import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  checkEmailExistenceBuilder,
  fetchUserInfoBuilder,
  googleSignUpBuilder,
  loginBuilder,
  loginWithOneTimeTokenBuilder,
  requestVerificationCodeBuilder,
  verifyEmailBuilder,
} from './builder';

const INIT_STATE = {
  loggedIn: null,
  accessToken: null,
  inputEmail: null,
  googleName: null,
  forgotPassword: false,
  referralCode: null,

  // ATTEMPTS
  checkEmailExistencePending: false,
  verifyEmailPending: false,
  requestVerificationCodePending: false,
  loginPending: false,
  loginWithOneTimeTokenPending: false,
  googleSignUpPending: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    setLoggedIn: (state, { payload }) => {
      const { loggedIn } = payload;
      state.loggedIn = loggedIn;
    },
    setInputEmail: (state, { payload }) => {
      const { email } = payload;
      state.inputEmail = email;
    },
    setAccessToken: (state, { payload }) => {
      const { accessToken } = payload;
      state.accessToken = accessToken;
    },
    setForgotPassword: (state, { payload }) => {
      state.forgotPassword = payload;
    },
    setReferralCode: (state, { payload }) => {
      state.referralCode = payload;
    },
  },
  extraReducers: builder => {
    googleSignUpBuilder(builder);
    checkEmailExistenceBuilder(builder);
    verifyEmailBuilder(builder);
    requestVerificationCodeBuilder(builder);
    loginBuilder(builder);
    fetchUserInfoBuilder(builder);
    loginWithOneTimeTokenBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE, loggedIn: false }));
  },
});

export const { setLoggedIn, setAccessToken, setInputEmail, setForgotPassword, setReferralCode } = authSlice.actions;

export const selectLoggedIn = state => state.auth.loggedIn;
export const selectAccessToken = state => state.auth.accessToken;
export const selectInputEmail = state => state.auth.inputEmail;
export const selectGoogleName = state => state.auth.googleName;
export const selectForgotPassword = state => state.auth.forgotPassword;

// ATTEMPTS
export const selectCheckEmailExistencePending = state => state.auth.checkEmailExistencePending;
export const selectVerifyEmailPending = state => state.auth.verifyEmailPending;
export const selectRequestVerificationCodePending = state => state.auth.requestVerificationCodePending;
export const selectLoginPending = state => state.auth.loginPending;
export const selectGoogleSignUpPending = state => state.auth.googleSignUpPending;

export default authSlice.reducer;
