import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentService from '../../../api/services/PaymentService';

export const createPaymentSession = createAsyncThunk('payment/createPaymentSession', async requestData => {
  return await PaymentService.createPaymentSession(requestData);
});

export const purchaseCredits = createAsyncThunk('payment/purchaseCredits', async requestData => {
  return await PaymentService.purchaseCredits(requestData);
});

export const cancelSubscription = createAsyncThunk('payment/cancelSubscription', async requestData => {
  return await PaymentService.cancelSubscription(requestData);
});

export const getPaymentSession = createAsyncThunk('payment/getPaymentSession', async requestData => {
  return await PaymentService.getPaymentSession(requestData);
});
