import styled from 'styled-components';
import { Text14Light } from '../../components/utils/Text/Text.styles';
import { Button } from '../../components/utils/Button/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 1.6rem;
  padding-bottom: 20rem;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    gap: 3.2rem;
    align-self: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 42rem;
    min-width: 42rem;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const Header = styled.div`
  padding-top: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.1rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const Label = styled(Text14Light)`
  margin-top: 2.4rem;
  margin-bottom: 1.2rem;
`;

export const SubscriptionCard = styled.div`
  border-radius: 12px;
  border: 1px solid #432181;
  background: linear-gradient(90deg, #1d0f37 0%, #05020b 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SubscriptionCardTextContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;

export const SubscriptionCardText = styled(Text14Light)`
  opacity: 0.5;
  color: #c4a0ff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const Line = styled.div`
  opacity: 0.56;
  background: linear-gradient(90deg, #030108 0%, #a168ff 47.5%, #030108 100%);
  height: 1px;
  width: 100%;
  margin: 1.2rem 0;
`;

export const Text = styled(Text14Light)`
  opacity: 0.5;
  color: #c4a0ff;
`;

export const StyledButton = styled(Button)`
  border: 1px solid #400f97;
  background: linear-gradient(180deg, #35166c 0%, #1c0c3a 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.23);
`;
