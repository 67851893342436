import styled from 'styled-components';
import { Text16Regular } from '../../utils/Text/Text.styles';
import { Button } from '../../utils/Button/Button';

export const ModalStyle = styled.div`
  position: relative;
  outline: none;
  border-radius: 24px;
  border: 1px solid #2a1a47;
  background: radial-gradient(78.01% 78.01% at 49.87% 0%, #1f0f3c 0%, #0a0416 100%);
  padding: 2.4rem;

  height: 30rem;
  width: 36rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 500px) {
    max-height: unset;
    width: 86%;
    height: 35.2rem;
    border-radius: 0;
    padding: 2rem 2.6rem;
    border-radius: 2rem;
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 300ms ease-in-out;

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.2rem;
`;

export const Text = styled(Text16Regular)`
  text-align: center;
  margin-inline: 1.6rem;
  line-height: 2.6rem;
`;

export const MainButton = styled(Button)`
  border: 1px solid rgba(132, 62, 254, 0.15);
  background: #1d0f37;
  box-shadow: none;
  width: 14.9rem;
`;

export const CancelButton = styled(Button)`
  width: 14.9rem;
`;
