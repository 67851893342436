import styled from 'styled-components';

export const ButtonContainer = styled.div`
  border-radius: 17px;
  background: linear-gradient(180deg, #ab75ff 0%, #4d2595 100%);
  padding: 1.6rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  background: linear-gradient(180deg, #4d2595 0%, #aa75ff 100%);
  padding: 0.1rem;
  height: 3.4rem;
`;

export const DiamondContainer = styled.div`
  border-radius: 50%;
  background: #bb8efb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 2px;
  transform: rotate(18.32deg);
`;
