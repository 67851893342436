import React, { useEffect, useState } from 'react';
import {
  ButtonContainer,
  Container,
  ResendCodeContainer,
  OtpContainer,
  TimeRemainText,
  TitleContainer,
  ResendText,
  Content,
} from './VerificationCodeStep.styles';
import { Subtitle, Title } from '../AuthModalContent/AuthModalContent.styles';
import ErrorContainer from '../../utils/ErrorContainer/ErrorContainer';
import OtpInput from '../../utils/inputs/OtpInput/OtpInput';
import { Button } from '../../utils/Button/Button';
import { Text15Light } from '../../utils/Text/Text.styles';
import { AUTH_MODAL_STEPS } from '../AuthModalContent/AuthModalContent';
import {
  selectForgotPassword,
  selectInputEmail,
  selectRequestVerificationCodePending,
  selectVerifyEmailPending,
} from '../../../store/slices/auth/slice';
import { useSelector, useDispatch } from 'react-redux';
import { notifyError } from '../../../utils/notify';
import { requestVerificationCode, verifyEmail } from '../../../store/slices/auth/asyncThunk';
import Spinner from '../../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { PROFILE } from '../../App/routes';
import { COOKIE_NAME, REFERRED_BY_TYPE } from '../../../utils/constants';
import { useCookies } from 'react-cookie';

const VerificationCodeStep = ({ setAuthStep, onClose, onSuccess, recaptchaRef }) => {
  const dispatch = useDispatch();
  const [emailOtp, setEmailOtp] = useState([]);
  const [otpError, setOtpError] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(59);
  const inputEmail = useSelector(selectInputEmail);
  const requestVerificationCodePending = useSelector(selectRequestVerificationCodePending);
  const verifyEmailPending = useSelector(selectVerifyEmailPending);
  const forgotPassword = useSelector(selectForgotPassword);
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([COOKIE_NAME.REFERRED_BY_AFFILIATE, COOKIE_NAME.REFERRED_BY_INFLUENCER]);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeRemaining]);

  const onChangeOtpInput = () => {
    setOtpError('');
  };

  const handleVerifyEmail = () => {
    if (emailOtp.length === 6) {
      const referredByAffiliate = cookies[COOKIE_NAME.REFERRED_BY_AFFILIATE];
      const referredByInfluencer = cookies[COOKIE_NAME.REFERRED_BY_INFLUENCER];
      let referredBy = null;
      if (referredByInfluencer) {
        referredBy = {
          type: REFERRED_BY_TYPE.INFLUENCER,
          source: { influencerUsername: referredByInfluencer.influencerUsername },
        };
      } else if (referredByAffiliate) {
        referredBy = {
          type: REFERRED_BY_TYPE.AFFILIATE,
          source: { clickId: referredByAffiliate.clickId, affiliateId: referredByAffiliate.affiliateId },
        };
      }

      dispatch(
        verifyEmail({
          code: emailOtp.join(''),
          email: inputEmail,
          ...(referredBy && { referredBy }),
        }),
      )
        .unwrap()
        .then(({ user }) => {
          if (!user.hasPassword) {
            setAuthStep(AUTH_MODAL_STEPS.PASSWORD);
          } else {
            if (forgotPassword) {
              navigate(`/${PROFILE}`);
            } else {
              onSuccess();
            }
            onClose();
          }
          removeCookie(COOKIE_NAME.REFERRED_BY_AFFILIATE);
          removeCookie(COOKIE_NAME.REFERRED_BY_INFLUENCER);
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      setOtpError('Code must contains 6 digits');
    }
  };

  useEffect(() => {
    if (emailOtp.join('').length === 6 && inputEmail) {
      const referredByAffiliate = cookies[COOKIE_NAME.REFERRED_BY_AFFILIATE];
      const referredByInfluencer = cookies[COOKIE_NAME.REFERRED_BY_INFLUENCER];

      let referredBy = null;
      if (referredByInfluencer) {
        referredBy = {
          type: REFERRED_BY_TYPE.INFLUENCER,
          source: { influencerUsername: referredByInfluencer.influencerUsername },
        };
      } else if (referredByAffiliate) {
        referredBy = {
          type: REFERRED_BY_TYPE.AFFILIATE,
          source: { clickId: referredByAffiliate.clickId, affiliateId: referredByAffiliate.affiliateId },
        };
      }

      dispatch(
        verifyEmail({
          code: emailOtp.join(''),
          email: inputEmail,
          ...(referredBy && { referredBy }),
        }),
      )
        .unwrap()
        .then(({ user }) => {
          if (!user.hasPassword) {
            setAuthStep(AUTH_MODAL_STEPS.PASSWORD);
          } else {
            if (forgotPassword) {
              navigate(`/${PROFILE}`);
            } else {
              onSuccess();
            }
            onClose();
          }
          removeCookie(COOKIE_NAME.REFERRED_BY_AFFILIATE);
          removeCookie(COOKIE_NAME.REFERRED_BY_INFLUENCER);
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
    // eslint-disable-next-line
  }, [emailOtp, forgotPassword, dispatch, inputEmail]);

  const resendCode = async () => {
    if (timeRemaining > 0) {
      notifyError('You can resend code after 1 minute.');
      return;
    } else {
      const recaptchaValue = await recaptchaRef.current.executeAsync();

      dispatch(requestVerificationCode({ email: inputEmail, token: recaptchaValue }))
        .unwrap()
        .then(() => {
          setTimeRemaining(59);
        })
        .catch(err => {
          recaptchaRef.current.reset();
          notifyError(err.message);
        });
    }
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Enter verification code</Title>
      </TitleContainer>
      {inputEmail && <Subtitle>Verification code sent to {inputEmail}</Subtitle>}
      <OtpContainer>
        <OtpInput codeLength={6} otp={emailOtp} setOtp={setEmailOtp} autoFocus onChange={onChangeOtpInput} />
        <ErrorContainer errorText={otpError} />
        <Content>
          <ResendCodeContainer>
            <Text15Light>Didn’t get the code?</Text15Light>
            {requestVerificationCodePending ? (
              <Spinner height={19} width={52} />
            ) : (
              <ResendText $disabled={timeRemaining > 0} onClick={resendCode}>
                Resend
              </ResendText>
            )}
          </ResendCodeContainer>
          {timeRemaining > 0 && (
            <TimeRemainText>00:{timeRemaining < 10 ? `0${timeRemaining}` : timeRemaining}</TimeRemainText>
          )}
        </Content>
      </OtpContainer>
      <ButtonContainer>
        <Button title="Continue" onClick={handleVerifyEmail} isLoading={verifyEmailPending} />
      </ButtonContainer>
    </Container>
  );
};

export default VerificationCodeStep;
