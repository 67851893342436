import React from 'react';
import { Container, SwitchCircle } from './MessageResponseFormatSwitch.styles';
import { CHAT_MODE } from '../../pages/InfluencerInfoPage/InfluencerInfoPage';
import { ReactComponent as MicrophoneIcon } from '../../assets/icons/microphone-02.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message-text-circle.svg';

const MessageResponseFormatSwitch = ({ chatMode, setChatMode }) => {
  return (
    <Container $isVoiceActive={chatMode === CHAT_MODE.VOICE} onClick={setChatMode}>
      <SwitchCircle $isVoiceActive={chatMode === CHAT_MODE.VOICE}>
        {chatMode === CHAT_MODE.VOICE ? <MicrophoneIcon /> : <MessageIcon />}
      </SwitchCircle>
    </Container>
  );
};

export default MessageResponseFormatSwitch;
