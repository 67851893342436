import { Fragment, useEffect } from 'react';
import GlobalStyles from '../../styles/globalStyles';
import {
  AppearancesPage,
  ChatPage,
  ChatPreviewPage,
  CheckoutPage,
  ExplorePage,
  FAQsPage,
  HomePage,
  InfluencerInfoPage,
  InfluencersTagPage,
  PersonalitiesPage,
  PrivacyPolicyPage,
  ProfilePage,
  SupportPage,
  TermsAndConditionsPage,
} from '../../pages';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  APPEARANCE,
  CHAT,
  CHAT_PREVIEW,
  CHECKOUT,
  EXPLORE,
  FAQS,
  HOME,
  INFLUENCER_INFO,
  PERSONALITY,
  PRIVACY_POLICY,
  PROFILE,
  SUPPORT,
  TAG,
  TERMS_AND_CONDITIONS,
} from './routes';
import { Layout, PrivateLayout } from '../../layouts';
import { selectInputEmail } from '../../store/slices/auth/slice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '../../store/slices/user/asyncThunk';
import { selectUser } from '../../store/slices/user/slice';
import { useAuthModal } from '../modals/AuthModal/AuthModal';
import { AUTH_MODAL_STEPS } from '../auth/AuthModalContent/AuthModalContent';
import { getInfluencersLanding } from '../../store/slices/influencer/asyncThunk';
import PageNotFound from '../../pages/utils/PageNotFound/PageNotFound';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { loginWithOneTimeToken } from '../../store/slices/auth/asyncThunk';
import { notifyError } from '../../utils/notify';
import { COOKIE_NAME } from '../../utils/constants';
import { useCookies } from 'react-cookie';

const SEARCH_PARAM = {
  CLICK_ID: 'click_id',
  AFFILIATE_ID: 'aff_id',
  TOKEN: 'token',
};

const App = () => {
  const user = useSelector(selectUser);
  const inputEmail = useSelector(selectInputEmail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setCookie] = useCookies([COOKIE_NAME.REFERRED_BY_AFFILIATE]);

  const [openAuthModal] = useAuthModal();
  let [searchParams, setSearchParams] = useSearchParams();

  const clickId = searchParams.get(SEARCH_PARAM.CLICK_ID);
  const affiliateId = searchParams.get(SEARCH_PARAM.AFFILIATE_ID);
  const token = searchParams.get(SEARCH_PARAM.TOKEN);

  useEffect(() => {
    if (clickId && affiliateId) {
      setCookie(COOKIE_NAME.REFERRED_BY_AFFILIATE, { clickId, affiliateId }, { expires: 0 });
      searchParams.delete(SEARCH_PARAM.AFFILIATE_ID);
      searchParams.delete(SEARCH_PARAM.CLICK_ID);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, [affiliateId, clickId]);

  useEffect(() => {
    if (token) {
      dispatch(loginWithOneTimeToken(token))
        .unwrap()
        .then(() => {
          navigate(`/${PROFILE}`);
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
    // eslint-disable-next-line
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInfluencersLanding({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (user && !user.name && !inputEmail) {
      setTimeout(() => {
        openAuthModal(AUTH_MODAL_STEPS.BASIC_INFO, () => {});
      }, 200);
    }
    // eslint-disable-next-line
  }, [user, inputEmail]);

  return (
    <Fragment>
      <GlobalStyles />
      <ToastContainer newestOnTop={true} autoClose={3500} />
      <Routes>
        <Route element={<Layout />}>
          <Route path={HOME} element={<HomePage />} />
          <Route path={EXPLORE} element={<ExplorePage />} />
          <Route path={CHAT} element={<ChatPage />}>
            <Route path={CHAT_PREVIEW} element={<ChatPreviewPage />} />
          </Route>
          <Route path={INFLUENCER_INFO} element={<InfluencerInfoPage />} />
          <Route path={TAG} element={<InfluencersTagPage />} />
          <Route path={PERSONALITY} element={<PersonalitiesPage />} />
          <Route path={APPEARANCE} element={<AppearancesPage />} />
          <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
          <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

          <Route element={<PrivateLayout />}>
            <Route path={PROFILE} element={<ProfilePage />} />
            <Route path={SUPPORT} element={<SupportPage />} />
            <Route path={FAQS} element={<FAQsPage />} />
            <Route path={CHECKOUT} element={<CheckoutPage />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Fragment>
  );
};

export default App;
