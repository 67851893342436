import styled from 'styled-components';
import { Text24Bold } from '../../utils/Text/Text.styles';

export const TrendingSection = styled.div``;

export const TrendingTitle = styled(Text24Bold)`
  margin-bottom: 1.2rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const TrendingSliderContent = styled.div`
  @media only screen and (max-width: 480px) {
    .slick-slide:not(.slick-center) {
      transition: transform 0.3s ease, opacity 0.3s ease;
      opacity: 0.5;
      transform: scale(0.95);
    }

    .slick-active {
      opacity: 1;
      transform: scale(1);
    }

    .slick-slide:not(.slick-active):not(.slick-center) {
      transform: scale(0.95);
    }

    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
      align-items: center;
    }
  }
`;

export const ArrowButton = styled.button`
  background: linear-gradient(270deg, color(display-p3 0 0 0) 0%, color(display-p3 0 0 0 / 0) 100%);
  width: 5rem;
  border: none;
  outline: none;
  position: absolute;
  right: 0rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;
