import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1.6rem;
  padding-inline: 1.6rem;

  @media only screen and (min-width: 768px) {
    padding: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-top: 2.4rem;

  @media only screen and (min-width: 768px) {
    margin-top: unset;
    padding: 2.4rem 1.6rem 1.6rem 2.4rem;
  }
`;

export const AllModelsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-top: -1rem;
    margin-bottom: -1.8rem;
  }

  div {
    width: 4rem;
    height: 4rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const TagsContainer = styled.div`
  margin-left: -1.6rem;
  margin-right: -1.6rem;

  @media only screen and (min-width: 768px) {
    margin-left: -2.4rem;
  }
`;

export const PersonalityTagsContainer = styled(TagsContainer)`
  @media only screen and (max-width: 768px) {
    margin-bottom: -1rem;
  }
`;
