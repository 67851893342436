import styled from 'styled-components';
import { Text13Light } from '../utils/Text/Text.styles';

export const AudioContainer = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  width: 30rem;
  height: 4.8rem;
  gap: 0.8rem;
  max-width: 100%;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AudioContent = styled.div`
  flex-grow: 1;
`;

export const AudioDurationContainer = styled(Text13Light)`
  color: #daccf7;
  user-select: none;
`;
