import React from 'react';
import {
  AboutMeInfo,
  AboutMeMobile,
  AboutMeText,
  AnimeWrapper,
  Avatar,
  BasicInfo,
  ButtonContainer,
  CardContainer,
  ChatButton,
  CircleButton,
  CountryFlag,
  Image,
  ImageContainer,
  InfluencerInfo,
  LocationInfo,
  NameInfo,
  PersonalitiesInfo,
  PersonalityTag,
  Shadow,
  Text,
} from './ExploreInfluencerCard.styles';
import { Text14Regular, Text22Bold } from '../utils/Text/Text.styles';
import { convertTagValueToLabel } from '../../utils/util';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import { ReactComponent as MarkerIcon } from '../../assets/icons/marker_pin.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message_circle.svg';
import { ReactComponent as AnimeIcon } from '../../assets/icons/anime.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { CHAT, CHAT_PREVIEW, INFLUENCER_INFO } from '../App/routes';
import { INFLUENCER_CATEGORY } from '../../utils/constants';

const ExploreInfluencerCard = ({ influencer }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToInfluencerInfo = () => {
    navigate(`/${INFLUENCER_INFO.replace(':influencerUsername', influencer.username)}`, { state: { from: pathname } });
  };
  return (
    <CardContainer>
      {influencer.category === INFLUENCER_CATEGORY.ANIME && (
        <AnimeWrapper>
          <AnimeIcon />
        </AnimeWrapper>
      )}
      <ImageContainer onClick={navigateToInfluencerInfo}>
        <Image src={influencer.image} />
        <Shadow />
      </ImageContainer>
      <InfluencerInfo onClick={navigateToInfluencerInfo}>
        <Avatar src={influencer.profilePhoto} onClick={navigateToInfluencerInfo} />
        <BasicInfo>
          <NameInfo>
            <Text22Bold>
              {influencer.name}, {influencer.age}
            </Text22Bold>
            <VerifiedIcon />
          </NameInfo>
          <LocationInfo>
            <MarkerIcon />
            <Text14Regular>{influencer.location}</Text14Regular>
          </LocationInfo>
          <AboutMeMobile>{influencer.aboutMe}</AboutMeMobile>
          <PersonalitiesInfo>
            <CountryFlag>{influencer.countryFlag}</CountryFlag>
            {influencer.personalityTags.map((personality, index) => {
              return <PersonalityTag key={index}>{convertTagValueToLabel(personality)}</PersonalityTag>;
            })}
          </PersonalitiesInfo>
        </BasicInfo>
        <AboutMeInfo>
          <Text>About Me</Text>
          <AboutMeText>{influencer.aboutMe}</AboutMeText>
        </AboutMeInfo>
        <ButtonContainer>
          <ChatButton
            icon={<MessageIcon />}
            title="Chat"
            onClick={e => {
              e.stopPropagation();
              navigate(`/${CHAT}/${CHAT_PREVIEW.replace(':influencerUsername', influencer.username)}`);
            }}
          />
        </ButtonContainer>
        <CircleButton
          onClick={e => {
            e.stopPropagation();

            navigate(`/${CHAT}/${CHAT_PREVIEW.replace(':influencerUsername', influencer.username)}`);
          }}>
          <MessageIcon />
        </CircleButton>
      </InfluencerInfo>
    </CardContainer>
  );
};

export default ExploreInfluencerCard;
