import styled from 'styled-components';
import { Text13Light, Text16Bold } from '../../utils/Text/Text.styles';

export const Card = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 0.48;
  min-width: 18.5rem;
  max-width: 18.5rem;
  cursor: pointer;
  border: 1.5px solid transparent;
  margin-right: 1.2rem;

  &:hover {
    border-color: #b687ff;
    transition: 0.3s;
  }

  @media only screen and (max-width: 550px) {
    min-width: ${({ $cardWidth }) => `${$cardWidth + 3}px`};
    max-width: ${({ $cardWidth }) => `${$cardWidth + 3}px`};
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Shadow = styled.div`
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(180deg, color(display-p3 0 0 0 / 0) 0%, color(display-p3 0 0 0 / 0.7) 100%);
  height: 8rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: absolute;
  bottom: 1.2rem;
  left: 0.8rem;
  right: 0.8rem;
  z-index: 1;
`;

export const AboutMeText = styled(Text13Light)`
  line-height: 2.1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const NameText = styled(Text16Bold)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const NumberContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 16px;
  border: 1px solid #400f97;
  background: linear-gradient(180deg, #35166c 0%, #220e45 100%);
  height: 3.6rem;
  width: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
