import styled from 'styled-components';
import { Text12Light, Text13Light, Text14Light, Text24ExtraBold } from '../../components/utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: ${({ $chatOpened }) => ($chatOpened ? 0 : '1.6rem')};
  height: 100%;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0;
  }
`;

export const Header = styled.div`
  padding-top: 2.4rem;
  display: ${({ $chatOpened }) => ($chatOpened ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.6rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  flex: 1;
`;

export const PremiumButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const LeftContainer = styled.div`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  gap: 2.4rem;
  padding-bottom: 8rem;

  @media only screen and (min-width: 768px) {
    padding: 2.4rem 1.6rem;
    display: flex;
    max-width: 42rem;
    min-width: 42rem;
    position: fixed;
    top: 0;
    bottom: 0;
    border-right: 1px solid #1d1229;
  }
`;

export const RightContainer = styled.div`
  margin-left: 42rem;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-grow: 1;
    margin-left: unset;
  }
`;

export const Title = styled(Text24ExtraBold)`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ChatRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  min-width: 5.6rem;
  max-width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex: 1;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const MessageInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
`;

export const MessageText = styled(Text14Light)`
  opacity: 0.6;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DotText = styled(Text12Light)`
  color: #ddccfa;

  opacity: 0.2;
`;

export const DateText = styled(Text13Light)`
  color: #daccf7;
  opacity: 0.4;
`;

export const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 2.4rem;

  @media only screen and (max-width: 768px) {
    display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`;

export const Text = styled(Text14Light)`
  opacity: 0.5;
  color: #cebaf2;
  max-width: 31rem;
  text-align: center;
`;

export const ExploreButton = styled.button`
  border-radius: 12px;
  border: 1px solid #7438df;
  background: linear-gradient(0deg, #2b1754 0%, #2b1754 100%), #5e3d99;
  backdrop-filter: blur(2px);
  font-size: 1.4rem;
  font-weight: 300;
  padding: 1rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.4rem;
  color: #fff;
  cursor: pointer;
`;

export const StyledPromoImage = styled.img`
  height: 20rem;
`;
