import React, { memo } from 'react';
import { Image, Message, MessageText, SentAtText } from './MessageComponent.styles';
import { MESSAGE_TYPE, USER_ROLE } from '../../utils/constants';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { formatCustomDate } from '../../utils/util';
import PremiumPhoto from '../PremiumPhoto/PremiumPhoto';

const MessageComponent = ({ message }) => {
  const renderContent = () => {
    switch (message.type) {
      case MESSAGE_TYPE.TEXT:
        return <MessageText>{message.content}</MessageText>;
      case MESSAGE_TYPE.VOICE:
        const uint8Array = new Uint8Array(message.content.data);
        const blob = new Blob([uint8Array], { type: 'audio/mp3' });
        const blobUrl = URL.createObjectURL(blob);
        return <AudioPlayer url={blobUrl} />;
      case MESSAGE_TYPE.PREMIUM_PHOTO:
        return <PremiumPhoto />;
      case MESSAGE_TYPE.PHOTO:
        return <Image src={message.content} />;
      default:
        break;
    }
  };

  return (
    <Message $role={message.role || USER_ROLE.INFLUENCER} $type={message.type}>
      {renderContent()}
      {message.type !== MESSAGE_TYPE.PREMIUM_PHOTO && (
        <SentAtText>{formatCustomDate(message.sentAt || new Date())}</SentAtText>
      )}
    </Message>
  );
};

export default memo(MessageComponent);
