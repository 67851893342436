import styled from 'styled-components';
import { Text14Light, Text22Bold } from '../../utils/Text/Text.styles';

export const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.1rem;
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.8rem;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const Title = styled(Text22Bold)``;

export const Subtitle = styled(Text14Light)`
  margin-top: 0.8rem;
`;
