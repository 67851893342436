import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  cancelSubscriptionBuilder,
  changeUserResponseFormatBuilder,
  fetchUserInfoBuilder,
  getTelegramInvitationUrlBuilder,
  googleSignUpBuilder,
  loginBuilder,
  loginWithOneTimeTokenBuilder,
  sendChatMessageBuilder,
  updateUserInfoBuilder,
  verifyEmailBuilder,
} from './builder';

const INIT_STATE = {
  user: null,

  // ATTEMPTS
  fetchUserInfoPending: false,
  updateUserInfoPending: false,
  getTelegramInvitationLinkPending: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    setUserFields: (state, { payload }) => {
      const { credits, subscribedUntil, activeSubscription, paymentMethods } = payload;
      state.user.credits = credits;
      state.user.subscribedUntil = subscribedUntil;
      state.user.activeSubscription = activeSubscription;
      state.user.paymentMethods = paymentMethods;
    },
  },
  extraReducers: builder => {
    verifyEmailBuilder(builder);
    fetchUserInfoBuilder(builder);
    updateUserInfoBuilder(builder);
    googleSignUpBuilder(builder);
    loginBuilder(builder);
    cancelSubscriptionBuilder(builder);
    getTelegramInvitationUrlBuilder(builder);
    sendChatMessageBuilder(builder);
    loginWithOneTimeTokenBuilder(builder);
    changeUserResponseFormatBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { setUserFields } = userSlice.actions;

export const selectUser = state => state.userInfo.user;
export const selectFetchUserInfoPending = state => state.userInfo.fetchUserInfoPending;
export const selectUpdateUserInfoPending = state => state.userInfo.updateUserInfoPending;
export const selectGetTelegramInvitationLinkPending = state => state.userInfo.getTelegramInvitationLinkPending;

export default userSlice.reducer;
