import { getUserInfluencerChat, sendChatMessage } from './asyncThunk';

export const sendChatMessageBuilder = builder => {
  builder.addCase(sendChatMessage.pending, state => {
    state.sendChatMessagePending = true;
  });
  builder.addCase(sendChatMessage.fulfilled, state => {
    state.sendChatMessagePending = false;
  });
  builder.addCase(sendChatMessage.rejected, state => {
    state.sendChatMessagePending = false;
  });
};

export const getUserInfluencerChatBuilder = builder => {
  builder.addCase(getUserInfluencerChat.pending, state => {
    state.getUserInfluencerChatPending = true;
  });
  builder.addCase(getUserInfluencerChat.fulfilled, state => {
    state.getUserInfluencerChatPending = false;
  });
  builder.addCase(getUserInfluencerChat.rejected, state => {
    state.getUserInfluencerChatPending = false;
  });
};
