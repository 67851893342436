import React from 'react';
import { Content, Header, MainButton, ModalStyle, OverlayStyle, Text } from './AgeAlertModal.styles';
import ReactModal from 'react-modal';
import { Text22Bold } from '../../utils/Text/Text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram1.svg';

const AgeAlertModal = ({ show, setShow }) => {
  // const [, setCookie] = useCookies([COOKIE_NAME.AGE_ALERT_WARNING]);

  // const onLeaveClick = () => {
  //   window.location.href = 'https://google.com';
  // };

  const onEnterClick = () => {
    window.open('https://t.me/onlychatssupport', '_blank');
    // setCookie(COOKIE_NAME.AGE_ALERT_WARNING, true, { expires: 0 });
    // setShow(false);
  };

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => setShow(false)}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Header>
            <Text22Bold>Support</Text22Bold>
            <CloseIcon onClick={() => setShow(false)} />
          </Header>
          <Content>
            <TelegramIcon />
            <Text>Our support requests are handled through Telegram.</Text>
          </Content>
          <MainButton title="Visit Telegram" onClick={onEnterClick} />
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default AgeAlertModal;
