import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.8rem;

  @media only screen and (min-width: 768px) {
    padding: 1.6rem 1.6rem 1.2rem 2.4rem;
    border-bottom: 1px solid #1d1229;
    height: 7.2rem;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  @media only screen and (min-width: 768px) {
    svg {
      display: none;
    }
  }
`;

export const BackContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
