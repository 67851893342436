import styled from 'styled-components';
import { Text12Regular } from '../utils/Text/Text.styles';
import PremiumButton from '../PremiumButton/PremiumButton';

export const PremiumImage = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 5rem;
  left: 3rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
`;

export const Button = styled(PremiumButton)`
  width: 11.4rem;
`;

export const Text = styled(Text12Regular)`
  text-align: center;
  letter-spacing: -0.408px;
  opacity: 0.4;
`;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 0.7;
  object-fit: cover;
`;
