import React from 'react';
import {
  AddCreditButton,
  Container,
  DiamondText,
  LogoContainer,
  LottieContainer,
  YourBalance,
  YourBalanceText,
} from './YourBalanceCard.styles';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus_circle.svg';
import { selectUser } from '../../../store/slices/user/slice';
import { useSelector } from 'react-redux';
import { Text32Bold } from '../../utils/Text/Text.styles';
import { useSubscriptionModal } from '../../modals/SubscriptionModal/SubscriptionModal';
import { SUBSCRIPTION_MODAL_MODE } from '../../payment/SubscriptionModalContent/SubscriptionModalContent';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
import Lottie from 'lottie-react';
import lottieData from '../../../assets/gifs/logo.json';

const YourBalanceCard = ({ loading }) => {
  const { credits } = useSelector(selectUser);
  const [openModal] = useSubscriptionModal();

  return (
    <Container>
      <YourBalance>
        {loading ? (
          <LottieContainer>
            <Lottie animationData={lottieData} loop />
          </LottieContainer>
        ) : (
          <>
            <LogoContainer>
              <LogoIcon height={86} width={91} />
            </LogoContainer>
            <Text32Bold>
              {credits}
              <DiamondText>💎</DiamondText>
            </Text32Bold>
            <YourBalanceText>YOUR BALANCE</YourBalanceText>
          </>
        )}
      </YourBalance>

      <AddCreditButton
        title="Add Credits"
        icon={<PlusIcon />}
        onClick={() => openModal(SUBSCRIPTION_MODAL_MODE.TOP_UP)}
      />
    </Container>
  );
};

export default YourBalanceCard;
