import { createAsyncThunk } from '@reduxjs/toolkit';
import InfluencerService from '../../../api/services/InfluencerService';

export const getInfluencersLanding = createAsyncThunk(
  'influencer/getInfluencersLanding',
  async (requestData, { getState }) => {
    const { page } = requestData;
    const maxReached = getState().influencerInfo.fetchLandingInfluencersInfo.maxReached;
    if (maxReached && page !== 1) {
      throw new Error('There is no more items');
    }
    return await InfluencerService.getInfluencersLanding(page);
  },
);

export const getInfluencers = createAsyncThunk('influencer/getInfluencers', async requestData => {
  return await InfluencerService.getInfluencers(requestData);
});

export const getExploreInfluencers = createAsyncThunk('influencer/getExploreInfluencers', async requestData => {
  return await InfluencerService.getInfluencers(requestData);
});

export const getInfluencerByUsername = createAsyncThunk('influencer/getInfluencerByUsername', async requestData => {
  return await InfluencerService.getInfluencerByUsername(requestData);
});
