import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1.6rem;
  padding-inline: 1.6rem;
  padding-bottom: 20rem;

  @media only screen and (min-width: 768px) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
`;
