import React, { useEffect, useState } from 'react';
import {
  BackgroundWrapper,
  ChooseModalMode,
  CloseIconWrapper,
  Container,
  EmptyDiv,
  Header,
  StyledButton,
} from './SubscriptionModalContent.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as BackgroundIcon } from '../../../assets/icons/background.svg';
import TopUpContent from '../TopUpContent/TopUpContent';
import SubscriptionContent from '../SubscriptionContent/SubscriptionContent';
import { selectUser } from '../../../store/slices/user/slice';
import { useDispatch, useSelector } from 'react-redux';
import { isSubscribed } from '../../../utils/util';
import LogoComponent from '../../LogoComponent/LogoComponent';
import {
  selectProcessingPayment,
  selectPurchaseCreditsPending,
  setProcessingPayment,
} from '../../../store/slices/payment/slice';

export const SUBSCRIPTION_MODAL_MODE = {
  TOP_UP: 'TOP_UP',
  SUBSCRIPTION: 'SUBSCRIPTION',
};
const SubscriptionModalContent = ({ onClose, modalModel }) => {
  const { subscribedUntil } = useSelector(selectUser);
  const processingPayment = useSelector(selectProcessingPayment);
  const purchaseCreditsPending = useSelector(selectPurchaseCreditsPending);

  const [mode, setMode] = useState(SUBSCRIPTION_MODAL_MODE.SUBSCRIPTION);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalModel) {
      if (isSubscribed(subscribedUntil)) {
        setMode(modalModel);
      }
    }
  }, [modalModel, subscribedUntil]);

  const renderContent = () => {
    if (mode === SUBSCRIPTION_MODAL_MODE.TOP_UP) {
      return <TopUpContent onClose={onClose} />;
    } else {
      return <SubscriptionContent onClose={onClose} />;
    }
  };

  return (
    <Container>
      <BackgroundWrapper>
        <BackgroundIcon />
      </BackgroundWrapper>
      {isSubscribed(subscribedUntil) && (
        <>
          <Header>
            <EmptyDiv />
            <LogoComponent fullLogo id="subsModal" />
            <CloseIconWrapper
              onClick={() => {
                onClose();
                dispatch(setProcessingPayment(false));
              }}>
              <CloseIcon />
            </CloseIconWrapper>
          </Header>
          <ChooseModalMode>
            <StyledButton
              onClick={() => {
                if (!processingPayment && !purchaseCreditsPending) {
                  setMode(SUBSCRIPTION_MODAL_MODE.TOP_UP);
                }
              }}
              $selected={mode === SUBSCRIPTION_MODAL_MODE.TOP_UP}>
              Top up
            </StyledButton>
            <StyledButton
              onClick={() => {
                if (!processingPayment && !purchaseCreditsPending) {
                  setMode(SUBSCRIPTION_MODAL_MODE.SUBSCRIPTION);
                }
              }}
              $selected={mode === SUBSCRIPTION_MODAL_MODE.SUBSCRIPTION}>
              Subscription
            </StyledButton>
          </ChooseModalMode>
        </>
      )}
      {renderContent()}
    </Container>
  );
};

export default SubscriptionModalContent;
