import React from 'react';
import { ButtonContainer, ButtonWrapper, DiamondContainer } from './PremiumButton.styles';
import { ReactComponent as DiamondIcon } from '../../assets/icons/diamond.svg';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { selectUser } from '../../store/slices/user/slice';
import { useSubscriptionModal } from '../modals/SubscriptionModal/SubscriptionModal';
import { useAuthModal } from '../modals/AuthModal/AuthModal';
import { useTelegramModal } from '../modals/TelegramModal/TelegramModal';
import { isSubscribed } from '../../utils/util';

const PremiumButton = ({ title, className, hideDiamond }) => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const [openModal] = useSubscriptionModal();
  const [openAuthModal] = useAuthModal();
  const [openTelegramModal] = useTelegramModal();

  const renderTitle = () => {
    if (!isLoggedIn) {
      return 'Join Now';
    } else {
      if (isSubscribed(user.subscribedUntil)) {
        return 'Premium';
      } else {
        return 'Try Premium';
      }
    }
  };

  const onButtonClick = () => {
    if (!isLoggedIn) {
      openAuthModal(null, () => {});
      return true;
    }
    if (isSubscribed(user.subscribedUntil)) {
      openTelegramModal();
      return true;
    } else {
      openModal();
      return true;
    }
  };

  return (
    <ButtonWrapper className={className}>
      <ButtonContainer onClick={onButtonClick}>
        {title ? title : renderTitle()}
        {!hideDiamond && (
          <DiamondContainer>
            <DiamondIcon width={16} height={16} color="#fff" />
          </DiamondContainer>
        )}
      </ButtonContainer>
    </ButtonWrapper>
  );
};

export default PremiumButton;
