import styled from 'styled-components';
import { Button } from '../../components/utils/Button/Button';

export const CheckoutPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 1.6rem;
  height: 100%;

  @media only screen and (min-width: 768px) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.1rem;
`;

export const EmptyDiv = styled.div`
  width: 2.4rem;
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1.2rem;
  cursor: pointer;
`;

export const Form = styled.form`
  margin: 2rem 0;
`;

export const StyledButton = styled(Button)`
  width: 20rem;
  margin-inline: auto;
`;

export const InputsRow = styled.div`
  display: flex;
  gap: 1.6rem;

  > :first-child {
    flex: 1;
  }

  > :nth-child(2) {
    flex: 1;
  }
`;
