import styled from 'styled-components';
import { Skeleton } from '../Skeleton.styles';

export const SkeletonContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;

export const SkeletonImage = styled(Skeleton)`
  width: 52rem;
  border-radius: 16px;
  aspect-ratio: 0.74;

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }
`;

export const RightSideSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.4rem 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const SkeletonAvatar = styled(Skeleton)`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin-left: 1.6rem;
`;

export const SkeletonContent = styled(Skeleton)`
  width: 25rem;
  height: 28rem;
  border-radius: 16px;
`;
