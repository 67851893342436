import React from 'react';
import { Container } from './ErrorContainer.styles';

const ErrorContainer = ({ errorText, className, twoLine }) => {
  return (
    <Container className={className} $twoLine={twoLine}>
      {errorText}
    </Container>
  );
};

export default ErrorContainer;
