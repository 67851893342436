import styled from 'styled-components';
import { Text15Light } from '../../utils/Text/Text.styles';

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 8rem;
`;

export const GenderSelection = styled.div`
  display: flex;
  gap: 1.6rem;
  padding-inline: 3.2rem;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    padding-inline: 0;
  }
`;

export const GenderButton = styled.button`
  border-radius: 8px;
  border: 1px solid ${({ $selected }) => ($selected ? '#7438DF' : 'rgba(116, 56, 223, 0.16)')};
  background: ${$selected =>
    $selected
      ? 'linear-gradient(0deg, #2B1754 0%, #2B1754 100%), #5E3D99'
      : 'linear-gradient(0deg, #1d0f37 0%, #1d0f37 100%), #5e3d99'};
  backdrop-filter: blur(2px);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 0.4rem 2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  align-items: center;
`;

export const CancelText = styled(Text15Light)`
  color: #c4a0ff;
  text-decoration: underline;
  cursor: pointer;
`;
