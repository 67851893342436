import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.4rem;
  z-index: 1;
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.8rem;
`;

export const EmptyDiv = styled.div`
  width: 24px;
  height: 24px;
`;

export const StyledButton = styled.button`
  border-radius: 12px;
  border: 1px solid ${({ $selected }) => ($selected ? '#A27FE0' : '#432181')};
  background: ${({ $selected }) => ($selected ? '#170E28' : 'linear-gradient(90deg, #1d0f37 0%, #05020b 100%)')};
  box-shadow: ${({ $selected }) =>
    $selected ? '0px 0px 8px 0px #6E539E inset' : '0px 8px 12px 0px rgba(0, 0, 0, 0.25)'};
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-weight: ${({ $selected }) => ($selected ? '500' : '400')};
  flex: 1;
  color: #fff;
  cursor: pointer;
  height: 3.4rem;
`;

export const ChooseModalMode = styled.div`
  display: flex;
  gap: 0.4rem;
  z-index: 1;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
`;
