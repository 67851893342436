import React, { createContext, useContext, useState } from 'react';
import Modal from '../utils/Modal/Modal';
import TelegramModalContent from '../../TelegramModalContent/TelegramModalContent';

const TelegramModalContext = createContext({
  openModal: () => {},
});

export const TelegramModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <TelegramModalContext.Provider value={{ openModal }}>
      {children}
      <TelegramModal isOpen={isOpen} onClose={closeModal} />
    </TelegramModalContext.Provider>
  );
};

const TelegramModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <TelegramModalContent onClose={onClose} />
    </Modal>
  );
};

export const useTelegramModal = () => {
  const { openModal } = useContext(TelegramModalContext);
  return [openModal];
};

export default TelegramModal;
