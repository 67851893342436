import { googleSignUp, login, loginWithOneTimeToken, verifyEmail } from '../auth/asyncThunk';
import { sendChatMessage } from '../chat/asyncThunk';
import { cancelSubscription } from '../payment/asyncThunk';
import { changeUserResponseFormat, fetchUserInfo, getTelegramInvitationUrl, updateUserInfo } from './asyncThunk';

export const verifyEmailBuilder = builder => {
  builder.addCase(verifyEmail.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const loginBuilder = builder => {
  builder.addCase(login.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const loginWithOneTimeTokenBuilder = builder => {
  builder.addCase(loginWithOneTimeToken.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const cancelSubscriptionBuilder = builder => {
  builder.addCase(cancelSubscription.fulfilled, (state, { payload }) => {
    const { canceledAt } = payload;
    state.user.activeSubscription.canceledAt = canceledAt;
  });
};

export const googleSignUpBuilder = builder => {
  builder.addCase(googleSignUp.fulfilled, (state, { payload }) => {
    state.user = payload.user;
  });
};

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.pending, state => {
    state.fetchUserInfoPending = true;
  });
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    state.fetchUserInfoPending = false;
    state.user = payload.user;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.fetchUserInfoPending = false;
  });
};

export const updateUserInfoBuilder = builder => {
  builder.addCase(updateUserInfo.pending, state => {
    state.updateUserInfoPending = true;
  });
  builder.addCase(updateUserInfo.fulfilled, (state, { payload }) => {
    state.updateUserInfoPending = false;
    state.user = payload.user;
  });
  builder.addCase(updateUserInfo.rejected, state => {
    state.updateUserInfoPending = false;
  });
};

export const getTelegramInvitationUrlBuilder = builder => {
  builder.addCase(getTelegramInvitationUrl.pending, state => {
    state.getTelegramInvitationLinkPending = true;
  });
  builder.addCase(getTelegramInvitationUrl.fulfilled, state => {
    state.getTelegramInvitationLinkPending = false;
  });
  builder.addCase(getTelegramInvitationUrl.rejected, state => {
    state.getTelegramInvitationLinkPending = false;
  });
};

export const sendChatMessageBuilder = builder => {
  builder.addCase(sendChatMessage.fulfilled, (state, { payload }) => {
    const { userInfluencer } = payload;
    state.user.messageLimiter.count += 1;
    const userInfluencerIndex = state.user.userInfluencers.findIndex(ui => ui.id === userInfluencer.id);
    if (userInfluencerIndex !== -1) {
      state.user.userInfluencers[userInfluencerIndex] = userInfluencer;
    } else {
      state.user.userInfluencers.push(userInfluencer);
    }
  });
};

export const changeUserResponseFormatBuilder = builder => {
  builder.addCase(changeUserResponseFormat.fulfilled, (state, { payload }) => {
    const { responseFormat } = payload;
    state.user.responseFormat = responseFormat;
  });
};
