import React, { useEffect } from 'react';
import { ButtonContainer, Container, Form, GenderButton, GenderSelection } from './BasicInfoStep.styles';
import { Title } from '../AuthModalContent/AuthModalContent.styles';
import { Button } from '../../utils/Button/Button';
import Input from '../../utils/inputs/Input/Input';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '../../../store/slices/user/asyncThunk';
import { notifyError } from '../../../utils/notify';
import { selectUpdateUserInfoPending } from '../../../store/slices/user/slice';
import { selectGoogleName } from '../../../store/slices/auth/slice';
import { GENDERS } from '../../../utils/constants';

const updateUserFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').min(3, 'Must be at least 3 characters'),
  })
  .required();

const BasicInfoStep = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const updateUserInfoPending = useSelector(selectUpdateUserInfoPending);
  const googleName = useSelector(selectGoogleName);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      gender: GENDERS[0].value,
    },
    delayError: 300,
    resolver: yupResolver(updateUserFormSchema),
  });

  useEffect(() => {
    if (googleName) {
      setValue('name', googleName);
    }
  }, [googleName, setValue]);

  const onSubmit = data => {
    const { name, gender } = data;
    dispatch(updateUserInfo({ name, gender }))
      .unwrap()
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch(() => {
        notifyError('Something went wrong. Please try again in few minutes.');
      });
  };

  return (
    <Container>
      <Title>What should we call you?</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input placeholder="Enter name" {...register('name')} error={errors.name?.message} />
        <GenderSelection>
          {GENDERS.map(gender => {
            return (
              <Controller
                name="gender"
                key={gender.value}
                control={control}
                render={({ field }) => (
                  <GenderButton
                    type="button"
                    $selected={field.value === gender.value}
                    onClick={() => field.onChange(gender.value)}>
                    {gender.label}
                  </GenderButton>
                )}
              />
            );
          })}
        </GenderSelection>
        <ButtonContainer>
          <Button title="Continue" isLoading={updateUserInfoPending} />
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default BasicInfoStep;
