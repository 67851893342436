import React from 'react';
import { AnswerText, Content, PageContainer, TextGroup } from './FAQsPage.styles';
import { Header } from '../../components';
import { Text16Bold } from '../../components/utils/Text/Text.styles';

const FAQsPage = () => {
  return (
    <PageContainer>
      <Header title="Frequently Asked Questions" />
      <Content>
        <TextGroup>
          <Text16Bold>Can I send audio messages?</Text16Bold>
          <AnswerText>
            You bet! While we love a good text chat, feel free to serenade them with your voice via audio messages.
          </AnswerText>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Are there refunds?</Text16Bold>
          <AnswerText>
            Sorry, no take backs! Once you dive into the Only Chats experience, all sales are final.{' '}
          </AnswerText>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Do they have their own interests?</Text16Bold>
          <AnswerText>Absolutely! Each companion has their quirks and passions. Dive in and discover! </AnswerText>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Do they have their own personalities?</Text16Bold>
          <AnswerText>Oh, yes! Every companion boasts a unique personality that makes them, well, them. </AnswerText>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Do they ever get upset?</Text16Bold>
          <AnswerText>Absolutely no! </AnswerText>
        </TextGroup>
      </Content>
    </PageContainer>
  );
};

export default FAQsPage;
