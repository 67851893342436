import React from "react";
import { Outlet } from "react-router-dom";
import { Navigation } from "../../components";
import { ContentContainer, Container } from "./Layout.styles";

const Layout = () => {
  return (
    <Container>
      <Navigation />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default Layout;
