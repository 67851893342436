import React from 'react';
import { AvatarSkeleton, ContentSkeleton, SkeletonItemContainer } from './ChatsPageSkeleton.styles';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';

const ChatsPageSkeleton = () => {
  return Array.from({ length: 6 }).map((_, index) => {
    return (
      <SkeletonItemContainer key={index}>
        <AvatarSkeleton $animationTime={30}>
          <LogoIcon height={36} width={36} />
        </AvatarSkeleton>
        <ContentSkeleton $animationTime={10}>
          <LogoIcon height={36} width={36} />
        </ContentSkeleton>
      </SkeletonItemContainer>
    );
  });
};

export default ChatsPageSkeleton;
