import React, { useEffect, useRef, useState } from 'react';
import {
  AmountContainer,
  Container,
  Header,
  IconWrapper,
  Label,
  LeftContainer,
  Line,
  StyledButton,
  SubscriptionCard,
  SubscriptionCardText,
  SubscriptionCardTextContainer,
  TitleContainer,
} from './ProfilePage.styles';
import { ReactComponent as DiamondIcon } from '../../assets/icons/diamond.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { ReactComponent as LogOutIcon } from '../../assets/icons/log_out.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg';
import { ReactComponent as MessageHeartIcon } from '../../assets/icons/message-heart-circle.svg';
import { Text14Light, Text16Bold, Text16ExtraBold, Text18Regular } from '../../components/utils/Text/Text.styles';
import {
  AgeAlertModal,
  CancelSubscriptionModal,
  EditUserInfoModal,
  ProcessingPaymentModal,
  SettingsButton,
  SingleFieldInput,
  YourBalanceCard,
} from '../../components';
import { selectUser, setUserFields } from '../../store/slices/user/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscriptionModal } from '../../components/modals/SubscriptionModal/SubscriptionModal';
import { capitalizeFirstLetter, isSubscribed } from '../../utils/util';
import { PAYMENT_SESSION_STATUS, SUBSCRIPTION_CREDITS } from '../../utils/constants';
import { logout } from '../../store/slices/auth/asyncThunk';
import { EDIT_USER_INFO_MODAL_TYPE } from '../../components/modals/EditUserInfoModal/EditUserInfoModal';
import { selectForgotPassword } from '../../store/slices/auth/slice';
import { format } from 'date-fns';
import { getPaymentSession } from '../../store/slices/payment/asyncThunk';
import { notifyError, notifyInfo } from '../../utils/notify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../components/App/routes';
import { selectGetPaymentSessionPending } from '../../store/slices/payment/slice';

export const GENDERS_LABEL = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

const PROFILE_SEARCH_PARAM = 'sid';

const ProfilePage = () => {
  const [openModal] = useSubscriptionModal();

  const updateUserInfoModalRef = useRef();
  const [modalType, setModalType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showProcessingPaymentModal, setShowProcessingPaymentModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);

  const [error, setError] = useState(null);
  const [getPaymentSessionAttempts, setGetPaymentSessionAttempts] = useState(1);
  const [intervalLength, setIntervalLength] = useState(1000);
  const [paymentTypeSuccess, setPaymentTypeSuccess] = useState(null);

  const user = useSelector(selectUser);
  const forgotPassword = useSelector(selectForgotPassword);
  const getPaymentSessionPending = useSelector(selectGetPaymentSessionPending);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get(PROFILE_SEARCH_PARAM);

  const defaultPaymentMethod = user?.paymentMethods?.find(method => method.isDefault);

  useEffect(() => {
    if (forgotPassword) {
      setModalType(EDIT_USER_INFO_MODAL_TYPE.PASSWORD);
      updateUserInfoModalRef.current.show();
    }
  }, [forgotPassword]);

  const onCancelSubscriptionClick = () => {
    setShowCancelSubscriptionModal(true);
  };

  useEffect(() => {
    let intervalId;
    if (sessionId && user?.id) {
      setShowProcessingPaymentModal(true);
      intervalId = setInterval(() => {
        dispatch(getPaymentSession({ sessionId, attempts: getPaymentSessionAttempts }))
          .unwrap()
          .then(({ userData, paymentSession }) => {
            if (paymentSession.status === PAYMENT_SESSION_STATUS.FAILED) {
              searchParams.delete(PROFILE_SEARCH_PARAM);
              setSearchParams(searchParams);
              setError(paymentSession.error);
              clearInterval(intervalId);
            } else if (paymentSession.status === PAYMENT_SESSION_STATUS.SUCCEEDED) {
              const { credits, subscribedUntil, activeSubscription, paymentMethods } = userData;
              dispatch(setUserFields({ credits, subscribedUntil, activeSubscription, paymentMethods }));
              notifyInfo('Payment successfully processed');
              searchParams.delete(PROFILE_SEARCH_PARAM);
              setSearchParams(searchParams);
              setPaymentTypeSuccess(paymentSession.paymentType);
              clearInterval(intervalId);
            } else {
              setGetPaymentSessionAttempts(getPaymentSessionAttempts + 1);
              if (getPaymentSessionAttempts > 14) {
                setIntervalLength(3000);
              }
            }
          })
          .catch(err => {
            clearInterval(intervalId);
            notifyError(err.message);
          });
      }, intervalLength);
    }

    return () => intervalId && clearInterval(intervalId);
    // eslint-disable-next-line
  }, [sessionId, user?.id, dispatch, intervalLength, getPaymentSessionAttempts]);

  if (!user || getPaymentSessionPending) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Text16ExtraBold>Profile</Text16ExtraBold>
      </Header>
      <LeftContainer>
        <YourBalanceCard loading={sessionId} />
        <Label>Subscription</Label>
        {!isSubscribed(user.subscribedUntil) ? (
          <StyledButton
            title="Subscribe to OnlyChats"
            icon={<DiamondIcon color="#BEA1FF" />}
            onClick={() => openModal()}
            isLoading={sessionId}
          />
        ) : (
          <SubscriptionCard>
            <TitleContainer>
              <Text18Regular>{user.activeSubscription.plan}</Text18Regular>
              <IconWrapper onClick={() => openModal()}>
                <PencilIcon />
              </IconWrapper>
            </TitleContainer>
            <AmountContainer>
              <Text16Bold>
                ${user.activeSubscription.paidAmount} / {user.activeSubscription.period.replace('_', ' ').toLowerCase()}
              </Text16Bold>
              <Text16Bold>{SUBSCRIPTION_CREDITS[user.activeSubscription.plan].TOTAL} 💎 / monthly</Text16Bold>
            </AmountContainer>
            <Line />
            <SubscriptionCardTextContainer>
              <SubscriptionCardText>Card details:</SubscriptionCardText>
              <Text14Light>
                {capitalizeFirstLetter(defaultPaymentMethod.details.brand)} **** {defaultPaymentMethod.details.last4}
              </Text14Light>
            </SubscriptionCardTextContainer>
            <SubscriptionCardTextContainer>
              <SubscriptionCardText>
                {user.activeSubscription.canceledAt ? 'Expires on:' : 'Renews on:'}
              </SubscriptionCardText>
              <Text14Light>{format(user.subscribedUntil, 'MMMM dd,yyyy')}</Text14Light>
            </SubscriptionCardTextContainer>
          </SubscriptionCard>
        )}
        <Label>Profile</Label>
        <SingleFieldInput name="Email" value={user.email} />
        <SingleFieldInput
          name="Nickname"
          value={user.name}
          onEditClick={() => {
            setModalType(EDIT_USER_INFO_MODAL_TYPE.NICKNAME);
            updateUserInfoModalRef.current.show();
          }}
        />
        <SingleFieldInput
          name="Gender"
          value={GENDERS_LABEL[user.gender]}
          onEditClick={() => {
            setModalType(EDIT_USER_INFO_MODAL_TYPE.GENDER);
            updateUserInfoModalRef.current.show();
          }}
          isLast
        />
        <Label>Settings</Label>
        <SettingsButton
          title="Change Password"
          onClick={() => {
            setModalType(EDIT_USER_INFO_MODAL_TYPE.PASSWORD);
            updateUserInfoModalRef.current.show();
          }}
        />
        <SettingsButton title="Support" icon={<HelpIcon />} onClick={() => setShowModal(true)} />
        <SettingsButton
          title="Creators"
          icon={<MessageHeartIcon width={20} height={20} />}
          onClick={() => setShowModal(true)}
        />
        <SettingsButton title="Privacy Policy" onClick={() => navigate(`/${PRIVACY_POLICY}`)} />
        <SettingsButton title="Terms & Conditions" onClick={() => navigate(`/${TERMS_AND_CONDITIONS}`)} />
        {user.activeSubscription && !user.activeSubscription.canceledAt && (
          <SettingsButton title="Cancel Subscription" onClick={onCancelSubscriptionClick} />
        )}
        <SettingsButton title="Logout" icon={<LogOutIcon />} onClick={() => dispatch(logout())} />
        <EditUserInfoModal modalRef={updateUserInfoModalRef} type={modalType} />
      </LeftContainer>
      <AgeAlertModal show={showModal} setShow={setShowModal} />
      <ProcessingPaymentModal
        show={showProcessingPaymentModal}
        setShow={setShowProcessingPaymentModal}
        error={error}
        paymentTypeSuccess={paymentTypeSuccess}
      />
      <CancelSubscriptionModal show={showCancelSubscriptionModal} setShow={setShowCancelSubscriptionModal} />
    </Container>
  );
};

export default ProfilePage;
