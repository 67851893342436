import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../../api/services/AuthService';
import { LOGOUT } from '../../../utils/constants';

export const googleSignUp = createAsyncThunk('auth/googleSignUp', async requestData => {
  return await AuthService.googleSignUp(requestData);
});

export const checkEmailExistence = createAsyncThunk('auth/checkEmailExistence', async requestData => {
  return await AuthService.checkEmailExistence(requestData);
});

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async requestData => {
  return await AuthService.verifyEmail(requestData);
});

export const requestVerificationCode = createAsyncThunk('auth/requestVerificationCode', async requestData => {
  return await AuthService.requestVerificationCode(requestData);
});

export const login = createAsyncThunk('auth/login', async requestData => {
  return await AuthService.login(requestData);
});

export const loginWithOneTimeToken = createAsyncThunk('auth/loginWithOneTimeToken', async requestData => {
  return await AuthService.loginWithOneTimeToken(requestData);
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  thunkAPI.dispatch({ type: LOGOUT });
  return await AuthService.logout();
});
