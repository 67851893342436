import React from 'react';
import {
  BackIcon,
  Circle,
  Container,
  Content,
  ImageWrapper,
  InfoContainer,
  LeftSide,
  NameText,
  NameWrapper,
  OnlineContainer,
  OnlineText,
  PhoneIconWrapper,
  RightSide,
  VideoIconWrapper,
} from './ChatHeader.styles';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/video.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron_left.svg';
import { ReactComponent as VerifiedIcon } from '../../../assets/icons/verified.svg';
import { useNavigate } from 'react-router-dom';
import { HOME, INFLUENCER_INFO } from '../../App/routes';
import MessageResponseFormatSwitch from '../../MessageResponseFormatSwitch/MessageResponseFormatSwitch';

const ChatHeader = ({
  name,
  image,
  isVerified,
  influencerUsername,
  onPhoneClick,
  setSelectedInfluencer,
  chatMode,
  setChatMode,
}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <LeftSide>
        <BackIcon
          onClick={() => {
            setSelectedInfluencer(null);
            if (window.history.length > 1) {
              navigate(-1);
            } else {
              navigate(`${HOME}`);
            }
          }}>
          <ArrowIcon />
        </BackIcon>
        <ImageWrapper
          onClick={() => {
            navigate(`/${INFLUENCER_INFO.replace(':influencerUsername', influencerUsername)}`, {
              state: { from: HOME },
            });
          }}>
          <img src={image} alt="asd" />
        </ImageWrapper>
        <Content>
          <InfoContainer>
            <NameWrapper
              onClick={() => {
                navigate(`/${INFLUENCER_INFO.replace(':influencerUsername', influencerUsername)}`, {
                  state: { from: HOME },
                });
              }}>
              <NameText>{name}</NameText>
              {isVerified && <VerifiedIcon />}
            </NameWrapper>
            <OnlineContainer>
              <Circle />
              <OnlineText>Online</OnlineText>
            </OnlineContainer>
          </InfoContainer>
        </Content>
      </LeftSide>
      <RightSide>
        <MessageResponseFormatSwitch chatMode={chatMode} setChatMode={setChatMode} />
        <VideoIconWrapper onClick={onPhoneClick}>
          <VideoIcon color="#fff" />
        </VideoIconWrapper>
        <PhoneIconWrapper onClick={onPhoneClick}>
          <PhoneIcon color="#fff" />
        </PhoneIconWrapper>
      </RightSide>
    </Container>
  );
};

export default ChatHeader;
