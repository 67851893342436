import React from 'react';
import { ButtonContainer, Container, ForgotPasswordText, Form } from './PasswordStep.styles';
import { Title } from '../AuthModalContent/AuthModalContent.styles';
import Input from '../../utils/inputs/Input/Input';
import { Button } from '../../utils/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectInputEmail,
  selectLoggedIn,
  selectLoginPending,
  setForgotPassword,
} from '../../../store/slices/auth/slice';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notifyError } from '../../../utils/notify';
import { login, requestVerificationCode } from '../../../store/slices/auth/asyncThunk';
import { updateUserInfo } from '../../../store/slices/user/asyncThunk';
import { AUTH_MODAL_STEPS } from '../AuthModalContent/AuthModalContent';
import { selectUpdateUserInfoPending } from '../../../store/slices/user/slice';

const passwordFormSchema = yup
  .object({
    password: yup.string().trim().required('Password is required').min(6, 'Must be at least 6 characters'),
  })
  .required();

const PasswordStep = ({ setAuthStep, onClose, onSuccess, recaptchaRef }) => {
  const loggedIn = useSelector(selectLoggedIn);
  const inputEmail = useSelector(selectInputEmail);
  const loginPending = useSelector(selectLoginPending);
  const updateUserInfoPending = useSelector(selectUpdateUserInfoPending);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(passwordFormSchema),
  });

  const onForgotPasswordClick = async () => {
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    dispatch(setForgotPassword(true));
    dispatch(requestVerificationCode({ email: inputEmail, token: recaptchaValue }))
      .unwrap()
      .then(() => {
        setAuthStep(AUTH_MODAL_STEPS.VERIFICATION_CODE);
      })
      .catch(err => {
        recaptchaRef.current.reset();
        notifyError(err.message);
      });
  };

  const onSubmit = async data => {
    const { password } = data;
    if (!loggedIn) {
      const recaptchaValue = await recaptchaRef.current.executeAsync();
      dispatch(
        login({
          email: inputEmail,
          password,
          token: recaptchaValue,
        }),
      )
        .unwrap()
        .then(() => {
          onClose();
          onSuccess();
        })
        .catch(err => {
          recaptchaRef.current.reset();
          notifyError(err.message);
        });
    } else {
      dispatch(updateUserInfo({ password }))
        .unwrap()
        .then(({ user }) => {
          if (!user.name) {
            setAuthStep(AUTH_MODAL_STEPS.BASIC_INFO);
          } else {
            onClose();
          }
        })
        .catch(() => {
          notifyError('Something went wrong. Please try again in few minutes.');
        });
    }
  };

  return (
    <Container>
      <Title>{!loggedIn ? 'Enter your password' : 'Create a password'}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Enter password"
          {...register('password')}
          error={errors.password?.message}
          type="password"
        />
        <ButtonContainer $loggedIn={loggedIn}>
          <Button title="Continue" isLoading={loginPending || updateUserInfoPending} />
          {!loggedIn && (
            <>
              <ForgotPasswordText onClick={onForgotPasswordClick}>Forgot password?</ForgotPasswordText>
            </>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default PasswordStep;
