import { getExploreInfluencers, getInfluencerByUsername, getInfluencers, getInfluencersLanding } from './asyncThunk';

export const getInfluencersLandingBuilder = builder => {
  builder.addCase(getInfluencersLanding.pending, state => {
    state.getInfluencersLandingPending = true;
  });
  builder.addCase(getInfluencersLanding.fulfilled, (state, { payload }) => {
    const { influencers, page } = payload;
    state.fetchLandingInfluencersInfo.page = page + 1;
    state.fetchLandingInfluencersInfo.maxReached = influencers.maxReached;
    state.fetchLandingInfluencersInfo.data =
      page === 1 ? influencers.data : [...state.fetchLandingInfluencersInfo.data, ...influencers.data];
    state.getInfluencersLandingPending = false;
  });
  builder.addCase(getInfluencersLanding.rejected, state => {
    state.getInfluencersLandingPending = false;
  });
};

export const getInfluencersBuilder = builder => {
  builder.addCase(getInfluencers.pending, state => {
    state.getInfluencersPending = true;
  });
  builder.addCase(getInfluencers.fulfilled, (state, { payload }) => {
    const { influencers } = payload;
    state.getInfluencersPending = false;
    state.influencers = influencers;
  });
  builder.addCase(getInfluencers.rejected, state => {
    state.getInfluencersPending = false;
  });
};

export const getExploreInfluencersBuilder = builder => {
  builder.addCase(getExploreInfluencers.pending, state => {
    state.getExploreInfluencersPending = true;
  });
  builder.addCase(getExploreInfluencers.fulfilled, (state, { payload }) => {
    const { influencers } = payload;
    state.getExploreInfluencersPending = false;
    state.exploreInfluencers = influencers;
  });
  builder.addCase(getExploreInfluencers.rejected, state => {
    state.getExploreInfluencersPending = false;
  });
};

export const getInfluencerByUsernameBuilder = builder => {
  builder.addCase(getInfluencerByUsername.pending, state => {
    state.getInfluencerByUsernamePending = true;
  });
  builder.addCase(getInfluencerByUsername.fulfilled, (state, { payload }) => {
    const { influencer } = payload;
    state.getInfluencerByUsernamePending = false;
    state.selectedInfluencer = influencer;
  });
  builder.addCase(getInfluencerByUsername.rejected, state => {
    state.getInfluencerByUsernamePending = false;
  });
};
