import styled from 'styled-components';

export const ModalStyle = styled.div`
  position: relative;
  outline: none;
  border-radius: 24px;
  border: 1px solid #b485ff;
  background: radial-gradient(78.01% 78.01% at 49.87% 0%, #1f0f3c 0%, #0a0416 100%);
  padding: 2rem 2.8rem;
  max-height: 92%;
  height: 70rem;
  width: 42rem;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    max-height: unset;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    padding: 1.2rem 2rem;
    background: #000;
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 300ms ease-in-out;

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`;
