import React, { useEffect } from 'react';
import { PageContainer } from './ExplorePage.styles';
import { ExploreInfluencerCard, ExplorePageSkeleton } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreInfluencers } from '../../store/slices/influencer/asyncThunk';
import { selectExploreInfluencers, selectGetExploreInfluencersPending } from '../../store/slices/influencer/slice';
import { selectLoggedIn } from '../../store/slices/auth/slice';

const ExplorePage = () => {
  const dispatch = useDispatch();
  const exploreInfluencers = useSelector(selectExploreInfluencers);
  const getExploreInfluencersPending = useSelector(selectGetExploreInfluencersPending);
  const isLoggedIn = useSelector(selectLoggedIn);

  useEffect(() => {
    if (!exploreInfluencers.length) {
      dispatch(getExploreInfluencers({ appearanceTags: [], personalityTags: [] }));
    }
  }, [dispatch, exploreInfluencers]);

  if (getExploreInfluencersPending || isLoggedIn === null) {
    return (
      <PageContainer>
        <ExplorePageSkeleton />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {exploreInfluencers.map(influencer => {
        return <ExploreInfluencerCard key={influencer.id} influencer={influencer} />;
      })}
    </PageContainer>
  );
};

export default ExplorePage;
