import styled from 'styled-components';
import { Skeleton } from '../Skeleton.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const TrendingCard = styled(Skeleton)`
  border-radius: 16px;
  width: 100%;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
`;

export const FeaturedNewbiesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
`;

export const FeaturedNewbiesContent = styled.div`
  display: flex;
  gap: 1.2rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FeaturedNewbiesCard = styled(Skeleton)`
  aspect-ratio: 0.48;
  border-radius: 16px;
  min-width: 18.5rem;
  max-width: 18.5rem;
  position: relative;

  @media only screen and (max-width: 550px) {
    min-width: ${({ $cardWidth }) => `${$cardWidth + 3}px`};
    max-width: ${({ $cardWidth }) => `${$cardWidth + 3}px`};
  }
`;

export const Number = styled.div`
  border-radius: 0px 16px;
  border: 1px solid #400f97;
  background: linear-gradient(180deg, #35166c 0%, #220e45 100%);
  height: 3.6rem;
  width: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
`;

export const AllModelsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const AllModelsTitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  div {
    width: 4rem;
    height: 4rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-top: -1rem;
    margin-bottom: -1.8rem;
  }

  div {
    width: 4rem;
    height: 4rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const TagsContainer = styled.div`
  margin-left: -1.6rem;
  margin-right: -1.6rem;

  @media only screen and (min-width: 768px) {
    margin-left: -2.4rem;
  }
`;

export const PersonalityTagsContainer = styled(TagsContainer)`
  @media only screen and (max-width: 768px) {
    margin-bottom: -1rem;
  }
`;
