import React from 'react';
import { Helmet } from 'react-helmet-async';

const InfluencerInfoPageHelmet = ({ influencer }) => {
  return (
    <Helmet>
      <title>{influencer ? influencer.name + ' - OnlyChats' : 'OnlyChats'}</title>
      <meta
        name="description"
        content={
          influencer
            ? `Chat with ${influencer.name} 24/7 on OnlyChats for Free. Experience the most realistic AI Girlfriend & Anime Chat platform.`
            : 'Experience the most realistic AI Girlfriend & Anime Chat platform with OnlyChats. Engage in chats with AI girl and anime companions.'
        }
      />
      <meta property="og:title" content={influencer ? influencer.name : 'OnlyChats - AI Girlfriend & Anime Chat'} />
      <meta
        property="og:description"
        content={
          influencer
            ? influencer.aboutMe
            : 'Experience the most realistic AI Girlfriend & Anime Chat platform with OnlyChats. Engage in chats with AI girl and anime companions.'
        }
      />
      <meta
        property="og:image"
        content={
          influencer
            ? influencer.profilePhoto
            : 'https://www.onlychats.com/cdn-cgi/imagedelivery/JbcvhHWGK90wHykvJ8zUXw/679ca574-e3fb-4221-e1b2-4f129e563f00/public'
        }
      />
      <meta
        property="og:url"
        content={influencer ? `https://www.onlychats.com/creator/${influencer.username}` : 'https://www.onlychats.com'}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="OnlyChats" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={influencer ? influencer.name : 'OnlyChats - AI Girlfriend & Anime Chat'} />
      <meta
        name="twitter:description"
        content={
          influencer
            ? influencer.aboutMe
            : 'Experience the most realistic AI Girlfriend & Anime Chat platform with OnlyChats. Engage in chats with AI girl and anime companions.'
        }
      />
      <meta
        name="twitter:image"
        content={
          influencer
            ? influencer.profilePhoto
            : 'https://www.onlychats.com/cdn-cgi/imagedelivery/JbcvhHWGK90wHykvJ8zUXw/e314707b-5166-4e58-83d6-a9fb5fecbe00/public'
        }
      />
      <meta name="apple-mobile-web-app-title" content="OnlyChats" />
      <meta name="application-name" content="OnlyChats" />
      <meta itemprop="name" content={influencer ? influencer.name : 'OnlyChats - AI Girlfriend & Anime Chat'} />
      <meta
        itemprop="description"
        content={
          influencer
            ? influencer.aboutMe
            : 'Experience the most realistic AI Girlfriend & Anime Chat platform with OnlyChats. Engage in chats with AI girl and anime companions.'
        }
      />
      <meta
        itemprop="image"
        content={
          influencer
            ? influencer.profilePhoto
            : 'https://www.onlychats.com/cdn-cgi/imagedelivery/JbcvhHWGK90wHykvJ8zUXw/e314707b-5166-4e58-83d6-a9fb5fecbe00/public'
        }
      />
    </Helmet>
  );
};

export default InfluencerInfoPageHelmet;
