import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import { getUserInfluencerChatBuilder, sendChatMessageBuilder } from './builder';

const INIT_STATE = {
  visitedChatsChanged: false,
  // ATTEMPTS
  sendChatMessagePending: false,
  getUserInfluencerChatPending: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: INIT_STATE,
  reducers: {
    setVisitedChatsChanged: (state, action) => {
      state.visitedChatsChanged = action.payload;
    },
  },
  extraReducers: builder => {
    sendChatMessageBuilder(builder);
    getUserInfluencerChatBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { setVisitedChatsChanged } = chatSlice.actions;

// ATTEMPTS
export const selectSendChatMessagePending = state => state.chatInfo.sendChatMessagePending;
export const selectGetUserInfluencerChatPending = state => state.chatInfo.getUserInfluencerChatPending;
export const selectVisitedChatsChanged = state => state.chatInfo.visitedChatsChanged;

export default chatSlice.reducer;
