import { createAsyncThunk } from '@reduxjs/toolkit';
import ChatService from '../../../api/services/ChatService';

export const sendChatMessage = createAsyncThunk('chat/sendChatMessage', async requestData => {
  return await ChatService.sendChatMessage(requestData);
});

export const getUserInfluencerChat = createAsyncThunk('chat/getUserInfluencerChat', async requestData => {
  return await ChatService.getUserInfluencerChat(requestData);
});
