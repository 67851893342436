import styled from 'styled-components';
import { Text10Regular, Text16Bold } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  border-bottom: 1px solid #1d1229;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  height: 7.2rem;
  padding: 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #05030b;
  position: fixed;
  top: 0;
  max-width: 83.2rem;
  z-index: 1000;
  border-right: 1px solid #1d1229;
  gap: 1rem;

  @media only screen and (max-width: 1440px) {
    right: 0;
    width: unset;
    left: 60.8rem;
    border-right: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 1.2rem;
    height: 6.4rem;
    left: 0;
    right: 0;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const BackIcon = styled.div`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1.2rem;

  svg {
    width: 2.4rem;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-right: 1.2rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
`;

export const NameText = styled(Text16Bold)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;

  @media only screen and (max-width: 380px) {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 380px) {
    font-size: 1.3rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const OnlineContainer = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;

export const Circle = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #00da00;
`;

export const OnlineText = styled(Text10Regular)`
  letter-spacing: -0.408px;
  color: #00da00;
`;

export const VideoIconWrapper = styled.div`
  border-radius: 50%;
  border: 1px solid #3a1f68;
  background: linear-gradient(180deg, #3b1f6b 0%, #180f2a 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.23);
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PhoneIconWrapper = styled.div`
  border-radius: 50%;
  border: 1px solid #4d2595;
  background: linear-gradient(180deg, #ab75ff 0%, #4d2595 100%);
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
