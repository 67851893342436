import React, { createContext, useContext, useState } from 'react';
import Modal from '../utils/Modal/Modal';
import AuthModalContent from '../../auth/AuthModalContent/AuthModalContent';
import { useDispatch } from 'react-redux';
import { setInputEmail } from '../../../store/slices/auth/slice';

const AuthModalContext = createContext({
  openModal: (modalStep, onSuccess) => {},
});

export const AuthModalProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalStep, setModalStep] = useState(null);
  const [onSuccess, setOnSuccess] = useState(() => () => {});

  const openModal = (modalStep, onSuccess) => {
    setIsOpen(true);
    setModalStep(modalStep);
    setOnSuccess(() => onSuccess);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalStep(null);
    dispatch(setInputEmail({ email: null }));
    setOnSuccess(() => () => {});
  };

  return (
    <AuthModalContext.Provider value={{ openModal }}>
      {children}
      <AuthModal isOpen={isOpen} onClose={closeModal} modalStep={modalStep} onSuccess={onSuccess} />
    </AuthModalContext.Provider>
  );
};

const AuthModal = ({ isOpen, onClose, modalStep, onSuccess }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} shouldCloseOnOverlayClick={false}>
      <AuthModalContent onClose={onClose} modalStep={modalStep} onSuccess={onSuccess} />
    </Modal>
  );
};

export const useAuthModal = () => {
  const { openModal } = useContext(AuthModalContext);
  return [openModal];
};

export default AuthModal;
