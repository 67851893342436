import React, { useCallback, useEffect, useState } from 'react';
import { ChatsPageSkeleton, LogoComponent, PremiumButton } from '../../components';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import {
  ChatRow,
  Container,
  DateText,
  DotText,
  ExploreButton,
  ExploreContainer,
  Header,
  Image,
  ImageWrapper,
  LeftContainer,
  LogoContainer,
  MessageInfo,
  MessageText,
  NameContainer,
  PremiumButtonWrapper,
  RightContainer,
  RowInfo,
  StyledPromoImage,
  Text,
  TextContainer,
  Title,
} from './ChatPage.styles';
import { Text16ExtraBold, Text16Regular, Text20Bold } from '../../components/utils/Text/Text.styles';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { CHAT, CHAT_PREVIEW, HOME } from '../../components/App/routes';
import { selectUser } from '../../store/slices/user/slice';
import { getVisitedChats, mergeInfluencerChats, timeSpentSince } from '../../utils/util';
import { MESSAGE_TYPE } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import PromoImage from '../../assets/images/promo.png';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { selectVisitedChatsChanged, setVisitedChatsChanged } from '../../store/slices/chat/slice';

const ChatPage = () => {
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectLoggedIn);
  const visitedChatsChanged = useSelector(selectVisitedChatsChanged);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { influencerUsername } = useParams();

  const getChats = useCallback(() => {
    const visitedChats = getVisitedChats(user);
    let userInfluencers = [];
    if (user) {
      userInfluencers = user.userInfluencers;
    }
    const visitedChatsArray = Object.values(visitedChats);
    if (!userInfluencers.length) {
      return visitedChatsArray;
    } else {
      return mergeInfluencerChats(userInfluencers, visitedChatsArray);
    }
  }, [user]);

  const [chats, setChats] = useState(() => getChats());

  useEffect(() => {
    if (visitedChatsChanged) {
      setChats(getChats());
      dispatch(setVisitedChatsChanged(false));
    }
  }, [dispatch, visitedChatsChanged, getChats]);

  useEffect(() => {
    if (user?.id) {
      setChats(getChats());
    }
  }, [user?.id, getChats]);

  return (
    <Container $chatOpened={!!influencerUsername}>
      <Header $chatOpened={!!influencerUsername}>
        <LogoContainer>
          <LogoComponent id="chatPage" />
        </LogoContainer>
        <Text16ExtraBold>Chats</Text16ExtraBold>
        <PremiumButtonWrapper>
          <PremiumButton />
        </PremiumButtonWrapper>
      </Header>
      <LeftContainer $hidden={!!influencerUsername}>
        <Title>Chats</Title>
        {isLoggedIn === null ? (
          <ChatsPageSkeleton />
        ) : (
          chats.map(chat => {
            return (
              <ChatItem
                key={chat.influencerUsername}
                chat={chat}
                onClick={() => {
                  navigate(`/${CHAT}/${CHAT_PREVIEW.replace(':influencerUsername', chat.influencerUsername)}`);
                }}
              />
            );
          })
        )}
      </LeftContainer>
      <RightContainer>
        {influencerUsername ? <Outlet /> : <EmptyChat hidden={!!chats.length || isLoggedIn === null} />}
      </RightContainer>
    </Container>
  );
};

const EmptyChat = ({ hidden }) => {
  const navigate = useNavigate();

  return (
    <ExploreContainer $hidden={hidden}>
      <StyledPromoImage src={PromoImage} alt="promo-image" />
      <TextContainer>
        <Text20Bold>Find your perfect girlfriend</Text20Bold>
        <Text>Find your perfect match and enjoy immersive messaging, calls and pictures 24/7.</Text>
      </TextContainer>
      <ExploreButton
        onClick={() => {
          navigate(`${HOME}`);
        }}>
        Explore
      </ExploreButton>
    </ExploreContainer>
  );
};

const ChatItem = ({ onClick, chat }) => {
  const lastMessage = chat.chat[chat.chat.length - 1];
  let lastMessageContent = '';
  if (lastMessage.type === MESSAGE_TYPE.VOICE) {
    lastMessageContent = 'Voice Message';
  } else if (lastMessage.type === MESSAGE_TYPE.PREMIUM_PHOTO) {
    lastMessageContent = '💣 Photo';
  } else {
    lastMessageContent = lastMessage.content;
  }

  return (
    <ChatRow onClick={onClick}>
      <ImageWrapper>
        <Image src={chat.profilePhoto} />
      </ImageWrapper>
      <RowInfo>
        <NameContainer>
          <Text16Regular>{chat.name}</Text16Regular>
          <VerifiedIcon />
        </NameContainer>
        <MessageInfo>
          <MessageText>{lastMessageContent}</MessageText>
          <DotText>•</DotText>
          <DateText>{timeSpentSince(new Date(lastMessage.sentAt))}</DateText>
        </MessageInfo>
      </RowInfo>
    </ChatRow>
  );
};

export default ChatPage;
