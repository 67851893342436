import styled from 'styled-components';
import { Text14Bold } from '../../components/utils/Text/Text.styles';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1.6rem;
  padding-inline: 1.6rem;
  padding-bottom: 20rem;

  @media only screen and (min-width: 768px) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
`;

export const Content = styled.div`
  margin-left: 4.4rem;
`;

export const EmailText = styled(Text14Bold)`
  margin-top: 0.6rem;
  color: #af87f6;
  text-decoration: underline;
`;
