import React from 'react';
import {
  RightSideSkeleton,
  SkeletonAvatar,
  SkeletonContainer,
  SkeletonContent,
  SkeletonImage,
} from './ExplorePageSkeleton.styles';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
const ExplorePageSkeleton = () => {
  return Array.from({ length: 4 }).map((_, index) => {
    return (
      <SkeletonContainer key={index}>
        <SkeletonImage $animationTime={10}>
          <LogoIcon />
        </SkeletonImage>
        <RightSideSkeleton>
          <SkeletonAvatar $animationTime={30}>
            <LogoIcon width={50} height={50} />
          </SkeletonAvatar>
          <SkeletonContent $animationTime={20}>
            <LogoIcon width={50} height={50} />
          </SkeletonContent>
        </RightSideSkeleton>
      </SkeletonContainer>
    );
  });
};

export default ExplorePageSkeleton;
