import styled from 'styled-components';
import { Text14Light } from '../utils/Text/Text.styles';

export const Card = styled.div`
  border-radius: 20px;
  border: 1px solid #542c9a;
  background: radial-gradient(175.43% 171.41% at 50.14% 0%, #210f42 0%, #0a0416 100%);
  padding: 2rem;
  width: 36rem;
  height: 38rem;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  opacity: 0.05;
  transform: rotate(18deg);
  top: 2rem;
  right: 2rem;
`;

export const Text = styled(Text14Light)`
  color: #cebaf2;
  opacity: 0.5;
  margin-top: 0.8rem;
  margin-bottom: 2.4rem;
`;

export const ExploreButton = styled.button`
  border-radius: 12px;
  border: 1px solid #7438df;
  background: linear-gradient(0deg, #2b1754 0%, #2b1754 100%), #5e3d99;
  backdrop-filter: blur(2px);
  margin-top: auto;
  padding: 1rem 1.6rem;
  color: #fff;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 300;
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: 32rem;
  .slick-slide {
    div {
      margin-right: 0.2rem;
    }
  }
`;

export const ArrowButton = styled.button`
  background: linear-gradient(
    270deg,
    color(display-p3 0.0941 0.0471 0.1961) 11.41%,
    color(display-p3 0.0941 0.0471 0.2 / 0) 100%
  );
  width: 5rem;
  border: none;
  outline: none;
  position: absolute;
  right: 0rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;
