import { transferVisitedChatsToNewUser } from '../../../utils/util';
import { fetchUserInfo } from '../user/asyncThunk';
import {
  checkEmailExistence,
  googleSignUp,
  login,
  loginWithOneTimeToken,
  requestVerificationCode,
  verifyEmail,
} from './asyncThunk';

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    const { accessToken } = payload;
    state.accessToken = accessToken;
    state.loggedIn = true;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.loggedIn = false;
  });
};

export const googleSignUpBuilder = builder => {
  builder.addCase(googleSignUp.pending, state => {
    state.googleSignUpPending = true;
  });
  builder.addCase(googleSignUp.fulfilled, (state, action) => {
    const { accessToken, googleName, isNewUser, user } = action.payload;
    if (isNewUser) {
      transferVisitedChatsToNewUser(user);
    }
    state.loggedIn = true;
    state.accessToken = accessToken;
    state.googleSignUpPending = false;
    state.googleName = googleName;
  });
  builder.addCase(googleSignUp.rejected, state => {
    state.googleSignUpPending = false;
  });
};

export const checkEmailExistenceBuilder = builder => {
  builder.addCase(checkEmailExistence.pending, state => {
    state.checkEmailExistencePending = true;
  });
  builder.addCase(checkEmailExistence.fulfilled, (state, action) => {
    state.checkEmailExistencePending = false;
  });
  builder.addCase(checkEmailExistence.rejected, state => {
    state.checkEmailExistencePending = false;
  });
};

export const verifyEmailBuilder = builder => {
  builder.addCase(verifyEmail.pending, state => {
    state.verifyEmailPending = true;
  });
  builder.addCase(verifyEmail.fulfilled, (state, action) => {
    const { accessToken, isNewUser, user } = action.payload;
    if (isNewUser) {
      transferVisitedChatsToNewUser(user);
    }
    state.verifyEmailPending = false;
    state.loggedIn = true;
    state.accessToken = accessToken;
  });
  builder.addCase(verifyEmail.rejected, state => {
    state.verifyEmailPending = false;
  });
};

export const requestVerificationCodeBuilder = builder => {
  builder.addCase(requestVerificationCode.pending, state => {
    state.requestVerificationCodePending = true;
  });
  builder.addCase(requestVerificationCode.fulfilled, state => {
    state.requestVerificationCodePending = false;
  });
  builder.addCase(requestVerificationCode.rejected, state => {
    state.requestVerificationCodePending = false;
  });
};

export const loginBuilder = builder => {
  builder.addCase(login.pending, state => {
    state.loginPending = true;
  });
  builder.addCase(login.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.loginPending = false;
    state.loggedIn = true;
    state.accessToken = accessToken;
  });
  builder.addCase(login.rejected, state => {
    state.loginPending = false;
  });
};

export const loginWithOneTimeTokenBuilder = builder => {
  builder.addCase(loginWithOneTimeToken.pending, state => {
    state.loginWithOneTimeTokenPending = true;
  });
  builder.addCase(loginWithOneTimeToken.fulfilled, (state, action) => {
    const { accessToken } = action.payload;
    state.loginWithOneTimeTokenPending = false;
    state.loggedIn = true;
    state.accessToken = accessToken;
  });
  builder.addCase(loginWithOneTimeToken.rejected, state => {
    state.loginWithOneTimeTokenPending = false;
  });
};
