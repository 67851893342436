import {
  LOCAL_STORAGE_ITEMS,
  MESSAGE_TYPE,
  NUMBER_OF_FREE_MESSAGES_PER_DAY,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_PRICES_PER_MONTH,
  USER_ROLE,
} from './constants';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  format,
  isSameDay,
} from 'date-fns';
import LocalStorageService from '../services/LocalStorageService';
import { VATS } from './vats';

export const isSubscribed = subscribedTo => {
  if (!subscribedTo) {
    return false;
  }
  const subscribedToDate = new Date(subscribedTo);
  const currentDateTime = new Date();

  return subscribedToDate > currentDateTime;
};

export const getPriceForSelectedPlan = (subscriptionPeriod, subscriptionPlan) => {
  if (subscriptionPeriod === SUBSCRIPTION_PERIOD.ANNUALLY) {
    return SUBSCRIPTION_PRICES_PER_MONTH[subscriptionPlan][subscriptionPeriod] * 12;
  } else if (subscriptionPeriod === SUBSCRIPTION_PERIOD['3_MONTHS']) {
    return SUBSCRIPTION_PRICES_PER_MONTH[subscriptionPlan][subscriptionPeriod] * 3;
  }
  return SUBSCRIPTION_PRICES_PER_MONTH[subscriptionPlan][subscriptionPeriod];
};

export const capitalizeFirstLetter = string => {
  if (string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const convertTagValueToLabel = tagValue => {
  if (tagValue === 'black-afro') {
    return 'Black/Afro';
  }
  return tagValue
    .split('-')
    .map(tag => titleCase(tag))
    .join(' ');
};

const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const roundNumber = (number, decimals = 2) =>
  +(Math.round((number + Number.EPSILON) * 100) / 100).toFixed(decimals);

export const saveVisitedChatsToStorage = (influencer, user, secretMode) => {
  const visitedChatsString = LocalStorageService.getItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS);
  let visitedChats = JSON.parse(visitedChatsString);

  let chat = null;
  if (secretMode) {
    chat = getMessagesBasedOnSecretMode(secretMode);
  } else {
    chat = [
      {
        type: MESSAGE_TYPE.PHOTO,
        content: influencer.chat[0].content,
        sentAt: new Date(),
        role: USER_ROLE.INFLUENCER,
        secretModeId: influencer.chat[0].secretModeId,
        id: `${new Date().getTime().toString()}-1`,
      },
      {
        type: MESSAGE_TYPE.TEXT,
        content: influencer.chat[1].content,
        sentAt: new Date(),
        role: USER_ROLE.INFLUENCER,
        secretModeId: influencer.chat[0].secretModeId,
        id: `${new Date().getTime().toString()}-2`,
      },
    ];
  }

  const defaultSecretMode = influencer.secretModes.find(secretMode => secretMode.isDefault);

  let suggestedReply = null;
  if (secretMode) {
    suggestedReply = secretMode.suggestedReply;
  } else {
    suggestedReply = defaultSecretMode.suggestedReply;
  }

  let chatObject = {
    influencerUsername: influencer.influencerUsername,
    isVerified: influencer.isVerified,
    profilePhoto: influencer.profilePhoto,
    name: influencer.name,
    activeSecretModeId: secretMode ? secretMode.id : defaultSecretMode.id,
    suggestedReply,
    secretModes: influencer.secretModes,
    chat,
  };

  let objectToSave = null;
  let savedInfluencerChat = null;

  let userId = 'user';
  if (user) {
    userId = user.id;
  }

  if (visitedChats) {
    let foundUserChats = visitedChats[userId];

    // If there are visited chats for the user
    if (!foundUserChats) {
      objectToSave = { ...visitedChats, [userId]: { [influencer.influencerUsername]: chatObject } };
      savedInfluencerChat = chatObject;
    } else {
      let influencerChat = foundUserChats[influencer.influencerUsername];

      // If there are visited chats for the influencer
      if (!influencerChat) {
        objectToSave = {
          ...visitedChats,
          [userId]: { [influencer.influencerUsername]: chatObject, ...foundUserChats },
        };
        savedInfluencerChat = chatObject;
      } else {
        let newChat = [];
        if (influencerChat.chat.length >= 20) {
          const newArray = influencerChat.chat.slice(2);
          newChat = [...newArray, ...chat];
        } else {
          newChat = [...influencerChat.chat, ...chat];
        }
        const newInfluencerChat = {
          ...influencerChat,
          activeSecretModeId: chatObject.activeSecretModeId,
          chat: newChat,
        };
        savedInfluencerChat = newInfluencerChat;

        // If there is chat for the influencer extend the chat
        const { [influencer.influencerUsername]: _, ...newObjFoundUserChats } = foundUserChats;

        objectToSave = {
          ...visitedChats,
          [userId]: {
            [influencer.influencerUsername]: newInfluencerChat,
            ...newObjFoundUserChats,
          },
        };
      }
    }
  } else {
    //  If there are no visited chats in the local storage

    objectToSave = {
      [userId]: { [influencer.influencerUsername]: chatObject },
    };
    savedInfluencerChat = chatObject;
  }

  LocalStorageService.setItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS, JSON.stringify(objectToSave));
  return savedInfluencerChat;
};

export const transferVisitedChatsToNewUser = user => {
  const visitedChatsString = LocalStorageService.getItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS);
  let visitedChats = JSON.parse(visitedChatsString);

  if (!visitedChats) {
    return;
  }

  const { user: guestVisitedChats, ...retVisitedChats } = visitedChats;

  if (guestVisitedChats) {
    const objectToSave = {
      ...retVisitedChats,
      [user.id]: guestVisitedChats,
    };

    LocalStorageService.setItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS, JSON.stringify(objectToSave));
  }
};

const getMessagesBasedOnSecretMode = secretMode => {
  return [
    {
      type: MESSAGE_TYPE.PHOTO,
      content: secretMode.welcomePhoto,
      sentAt: new Date(),
      role: USER_ROLE.INFLUENCER,
      secretModeId: secretMode.id,
      id: `${new Date().getTime().toString()}-1`,
    },
    {
      type: MESSAGE_TYPE.TEXT,
      content: secretMode.welcomeMessage,
      sentAt: new Date(),
      role: USER_ROLE.INFLUENCER,
      secretModeId: secretMode.id,
      id: `${new Date().getTime().toString()}-2`,
    },
  ];
};

export const getVisitedChats = user => {
  const visitedChatsString = LocalStorageService.getItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS);
  const visitedChats = JSON.parse(visitedChatsString);

  let userId = 'user';
  if (user) {
    userId = user.id;
  }

  if (visitedChats && visitedChats[userId]) {
    return visitedChats[userId];
  } else {
    return {};
  }
};

export const removeUsersInfluencerVisitedChat = (user, influencerUsername) => {
  const visitedChatsString = LocalStorageService.getItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS);
  let visitedChats = JSON.parse(visitedChatsString);

  let userId = 'user';
  if (user) {
    userId = user.id;
  }

  if (visitedChats && visitedChats[userId]) {
    if (visitedChats[userId][influencerUsername]) {
      visitedChats[userId][influencerUsername].chat = [];
    }
    LocalStorageService.setItem(LOCAL_STORAGE_ITEMS.VISITED_CHATS, JSON.stringify(visitedChats));
  }
};

export const formatCustomDate = date => {
  return `${format(date, 'h:mm a')}`;
};

export const timeSpentSince = date => {
  const now = new Date();
  const seconds = differenceInSeconds(now, date);

  if (seconds < 30) {
    return 'now';
  } else if (seconds < 60) {
    return '1m';
  }

  const minutes = differenceInMinutes(now, date);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = differenceInHours(now, date);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = differenceInDays(now, date);
  if (days < 30) {
    return `${days}d`;
  }

  const months = differenceInMonths(now, date);
  if (months < 12) {
    return `${months}m`;
  }

  const years = differenceInYears(now, date);
  return `${years}y`;
};

export const getVatByCountry = country => {
  const vat = VATS[country];
  if (vat) {
    return vat;
  } else {
    return 0;
  }
};

export const mergeInfluencerChats = (influencerChats1, influencerChats2) => {
  const mergedMap = {};

  const addToMap = array => {
    array.forEach(item => {
      if (item.chat.length) {
        if (mergedMap[item.influencerUsername]) {
          mergedMap[item.influencerUsername].chat = mergedMap[item.influencerUsername].chat.concat(item.chat);
          if (item.chat.length > 0) {
            mergedMap[item.influencerUsername].lastTimeActive = item.chat[item.chat.length - 1].sentAt;
          }
        } else {
          mergedMap[item.influencerUsername] = { ...item, lastTimeActive: item.chat[item.chat.length - 1].sentAt };
        }
      }
    });
  };

  addToMap(influencerChats1);
  addToMap(influencerChats2);

  let mergedArray = Object.values(mergedMap);
  mergedArray = mergedArray.sort((a, b) => new Date(b.lastTimeActive) - new Date(a.lastTimeActive));

  return mergedArray;
};

export const splitArrayInHalf = arr => {
  const middleIndex = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex);

  return [firstHalf, secondHalf];
};

export const groupMessagesByDay = messages => {
  const groupedMessages = {};
  let hasUserMessages = false;

  messages.forEach(message => {
    const date = format(new Date(message.sentAt), 'yyyy-MM-dd');
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
    if (message.role === 'user') {
      hasUserMessages = true;
    }
  });

  return { groupedMessages, hasUserMessages };
};

export const messageLimitExceeded = messageLimiter => {
  return isSameDay(messageLimiter.date, new Date()) && messageLimiter.count >= NUMBER_OF_FREE_MESSAGES_PER_DAY;
};
