import React from 'react';
import { Content, PageContainer, Text, TextGroup } from './PrivacyPolicyPage.styles';
import { Header } from '../../components';
import { Text14Bold, Text15Bold, Text16Bold, Text17Bold, Text20Bold } from '../../components/utils/Text/Text.styles';

const PrivacyPolicyPage = () => {
  return (
    <PageContainer>
      <Header title="Privacy Policy" />
      <Content>
        <Text14Bold>Last updated: July 09, 2024</Text14Bold>
        <Text>
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </Text>
        <Text>
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy.
        </Text>
        <Text20Bold>Interpretation and Definitions</Text20Bold>
        <TextGroup>
          <Text16Bold>Interpretation</Text16Bold>
          <Text>
            The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Definitions</Text16Bold>
          <Text>
            For the purposes of this Privacy Policy:
            <br /> - Account means a unique account created for You to access our Service or parts of our Service.
            <br />- Affiliate means an entity that controls, is controlled by or is under common control with a party,
            where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled
            to vote for election of directors or other managing authority. <br />- Business, for the purpose of
            CCPA/CPRA, refers to the Company as the legal entity that collects Consumers' personal information and
            determines the purposes and means of the processing of Consumers' personal information, or on behalf of
            which such information is collected and that alone, or jointly with others, determines the purposes and
            means of the processing of consumers' personal information, that does business in the State of California.
            <br />- CCPA and/or CPRA refers to the California Consumer Privacy Act (the "CCPA") as amended by the
            California Privacy Rights Act of 2020 (the "CPRA"). <br />- Company (referred to as either "the Company",
            "We", "Us" or "Our" in this Agreement) refers to OCAI LTD, Themistokli Dervi, 6, Flat/Office D4, 1066,
            Nicosia, Cyprus.
            <br />- For the purpose of the GDPR, the Company is the Data Controller.
            <br />- Consumer, for the purpose of the CCPA/CPRA, means a natural person who is a California resident. A
            resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary
            or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a
            temporary or transitory purpose.
            <br />- Cookies are small files that are placed on Your computer, mobile device or any other device by a
            website, containing the details of Your browsing history on that website among its many uses.
            <br />- Country refers to: Cyprus
            <br />- Data Controller, for the purposes of the GDPR (General Data Protection Regulation), refers to the
            Company as the legal person which alone or jointly with others determines the purposes and means of the
            processing of Personal Data.
            <br />- Device means any device that can access the Service such as a computer, a cellphone or a digital
            tablet.
            <br />- Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular
            the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for
            allowing internet users to control the tracking of their online activities across websites.
            <br />- Facebook Fan Page is a public profile named OnlyChats specifically created by the Company on the
            Facebook social network, accessible from https://www.facebook.com/people/OnlyChats/61561965305835/
            <br />- GDPR refers to EU General Data Protection Regulation.
            <br />- Personal Data is any information that relates to an identified or identifiable individual.
            <br />- For the purposes of GDPR, Personal Data means any information relating to You such as a name, an
            identification number, location data, online identifier or to one or more factors specific to the physical,
            physiological, genetic, mental, economic, cultural or social identity.
            <br />- For the purposes of the CCPA/CPRA, Personal Data means any information that identifies, relates to,
            describes or is capable of being associated with, or could reasonably be linked, directly or indirectly,
            with You. <br />- Service refers to the Website.
            <br />- Service Provider means any natural or legal person who processes the data on behalf of the Company.
            It refers to third-party companies or individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered
            Data Processors.
            <br />- Third-party Social Media Service refers to any website or any social network website through which a
            User can log in or create an account to use the Service.
            <br />- Usage Data refers to data collected automatically, either generated by the use of the Service or
            from the Service infrastructure itself (for example, the duration of a page visit).
            <br />- Website refers to OnlyChats, accessible from https://www.onlychats.com
            <br />- You means the individual accessing or using the Service, or the company, or other legal entity on
            behalf of which such individual is accessing or using the Service, as applicable.
            <br />- Under GDPR, You can be referred to as the Data Subject or as the User as you are the individual
            using the Service.
          </Text>
        </TextGroup>
        <Text20Bold>Collecting and Using Your Personal Data</Text20Bold>
        <TextGroup>
          <Text16Bold>Personal Data</Text16Bold>
          <Text>
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that
            can be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:
            <br />- Email address
            <br />- Usage Data
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Usage Data</Text16Bold>
          <Text>Usage Data is collected automatically when using the Service.</Text>
          <Text>
            Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other diagnostic data.
          </Text>
          <Text>
            When You access the Service by or through a mobile device, We may collect certain information automatically,
            including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </Text>
          <Text>
            We may also collect information that Your browser sends whenever You visit our Service or when You access
            the Service by or through a mobile device.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Information from Third-Party Social Media Services</Text16Bold>
          <Text>
            The Company allows You to create an account and log in to use the Service through the following Third-party
            Social Media Services:
            <br />- Facebook
            <br />- Telegram
          </Text>
          <Text>
            If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may
            collect Personal data that is already associated with Your Third-Party Social Media Service's account, such
            as Your name, Your email address, Your activities or Your contact list associated with that account.
          </Text>
          <Text>
            You may also have the option of sharing additional information with the Company through Your Third-Party
            Social Media Service's account. If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to use, share, and store it in a manner
            consistent with this Privacy Policy.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Tracking Technologies and Cookies</Text16Bold>
          <Text>
            We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
            to improve and analyze Our Service. The technologies We use may include:
            <br />- Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your
            browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept
            Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting
            so that it will refuse Cookies, our Service may use Cookies.
            <br />- Web Beacons. Certain sections of our Service and our emails may contain small electronic files known
            as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
            for example, to count users who have visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain section and verifying system and server
            integrity).
          </Text>
          <Text>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
            Learn more about cookies on the Privacy Policies website article.
          </Text>
          <Text>
            We use both Session and Persistent Cookies for the purposes set out below:
            <br />- Necessary / Essential Cookies
            <br />
            &emsp;Type: Session Cookies
            <br />
            &emsp;Administered by: Us
            <br />
            &emsp;Purpose: These Cookies are essential to provide You with services available through the Website and to
            enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user
            accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use
            these Cookies to provide You with those services.
            <br />- Cookies Policy / Notice Acceptance Cookies
            <br />
            &emsp;Type: Persistent Cookies
            <br />
            &emsp;Administered by: Us
            <br />
            &emsp;Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
            <br />- Functionality Cookies
            <br />
            &emsp;Type: Persistent Cookies
            <br />
            &emsp;Administered by: Us
            <br />
            &emsp;Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
            remembering your login details or language preference. The purpose of these Cookies is to provide You with a
            more personal experience and to avoid You having to re-enter your preferences every time You use the
            Website.
            <br />- Tracking and Performance Cookies
            <br />
            &emsp;Type: Persistent Cookies
            <br />
            &emsp;Administered by: Third-Parties
            <br />
            &emsp;Purpose: These Cookies are used to track information about traffic to the Website and how users use
            the Website. The information gathered via these Cookies may directly or indirectly identify you as an
            individual visitor. This is because the information collected is typically linked to a pseudonymous
            identifier associated with the device you use to access the Website. We may also use these Cookies to test
            new pages, features or new functionality of the Website to see how our users react to them.
          </Text>
          <Text>
            For more information about the cookies we use and your choices regarding cookies, please visit our Cookies
            Policy or the Cookies section of our Privacy Policy.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Use of Your Personal Data</Text16Bold>
          <Text>
            The Company may use Personal Data for the following purposes:
            <br />- To provide and maintain our Service, including to monitor the usage of our Service.
            <br />- To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You
            provide can give You access to different functionalities of the Service that are available to You as a
            registered user.
            <br />- For the performance of a contract: the development, compliance and undertaking of the purchase
            contract for the products, items or services You have purchased or of any other contract with Us through the
            Service.
            <br />- To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of
            electronic communication, such as a mobile application's push notifications regarding updates or informative
            communications related to the functionalities, products or contracted services, including the security
            updates, when necessary or reasonable for their implementation.
            <br />- To provide You with news, special offers and general information about other goods, services and
            events which we offer that are similar to those that you have already purchased or enquired about unless You
            have opted not to receive such information.
            <br />- To manage Your requests: To attend and manage Your requests to Us.
            <br />- To deliver targeted advertising to You: We may use Your information to develop and display content
            and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and
            to measure its effectiveness.
            <br />- For business transfers: We may use Your information to evaluate or conduct a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether
            as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held
            by Us about our Service users is among the assets transferred.
            <br />- For other purposes: We may use Your information for other purposes, such as data analysis,
            identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your experience.
            <br /> We may share Your personal information in the following situations:
            <br />
            -With Service Providers: We may share Your personal information with Service Providers to monitor and
            analyze the use of our Service, to advertise on third party websites to You after You visited our Service,
            for payment processing, to contact You.
            <br />
            -For business transfers: We may share or transfer Your personal information in connection with, or during
            negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our
            business to another company.
            <br />
            -With Affiliates: We may share Your information with Our affiliates, in which case we will require those
            affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that are under common control with Us.
            <br />
            -With business partners: We may share Your information with Our business partners to offer You certain
            products, services or promotions.
            <br />
            -With other users: when You share personal information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be publicly distributed outside. If You interact
            with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party
            Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other
            users will be able to view descriptions of Your activity, communicate with You and view Your profile.
            <br />
            -With Your consent: We may disclose Your personal information for any other purpose with Your consent.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Retention of Your Personal Data</Text16Bold>
          <Text>
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
            disputes, and enforce our legal agreements and policies.
          </Text>
          <Text>
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
            a shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Transfer of Your Personal Data</Text16Bold>
          <Text>
            Your information, including Personal Data, is processed at the Company's operating offices and in any other
            places where the parties involved in the processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
          </Text>
          <Text>
            Your consent to this Privacy Policy followed by Your submission of such information represents Your
            agreement to that transfer.
          </Text>
          <Text>
            The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including the security of Your data and other
            personal information.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Delete Your Personal Data</Text16Bold>
          <Text>
            You have the right to delete or request that We assist in deleting the Personal Data that We have collected
            about You.
          </Text>
          <Text>
            Our Service may give You the ability to delete certain information about You from within the Service.
          </Text>
          <Text>
            You may update, amend, or delete Your information at any time by signing in to Your Account, if you have
            one, and visiting the account settings section that allows you to manage Your personal information. You may
            also contact Us to request access to, correct, or delete any personal information that You have provided to
            Us.
          </Text>
          <Text>
            Please note, however, that We may need to retain certain information when we have a legal obligation or
            lawful basis to do so.
          </Text>
        </TextGroup>
        <Text17Bold>Disclosure of Your Personal Data</Text17Bold>
        <TextGroup>
          <Text16Bold>Business Transactions</Text16Bold>
          <Text>
            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
            will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
            Policy.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Law enforcement</Text16Bold>
          <Text>
            Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
            by law or in response to valid requests by public authorities (e.g. a court or a government agency).
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Other legal requirements</Text16Bold>
          <Text>
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            <br />- Comply with a legal obligation
            <br />- Protect and defend the rights or property of the Company
            <br />- Prevent or investigate possible wrongdoing in connection with the Service
            <br />- Protect the personal safety of Users of the Service or the public
            <br />- Protect against legal liability
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Security of Your Personal Data</Text16Bold>
          <Text>
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
            means to protect Your Personal Data, We cannot guarantee its absolute security.
          </Text>
        </TextGroup>
        <Text20Bold>Detailed Information on the Processing of Your Personal Data</Text20Bold>
        <Text>
          The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store,
          use, process and transfer information about Your activity on Our Service in accordance with their Privacy
          Policies.
        </Text>
        <TextGroup>
          <Text16Bold>Analytics</Text16Bold>
          <Text>We may use third-party Service providers to monitor and analyze the use of our Service.</Text>
          <Text16Bold>Google Analytics</Text16Bold>
          <Text>
            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
            Google uses the data collected to track and monitor the use of our Service. This data is shared with other
            Google services. Google may use the collected data to contextualize and personalize the ads of its own
            advertising network.
          </Text>
          <Text>
            You can opt-out of having made your activity on the Service available to Google Analytics by installing the
            Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
            analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
          </Text>
          <Text>
            For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:
            https://policies.google.com/privacy
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Email Marketing</Text16Bold>
          <Text>
            We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other
            information that may be of interest to You. You may opt-out of receiving any, or all, of these
            communications from Us by following the unsubscribe link or instructions provided in any email We send or by
            contacting Us.
          </Text>
          <Text>We may use Email Marketing Service Providers to manage and send emails to You.</Text>
          <Text16Bold>Postmark</Text16Bold>
          <Text>Their Privacy Policy can be viewed at https://postmarkapp.com/privacy-policy</Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Payments</Text16Bold>
          <Text>
            We may provide paid products and/or services within the Service. In that case, we may use third-party
            services for payment processing (e.g. payment processors).
          </Text>
          <Text>
            We will not store or collect Your payment card details. That information is provided directly to Our
            third-party payment processors whose use of Your personal information is governed by their Privacy Policy.
            These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
            requirements help ensure the secure handling of payment information.
          </Text>
          <Text16Bold>Smart Glocal Services Limited</Text16Bold>
          <Text>Their Privacy Policy can be viewed at https://smart-glocal.com/privacy-policy</Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Behavioral Remarketing</Text16Bold>
          <Text>
            The Company uses remarketing services to advertise to You after You accessed or visited our Service. We and
            Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and
            understand how You use our Service so that We can improve our Service to reflect Your interests and serve
            You advertisements that are likely to be of more interest to You.
          </Text>
          <Text>
            These third-party vendors collect, store, use, process and transfer information about Your activity on Our
            Service in accordance with their Privacy Policies and to enable Us to:
            <br />- Measure and analyze traffic and browsing activity on Our Service
            <br />- Show advertisements for our products and/or services to You on third-party websites or apps
            <br />- Measure and analyze the performance of Our advertising campaigns
          </Text>
          <Text>
            Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser
            settings that block cookies. Your browser may not permit You to block such technologies. You can use the
            following third-party tools to decline the collection and use of information for the purpose of serving You
            interest-based advertising:
            <br />- The NAI's opt-out platform: http://www.networkadvertising.org/choices/
            <br />- The EDAA's opt-out platform http://www.youronlinechoices.com/
            <br />- The DAA's opt-out platform: http://optout.aboutads.info/?c=2&lang=EN
          </Text>
          <Text>
            You may opt-out of all personalized advertising by enabling privacy features on Your mobile device such as
            Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help system for
            more information.
          </Text>
          <Text>
            We may share information, such as hashed email addresses (if available) or other online identifiers
            collected on Our Service with these third-party vendors. This allows Our third-party vendors to recognize
            and deliver You ads across devices and browsers. To read more about the technologies used by these
            third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor
            listed below.
          </Text>
          <Text>
            The third-party vendors We use are:
            <br />- Google Ads (AdWords)
            <br />- Google Ads (AdWords) remarketing service is provided by Google Inc.
            <br />- You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network
            ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads
            <br />- Google also recommends installing the Google Analytics Opt-out Browser Add-on -
            https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
            <br />- For more information on the privacy practices of Google, please visit the Google Privacy & Terms web
            page: https://policies.google.com/privacy
            <br />- Twitter
            <br />- Twitter remarketing service is provided by Twitter Inc.
            <br />- You can opt-out from Twitter's interest-based ads by following their instructions:
            https://support.twitter.com/articles/20170405
            <br />- You can learn more about the privacy practices and policies of Twitter by visiting their Privacy
            Policy page: https://twitter.com/privacy
            <br />- Facebook
            <br />- Facebook remarketing service is provided by Facebook Inc.
            <br />- You can learn more about interest-based advertising from Facebook by visiting this page:
            https://www.facebook.com/help/516147308587266
            <br />- To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:
            https://www.facebook.com/help/568137493302217
            <br />- Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by
            the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies
            through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital
            Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital
            Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device
            settings.
            <br />- For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:
            https://www.facebook.com/privacy/explanation
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Usage, Performance and Miscellaneous</Text16Bold>
          <Text>
            We may use third-party Service Providers to maintain and improve our Service.
            <br />- Invisible reCAPTCHA
            <br />- We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.
            <br />- The reCAPTCHA service may collect information from You and from Your Device for security purposes.
            <br />- The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google:
            https://www.google.com/intl/en/policies/privacy/
          </Text>
        </TextGroup>
        <Text20Bold>GDPR Privacy</Text20Bold>
        <TextGroup>
          <Text16Bold>Legal Basis for Processing Personal Data under GDPR</Text16Bold>
          <Text>
            We may process Personal Data under the following conditions:
            <br />- Consent: You have given Your consent for processing Personal Data for one or more specific purposes.
            <br />- Performance of a contract: Provision of Personal Data is necessary for the performance of an
            agreement with You and/or for any pre-contractual obligations thereof.
            <br />- Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to
            which the Company is subject.
            <br />- Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or
            of another natural person.
            <br />- Public interests: Processing Personal Data is related to a task that is carried out in the public
            interest or in the exercise of official authority vested in the Company.
            <br />- Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate
            interests pursued by the Company.
          </Text>
          <Text>
            In any case, the Company will gladly help to clarify the specific legal basis that applies to the
            processing, and in particular whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Your Rights under the GDPR</Text16Bold>
          <Text>
            The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can
            exercise Your rights.
          </Text>
          <Text>
            You have the right under this Privacy Policy, and by law if You are within the EU, to:
            <br />- Request access to Your Personal Data. The right to access, update or delete the information We have
            on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly
            within Your account settings section. If you are unable to perform these actions yourself, please contact Us
            to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
            <br />- Request correction of the Personal Data that We hold about You. You have the right to have any
            incomplete or inaccurate information We hold about You corrected.
            <br />- Object to processing of Your Personal Data. This right exists where We are relying on a legitimate
            interest as the legal basis for Our processing and there is something about Your particular situation, which
            makes You want to object to our processing of Your Personal Data on this ground. You also have the right to
            object where We are processing Your Personal Data for direct marketing purposes.
            <br />- Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal
            Data when there is no good reason for Us to continue processing it.
            <br />- Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have
            chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this
            right only applies to automated information which You initially provided consent for Us to use or where We
            used the information to perform a contract with You.
            <br />- Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If
            You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities
            of the Service.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Exercising of Your GDPR Data Protection Rights</Text16Bold>
          <Text>
            You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please
            note that we may ask You to verify Your identity before responding to such requests. If You make a request,
            We will try our best to respond to You as soon as possible.
          </Text>
          <Text>
            You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal
            Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data
            protection authority in the EEA.
          </Text>
        </TextGroup>
        <Text20Bold>Facebook Fan Page</Text20Bold>
        <TextGroup>
          <Text16Bold>Data Controller for the Facebook Fan Page</Text16Bold>
          <Text>
            The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of
            the Facebook Fan Page https://www.facebook.com/people/OnlyChats/61561965305835/, the Company and the
            operator of the social network Facebook are Joint Controllers.
          </Text>
          <Text>
            The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan
            Page, among other things. These terms are mostly based on the Facebook Terms of Service:
            https://www.facebook.com/terms.php
          </Text>
          <Text>
            Visit the Facebook Privacy Policy https://www.facebook.com/policy.php for more information about how
            Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy
            Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Facebook Insights</Text16Bold>
          <Text>
            We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the
            basis of the GDPR, in order to obtain anonymized statistical data about Our users.
          </Text>
          <Text>
            For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each
            Cookie contains a unique identifier code and remains active for a period of two years, except when it is
            deleted before the end of this period.
          </Text>
          <Text>
            Facebook receives, records and processes the information stored in the Cookie, especially when the user
            visits the Facebook services, services that are provided by other members of the Facebook Fan Page and
            services by other companies that use Facebook services.
          </Text>
          <Text>
            For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here:
            https://www.facebook.com/privacy/explanation
          </Text>
        </TextGroup>
        <Text20Bold>CCPA/CPRA Privacy Notice </Text20Bold>
        <Text>
          This privacy notice section for California residents supplements the information contained in Our Privacy
          Policy and it applies solely to all visitors, users, and others who reside in the State of California.
        </Text>
        <TextGroup>
          <Text16Bold>Categories of Personal Information Collected</Text16Bold>
          <Text>
            We collect information that identifies, relates to, describes, references, is capable of being associated
            with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The
            following is a list of categories of personal information which we may collect or may have been collected
            from California residents within the last twelve (12) months.
          </Text>
          <Text>
            Please note that the categories and examples provided in the list below are those defined in the CCPA/CPRA.
            This does not mean that all examples of that category of personal information were in fact collected by Us,
            but reflects our good faith belief to the best of Our knowledge that some of that information from the
            applicable category may be and may have been collected. For example, certain categories of personal
            information would only be collected if You provided such personal information directly to Us.
          </Text>
          <Text15Bold>Category A: Identifiers.</Text15Bold>
          <Text>
            Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet
            Protocol address, email address, account name, driver's license number, passport number, or other similar
            identifiers.
          </Text>
          <Text>Collected: Yes.</Text>
          <Text15Bold>
            Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ.
            Code § 1798.80(e)).
          </Text15Bold>
          <Text>
            Examples: A name, signature, Social Security number, physical characteristics or description, address,
            telephone number, passport number, driver's license or state identification card number, insurance policy
            number, education, employment, employment history, bank account number, credit card number, debit card
            number, or any other financial information, medical information, or health insurance information. Some
            personal information included in this category may overlap with other categories.
          </Text>
          <Text>Collected: Yes.</Text>
          <Text15Bold>Category C: Protected classification characteristics under California or federal law.</Text15Bold>
          <Text>
            Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed,
            marital status, medical condition, physical or mental disability, sex (including gender, gender identity,
            gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or
            military status, genetic information (including familial genetic information).
          </Text>
          <Text>Collected: No.</Text>
          <Text15Bold>Category D: Commercial information.</Text15Bold>
          <Text>Examples: Records and history of products or services purchased or considered.</Text>
          <Text>Collected: Yes.</Text>
          <Text15Bold>Category E: Biometric information.</Text15Bold>
          <Text>
            Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to
            extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and
            voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or
            exercise data.
          </Text>
          <Text>Collected: No.</Text>
          <Text15Bold>Category F: Internet or other similar network activity.</Text15Bold>
          <Text>Examples: Interaction with our Service or advertisement.</Text>
          <Text>Collected: Yes.</Text>
          <Text15Bold>Category G: Geolocation data.</Text15Bold>
          <Text>Examples: Approximate physical location.</Text>
          <Text>Collected: No.</Text>
          <Text15Bold>Category H: Sensory data.</Text15Bold>
          <Text>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</Text>
          <Text>Collected: No.</Text>
          <Text15Bold>Category I: Professional or employment-related information.</Text15Bold>
          <Text>Examples: Current or past job history or performance evaluations.</Text>
          <Text>Collected: No.</Text>
          <Text15Bold>
            Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C.
            Section 1232g, 34 C.F.R. Part 99)).
          </Text15Bold>
          <Text>
            Examples: Education records directly related to a student maintained by an educational institution or party
            acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification
            codes, student financial information, or student disciplinary records.
          </Text>
          <Text>Collected: No.</Text>
          <Text15Bold>Category K: Inferences drawn from other personal information.</Text15Bold>
          <Text>
            Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions,
            behavior, attitudes, intelligence, abilities, and aptitudes.
          </Text>
          <Text>Collected: No.</Text>
          <Text15Bold>Category L: Sensitive personal information.</Text15Bold>
          <Text>Examples: Account login and password information, geolocation data.</Text>
          <Text>Collected: Yes.</Text>
        </TextGroup>
        <TextGroup>
          <Text>
            Under CCPA/CPRA, personal information does not include:
            <br />- Publicly available information from government records
            <br />- Deidentified or aggregated consumer information
            <br />- Information excluded from the CCPA/CPRA's scope, such as:
            <br /> &emsp; - Health or medical information covered by the Health Insurance Portability and Accountability
            Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial
            data
            <br /> &emsp; - Personal Information covered by certain sector-specific privacy laws, including the Fair
            Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy
            Act (FIPA), and the Driver's Privacy Protection Act of 1994
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Sources of Personal Information</Text16Bold>
          <Text>
            We obtain the categories of personal information listed above from the following categories of sources:
            <br />- Directly from You. For example, from the forms You complete on our Service, preferences You express
            or provide through our Service, or from Your purchases on our Service.
            <br />- Indirectly from You. For example, from observing Your activity on our Service.
            <br />- Automatically from You. For example, through cookies We or our Service Providers set on Your Device
            as You navigate through our Service.
            <br />- From Service Providers. For example, third-party vendors to monitor and analyze the use of our
            Service, third-party vendors to deliver targeted advertising to You, third-party vendors for payment
            processing, or other third-party vendors that We use to provide the Service to You.
          </Text>
        </TextGroup>

        <TextGroup>
          <Text16Bold>Use of Personal Information</Text16Bold>
          <Text>
            We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as
            defined under the CCPA/CPRA), which may include the following examples:
            <br />- To operate our Service and provide You with Our Service.
            <br />- To provide You with support and to respond to Your inquiries, including to investigate and address
            Your concerns and monitor and improve our Service.
            <br />- To fulfill or meet the reason You provided the information. For example, if You share Your contact
            information to ask a question about our Service, We will use that personal information to respond to Your
            inquiry. If You provide Your personal information to purchase a product or service, We will use that
            information to process Your payment and facilitate delivery.
            <br />- To respond to law enforcement requests and as required by applicable law, court order, or
            governmental regulations.
            <br />- As described to You when collecting Your personal information or as otherwise set forth in the
            CCPA/CPRA.
            <br />- For internal administrative and auditing purposes.
            <br />- To detect security incidents and protect against malicious, deceptive, fraudulent or illegal
            activity, including, when necessary, to prosecute those responsible for such activities.
            <br />- Other one-time uses.
          </Text>
          <Text>
            Please note that the examples provided above are illustrative and not intended to be exhaustive. For more
            details on how we use this information, please refer to the "Use of Your Personal Data" section.
          </Text>
          <Text>
            If We decide to collect additional categories of personal information or use the personal information We
            collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Disclosure of Personal Information</Text16Bold>
          <Text>
            We may use or disclose and may have used or disclosed in the last twelve (12) months the following
            categories of personal information for business or commercial purposes:
            <br />- Category A: Identifiers
            <br />- Category B: Personal information categories listed in the California Customer Records statute (Cal.
            Civ. Code § 1798.80(e))
            <br />- Category D: Commercial information
            <br />- Category F: Internet or other similar network activity
          </Text>
          <Text>
            Please note that the categories listed above are those defined in the CCPA/CPRA. This does not mean that all
            examples of that category of personal information were in fact disclosed, but reflects our good faith belief
            to the best of our knowledge that some of that information from the applicable category may be and may have
            been disclosed.
          </Text>
          <Text>
            When We disclose personal information for a business purpose or a commercial purpose, We enter a contract
            that describes the purpose and requires the recipient to both keep that personal information confidential
            and not use it for any purpose except performing the contract.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Share of Personal Information</Text16Bold>
          <Text>
            We may share, and have shared in the last twelve (12) months, Your personal information identified in the
            above categories with the following categories of third parties:
            <br />- Service Providers
            <br />- Payment processors
            <br />- Our affiliates
            <br />- Our business partners
            <br />- Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in
            connection with products or services We provide to You
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Sale of Personal Information</Text16Bold>
          <Text>
            As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating,
            making available, transferring, or otherwise communicating orally, in writing, or by electronic or other
            means, a Consumer's personal information by the Business to a third party for valuable consideration. This
            means that We may have received some kind of benefit in return for sharing personal information, but not
            necessarily a monetary benefit.
          </Text>
          <Text>
            We do not sell personal information as the term sell is commonly understood. We do allow Service Providers
            to use Your personal information for the business purposes described in Our Privacy Policy, for activities
            such as advertising, marketing, and analytics, and these may be deemed a sale under CCPA/CPRA.
          </Text>
          <Text>
            We may sell and may have sold in the last twelve (12) months the following categories of personal
            information:
            <br />- Category A: Identifiers
            <br />- Category B: Personal information categories listed in the California Customer Records statute (Cal.
            Civ. Code § 1798.80(e))
            <br />- Category D: Commercial information
            <br />- Category F: Internet or other similar network activity
          </Text>
          <Text>
            Please note that the categories listed above are those defined in the CCPA/CPRA. This does not mean that all
            examples of that category of personal information were in fact sold, but reflects our good faith belief to
            the best of Our knowledge that some of that information from the applicable category may be and may have
            been shared for value in return.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Sale of Personal Information of Minors Under 16 Years of Age</Text16Bold>
          <Text>
            We do not knowingly collect personal information from minors under the age of 16 through our Service,
            although certain third party websites that we link to may do so. These third-party websites have their own
            terms of use and privacy policies and We encourage parents and legal guardians to monitor their children's
            Internet usage and instruct their children to never provide information on other websites without their
            permission.
          </Text>
          <Text>
            We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless
            We receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and
            16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to
            the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out,
            You (or Your authorized representative) may submit a request to Us by contacting Us.
          </Text>
          <Text>
            If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal
            information, please contact Us with sufficient detail to enable Us to delete that information.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Your Rights under the CCPA/CPRA</Text16Bold>
          <Text>
            The CCPA/CPRA provides California residents with specific rights regarding their personal information. If
            You are a resident of California, You have the following rights:
            <br />- The right to notice. You have the right to be notified which categories of Personal Data are being
            collected and the purposes for which the Personal Data is being used.
            <br />- The right to know/access. Under CCPA/CPRA, You have the right to request that We disclose
            information to You about Our collection, use, sale, disclosure for business purposes and share of personal
            information. Once We receive and confirm Your request, We will disclose to You:
            <br /> &emsp; - The categories of personal information We collected about You
            <br /> &emsp; - The categories of sources for the personal information We collected about You
            <br /> &emsp; - Our business or commercial purposes for collecting or selling that personal information
            <br /> &emsp; - The categories of third parties with whom We share that personal information
            <br /> &emsp; - The specific pieces of personal information We collected about You
            <br /> &emsp; - If we sold Your personal information or disclosed Your personal information for a business
            purpose, We will disclose to You:
            <br /> &emsp; &emsp; - The categories of personal information categories sold
            <br /> &emsp; &emsp; - The categories of personal information categories disclosed
            <br />- The right to say no to the sale or sharing of Personal Data (opt-out). You have the right to direct
            Us to not sell Your personal information. To submit an opt-out request, please see the "Do Not Sell My
            Personal Information" section or contact Us.
            <br />- The right to correct Personal Data. You have the right to correct or rectify any inaccurate personal
            information about You that We collected. Once We receive and confirm Your request, We will use commercially
            reasonable efforts to correct (and direct our Service Providers to correct) Your personal information,
            unless an exception applies.
            <br />- The right to limit use and disclosure of sensitive Personal Data. You have the right to request to
            limit the use or disclosure of certain sensitive personal information We collected about You, unless an
            exception applies. To submit, please see the "Limit the Use or Disclosure of My Sensitive Personal
            Information" section or contact Us.
            <br />- The right to delete Personal Data. You have the right to request the deletion of Your Personal Data
            under certain circumstances, subject to certain exceptions. Once We receive and confirm Your request, We
            will delete (and direct Our Service Providers to delete) Your personal information from our records, unless
            an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or
            Our Service Providers to:
            <br /> &emsp; - Complete the transaction for which We collected the personal information, provide a good or
            service that You requested, take actions reasonably anticipated within the context of our ongoing business
            relationship with You, or otherwise perform our contract with You.
            <br /> &emsp; - Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
            activity, or prosecute those responsible for such activities.
            <br /> &emsp; - Debug products to identify and repair errors that impair existing intended functionality.
            <br /> &emsp; - Exercise free speech, ensure the right of another consumer to exercise their free speech
            rights, or exercise another right provided for by law.
            <br /> &emsp; - Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et.
            seq.).
            <br /> &emsp; - Engage in public or peer-reviewed scientific, historical, or statistical research in the
            public interest that adheres to all other applicable ethics and privacy laws, when the information's
            deletion may likely render impossible or seriously impair the research's achievement, if You previously
            provided informed consent.
            <br /> &emsp; - Enable solely internal uses that are reasonably aligned with consumer expectations based on
            Your relationship with Us.
            <br /> &emsp; - Comply with a legal obligation.
            <br /> &emsp; - Make other internal and lawful uses of that information that are compatible with the context
            in which You provided it.
            <br />- The right not to be discriminated against. You have the right not to be discriminated against for
            exercising any of Your consumer's rights, including by:
            <br /> &emsp; - Denying goods or services to You
            <br /> &emsp; - Charging different prices or rates for goods or services, including the use of discounts or
            other benefits or imposing penalties
            <br /> &emsp; - Providing a different level or quality of goods or services to You
            <br /> &emsp; - Suggesting that You will receive a different price or rate for goods or services or a
            different level or quality of goods or services
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Exercising Your CCPA/CPRA Data Protection Rights</Text16Bold>
          <Text>
            Please see the "Do Not Sell My Personal Information" section and "Limit the Use or Disclosure of My
            Sensitive Personal Information" section for more information on how to opt out and limit the use of
            sensitive information collected.
          </Text>
          <Text>
            Additionally, in order to exercise any of Your rights under the CCPA/CPRA, and if You are a California
            resident, You can contact Us:
            <br />- By email: support@onlychats.com
          </Text>
          <Text>
            Only You, or a person registered with the California Secretary of State that You authorize to act on Your
            behalf, may make a verifiable request related to Your personal information.
          </Text>
          <Text>
            Your request to Us must:
            <br />- Provide sufficient information that allows Us to reasonably verify You are the person about whom We
            collected personal information or an authorized representative
            <br />- Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and
            respond to it
          </Text>
          <Text>
            We cannot respond to Your request or provide You with the required information if We cannot:
            <br />- Verify Your identity or authority to make the request
            <br />- And confirm that the personal information relates to You
          </Text>
          <Text>
            We will disclose and deliver the required information free of charge within 45 days of receiving Your
            verifiable request. The time period to provide the required information may be extended once by an
            additional 45 days when reasonably necessary and with prior notice.
          </Text>
          <Text>
            Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.
          </Text>
          <Text>
            For data portability requests, We will select a format to provide Your personal information that is readily
            usable and should allow You to transmit the information from one entity to another entity without hindrance.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Do Not Sell My Personal Information</Text16Bold>
          <Text>
            As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating,
            making available, transferring, or otherwise communicating orally, in writing, or by electronic or other
            means, a Consumer's personal information by the Business to a third party for valuable consideration. This
            means that We may have received some kind of benefit in return for sharing personal information, but not
            necessarily a monetary benefit.
          </Text>
          <Text>
            We do not sell personal information as the term sell is commonly understood. We do allow Service Providers
            to use Your personal information for the business purposes described in Our Privacy Policy, for activities
            such as advertising, marketing, and analytics, and these may be deemed a sale under CCPA/CPRA.
          </Text>
          <Text>
            You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
            verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right
            to opt-out, please contact Us.
          </Text>
          <Text>
            The Service Providers we partner with (for example, our analytics or advertising partners) may use
            technology on the Service that sells personal information as defined by the CCPA/CPRA law. If you wish to
            opt out of the use of Your personal information for interest-based advertising purposes and these potential
            sales as defined under CCPA/CPRA law, you may do so by following the instructions below.
          </Text>
          <Text>
            Please note that any opt out is specific to the browser You use. You may need to opt out on every browser
            that You use.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Website</Text16Bold>
          <Text>
            If applicable, click "Privacy Preferences", "Update Privacy Preferences" or "Do Not Sell My Personal
            Information" buttons listed on the Service to review your privacy preferences and opt out of cookies and
            other technologies that We may use. Please note that You will need to opt out from each browser that You use
            to access the Service.
          </Text>
          <Text>
            Additionally, You can opt out of receiving ads that are personalized as served by our Service Providers by
            following our instructions presented on the Service:
            <br />- The NAI's opt-out platform: http://www.networkadvertising.org/choices/
            <br />- The EDAA's opt-out platform http://www.youronlinechoices.com/
            <br />- The DAA's opt-out platform: http://optout.aboutads.info/?c=2&lang=EN
          </Text>
          <Text>
            The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you
            change browsers or delete the cookies saved by your browser, You will need to opt out again.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Mobile Devices</Text16Bold>
          <Text>
            Your mobile device may give You the ability to opt out of the use of information about the apps You use in
            order to serve You ads that are targeted to Your interests:
            <br />- "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices
            <br />- "Limit Ad Tracking" on iOS devices
          </Text>
          <Text>
            You can also stop the collection of location information from Your mobile device by changing the preferences
            on Your mobile device.
          </Text>
        </TextGroup>
        <TextGroup>
          <Text16Bold>Limit the Use or Disclosure of My Sensitive Personal Information</Text16Bold>
          <Text>
            If You are a California resident, You have the right to limit the use and disclosure of Your sensitive
            personal information to that use which is necessary to perform the services or provide the goods reasonably
            expected by an average Consumer who requests such services or goods.
          </Text>
          <Text>
            We collect, use and disclose sensitive personal information in ways that are necessary to provide the
            Service. For more information on how We use Your personal information, please see the "Use of Your Personal
            Data" section or contact us.
          </Text>
        </TextGroup>
        <Text20Bold>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</Text20Bold>
        <TextGroup>
          <Text>Our Service does not respond to Do Not Track signals.</Text>
          <Text>
            However, some third party websites do keep track of Your browsing activities. If You are visiting such
            websites, You can set Your preferences in Your web browser to inform websites that You do not want to be
            tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
          </Text>
        </TextGroup>
        <Text20Bold>Your California Privacy Rights (California's Shine the Light law)</Text20Bold>
        <TextGroup>
          <Text>
            Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an
            established business relationship with us can request information once a year about sharing their Personal
            Data with third parties for the third parties' direct marketing purposes.
          </Text>
          <Text>
            If you'd like to request more information under the California Shine the Light law, and if You are a
            California resident, You can contact Us using the contact information provided below.
          </Text>
        </TextGroup>
        <Text20Bold>
          California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)
        </Text20Bold>
        <TextGroup>
          <Text>
            California Business and Professions Code Section 22581 allows California residents under the age of 18 who
            are registered users of online sites, services or applications to request and obtain removal of content or
            information they have publicly posted.
          </Text>
          <Text>
            To request removal of such data, and if You are a California resident, You can contact Us using the contact
            information provided below, and include the email address associated with Your account.
          </Text>
          <Text>
            Be aware that Your request does not guarantee complete or comprehensive removal of content or information
            posted online and that the law may not permit or require removal in certain circumstances.
          </Text>
        </TextGroup>
        <Text20Bold>Children's Privacy</Text20Bold>
        <TextGroup>
          <Text>
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
            child has provided Us with Personal Data, please contact Us. If We become aware that We have collected
            Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.
          </Text>
          <Text>
            If We need to rely on consent as a legal basis for processing Your information and Your country requires
            consent from a parent, We may require Your parent's consent before We collect and use that information.
          </Text>
        </TextGroup>
        <Text20Bold>Links to Other Websites</Text20Bold>
        <TextGroup>
          <Text>
            Our Service may contain links to other websites that are not operated by Us. If You click on a third party
            link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy
            of every site You visit.
          </Text>
          <Text>
            We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.
          </Text>
        </TextGroup>
        <Text20Bold>Changes to this Privacy Policy</Text20Bold>
        <TextGroup>
          <Text>
            We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
            Privacy Policy on this page.
          </Text>
          <Text>
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
            effective and update the "Last updated" date at the top of this Privacy Policy.
          </Text>
          <Text>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.
          </Text>
        </TextGroup>
        <Text20Bold>Contact Us</Text20Bold>
        <TextGroup>
          <Text>
            If you have any questions about this Privacy Policy, You can contact us:
            <br />- By email: support@onlychats.com
          </Text>
        </TextGroup>
      </Content>
    </PageContainer>
  );
};

export default PrivacyPolicyPage;
