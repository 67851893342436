import React from 'react';
import LogoComponent from '../LogoComponent/LogoComponent';
import PremiumButton from '../PremiumButton/PremiumButton';
import { BackContainer, Header, LogoWrapper } from './PublicHeader.styles';
import { Text20Bold } from '../utils/Text/Text.styles';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron_left.svg';
import { useMediaQuery } from 'react-responsive';

const PublicHeader = ({ backText, onBack }) => {
  const isNotebook = useMediaQuery({ minWidth: 768 });

  return (
    <Header>
      {!isNotebook && (
        <LogoWrapper>
          <LogoComponent fullLogo id="homepage1" />
        </LogoWrapper>
      )}
      {onBack ? (
        <BackContainer onClick={onBack}>
          <ChevronLeft />
          <Text20Bold>{backText}</Text20Bold>
        </BackContainer>
      ) : (
        <div />
      )}
      <PremiumButton />
    </Header>
  );
};

export default PublicHeader;
