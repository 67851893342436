import styled from 'styled-components';
import { theme } from '../../../styles/constants';
import { Text12Light, Text14Light } from '../../utils/Text/Text.styles';

export const ModalStyle = styled.div`
  position: relative;
  outline: none;
  border-radius: 24px;
  background: radial-gradient(78.01% 78.01% at 49.87% 0%, #1f0f3c 0%, #0a0416 100%);
  max-height: 90%;
  height: 100%;
  width: 60rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    max-height: unset;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;

    background: #000;
  }
`;

export const OverlayStyle = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: rgba(0, 0, 0, 0.42);
  backdrop-filter: blur(5px);
  opacity: 0;
  transition: all 300ms ease-in-out;

  &[class*='--after-open'] {
    opacity: 1;
  }

  &[class*='--before-close'] {
    opacity: 0;
  }
`;

export const Header = styled.div`
  height: 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 2rem;
  padding-top: 1.6rem;
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: -0.8rem;
`;

export const Content = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 24px;
  position: relative;
`;

export const SubscriptionPlanContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #ced2d6;
  display: flex;
  padding: 0.8rem 1.6rem;
  justify-content: space-between;
  align-items: center;
  height: 5.2rem;

  p {
    color: #000;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-end;
`;

export const VATPrice = styled(Text12Light)`
  color: #bea1ff;
  opacity: 0.78;
`;

export const PaymentInfo = styled.div`
  margin-top: 1.6rem;
  position: relative;

  .smgl-MainContainer__container {
    font-family: inherit;
    font-weight: 300;
    color: #000;

    .smgl-PaymentStatus__title {
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }
    .smgl-PaymentInfo__detailsRow {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }

  .smgl-Field__note {
    display: none;
  }

  section {
    max-width: unset;
    height: 100%;

    .smgl-Button__container {
      height: 4.8rem;
      border-radius: 14px;
      border: 1px solid #4d2595;

      background: linear-gradient(92deg, #a486ff 0.26%, #5b16c4 99.74%);
      background: linear-gradient(
        92deg,
        color(display-p3 0.6235 0.5294 0.9922) 0.26%,
        color(display-p3 0.3255 0.1059 0.7373) 99.74%
      );
      box-shadow: 0px 8px 12px 0px rgba(132, 62, 254, 0.4);
      box-shadow: 0px 8px 12px 0px color(display-p3 0.4824 0.2588 0.9608 / 0.4);
      font-weight: 400;
      width: 30rem;
      margin-inline: auto;
      align-self: flex-end;
    }

    .smgl-PaymentForm__row {
      height: 9.9rem;
      margin-bottom: 0;
      .smgl-Field__label {
        font-weight: 300;
        font-size: 1.4rem;
        margin-bottom: 0.6rem;
        color: #000;
      }
    }
  }

  span {
    padding: 0.6rem 0;
    height: 5.4rem;
    border-radius: 16px;
  }

  label {
    font-size: 1.4rem;
  }

  iframe {
    height: 100%;

    input {
      height: 5.2rem;
    }
  }

  .smgl-Field__error {
    color: ${theme.colors.error};
    font-size: 1.3rem;
    min-height: 2.1rem;
    letter-spacing: 0.5px;
    margin-left: 0.2rem;
    margin-top: 0.8rem;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 46.3rem;
  left: 0;
  right: 0;
  height: 6rem;
  width: 30rem;
  margin-inline: auto;
  cursor: not-allowed;
`;

export const CheckboxContainer = styled.div`
  padding: 0 2rem;
  position: absolute;
  top: 40.1rem;
  left: 0;
  right: 0;
  height: 2rem;
  display: flex;
  gap: 2rem;
  p {
    color: #000;
  }
`;

export const CheckboxWrapper = styled.div`
  cursor: pointer;
`;

export const NoteText = styled(Text12Light)`
  color: #000;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 54.3rem;
  width: 30rem;
  margin-inline: auto;
  color: #8e39ff;
  font-size: 1.1rem;
`;

export const WeAcceptContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
`;

export const WeAcceptText = styled(Text14Light)`
  color: #040209;
  opacity: 0.5;
`;

export const CompanyText = styled.p`
  font-size: 0.8rem;
  font-weight: 300;
  color: #040209;
  opacity: 0.5;
  position: absolute;
  top: 56.3rem;
  left: 0;
  right: 0;
  text-align: center;
`;
