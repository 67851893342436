import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  cancelSubscriptionBuilder,
  createPaymentSessionBuilder,
  getPaymentSessionBuilder,
  purchaseCreditsBuilder,
} from './builder';

const INIT_STATE = {
  processingPayment: false,
  // ATTEMPTS
  createPaymentSessionPending: false,
  purchaseCreditsPending: false,
  cancelSubscriptionPending: false,
  getPaymentSessionPending: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: INIT_STATE,
  reducers: {
    setProcessingPayment: (state, action) => {
      state.processingPayment = action.payload;
    },
  },
  extraReducers: builder => {
    createPaymentSessionBuilder(builder);
    purchaseCreditsBuilder(builder);
    cancelSubscriptionBuilder(builder);
    getPaymentSessionBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { setProcessingPayment } = paymentSlice.actions;

// ATTEMPTS
export const selectCreatePaymentSessionPending = state => state.paymentInfo.createPaymentSessionPending;
export const selectPurchaseCreditsPending = state => state.paymentInfo.purchaseCreditsPending;
export const selectCancelSubscriptionPending = state => state.paymentInfo.cancelSubscriptionPending;
export const selectProcessingPayment = state => state.paymentInfo.processingPayment;
export const selectGetPaymentSessionPending = state => state.paymentInfo.getPaymentSessionPending;

export default paymentSlice.reducer;
