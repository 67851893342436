import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 1.6rem;
  padding-bottom: 8rem;
  padding-inline: 1rem;

  @media only screen and (min-width: 768px) {
    padding: 1.6rem;
    align-self: center;
  }
`;
