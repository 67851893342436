import styled from 'styled-components';
import { Text24Bold } from '../../../components/utils/Text/Text.styles';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.linearLeft} 0%,
    ${({ theme }) => theme.colors.linearRight} 100%
  ); */
`;

export const Logo = styled.img`
  height: 10rem;
`;

export const Title = styled(Text24Bold)`
  margin-top: 1.6rem;
`;
