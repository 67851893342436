import styled from 'styled-components';
import { Text16Regular } from '../Text/Text.styles';
import { Transition } from '../../../styles/constants';

export const SingleValueText = styled.span`
  color: #fff;
  font-size: 1.5rem;
`;

export const SingleValueTextLight = styled(SingleValueText)`
  opacity: 0.6;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(Text16Regular)`
  margin-bottom: 0.6rem;
`;

export const selectStyles = {
  control: baseStyles => ({
    ...baseStyles,
    background: `linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36`,
    minHeight: '5.4rem',
    borderRadius: '16px',
    fontSize: '1.5rem',
    boxShadow: 'none',
    outline: 'none',
    borderColor: '#2c1655',
    fontWeight: '300',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#2c1655',
    },
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#b3a2cf',
      fontSize: '1.5rem',
      fontWeight: '300',
    };
  },
  input: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#fff',
    };
  },

  menu: baseStyles => ({
    ...baseStyles,
    background: `linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36`,
    borderRadius: '8px',
    border: '1px solid',
    boxShadow: 'none',
    overflow: 'auto',
    borderColor: '#2c1655',
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color1: '#fff',
  }),
  option: baseStyles => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    gap: '1rem',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#adaae160',
      transition: Transition.hover.on,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: '#fff',
  }),
};
