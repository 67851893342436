import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { CloseIconWrapper, Content, Header, ModalContent } from './AuthModalContent.styles';
import StepIndicator from '../StepIndicator/StepIndicator';
import SignUpStep from '../SignUpStep/SignUpStep';
import VerificationCodeStep from '../VerificationCodeStep/VerificationCodeStep';
import PasswordStep from '../PasswordStep/PasswordStep';
import BasicInfoStep from '../BasicInfoStep/BasicInfoStep';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../../utils/constants';

export const AUTH_MODAL_STEPS = {
  SIGN_UP: 'SIGN_UP',
  VERIFICATION_CODE: 'VERIFICATION_CODE',
  PASSWORD: 'PASSWORD',
  BASIC_INFO: 'BASIC_INFO',
};

const AuthModalContent = ({ onClose, modalStep, onSuccess }) => {
  const [authStep, setAuthStep] = useState(AUTH_MODAL_STEPS.SIGN_UP);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (modalStep) {
      setAuthStep(modalStep);
    }
  }, [modalStep]);

  const activeStep = useMemo(() => {
    switch (authStep) {
      case AUTH_MODAL_STEPS.SIGN_UP:
        return 0;
      case AUTH_MODAL_STEPS.VERIFICATION_CODE:
        return 0;
      case AUTH_MODAL_STEPS.PASSWORD:
        return 1;
      case AUTH_MODAL_STEPS.BASIC_INFO:
        return 2;
      default:
        break;
    }
  }, [authStep]);

  const renderContent = () => {
    switch (authStep) {
      case AUTH_MODAL_STEPS.SIGN_UP:
        return <SignUpStep setAuthStep={setAuthStep} onClose={onClose} recaptchaRef={recaptchaRef} />;
      case AUTH_MODAL_STEPS.VERIFICATION_CODE:
        return (
          <VerificationCodeStep
            setAuthStep={setAuthStep}
            onClose={onClose}
            onSuccess={onSuccess}
            recaptchaRef={recaptchaRef}
          />
        );
      case AUTH_MODAL_STEPS.PASSWORD:
        return (
          <PasswordStep setAuthStep={setAuthStep} onClose={onClose} onSuccess={onSuccess} recaptchaRef={recaptchaRef} />
        );
      case AUTH_MODAL_STEPS.BASIC_INFO:
        return <BasicInfoStep setAuthStep={setAuthStep} onClose={onClose} onSuccess={onSuccess} />;
      default:
        break;
    }
  };

  return (
    <ModalContent>
      <Header>
        <StepIndicator numOfSteps={3} activeStep={activeStep} />
        {authStep !== AUTH_MODAL_STEPS.BASIC_INFO && (
          <CloseIconWrapper onClick={onClose}>
            <CloseIcon />
          </CloseIconWrapper>
        )}
      </Header>
      <Content>{renderContent()}</Content>
      <ReCAPTCHA ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_SITE_KEY} size="invisible" theme="dark" />
    </ModalContent>
  );
};

export default AuthModalContent;
