import React from 'react';
import ReactModal from 'react-modal';
import { ModalStyle, OverlayStyle } from './Modal.styles';

const Modal = ({ isOpen, children, onClose, shouldCloseOnOverlayClick = true }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={300}
      className="_"
      overlayClassName="_"
      contentElement={props => <ModalStyle {...props}>{children}</ModalStyle>}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    />
  );
};

export default Modal;
