import React, { useCallback, useRef } from 'react';
import {
  AppearanceTags,
  Footer,
  HomePageSkeleton,
  InfluencerCard,
  PersonalityTags,
  PublicHeader,
  TryPremiumCard,
} from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { APPEARANCE, PERSONALITY } from '../../components/App/routes';
import {
  AllModelsSection,
  Content,
  Grid,
  PageContainer,
  PersonalityTagsContainer,
  TagsContainer,
  TitleContainer,
} from './HomePage.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFetchLandingInfluencersInfo,
  selectGetInfluencersLandingPending,
} from '../../store/slices/influencer/slice';
import { getInfluencersLanding } from '../../store/slices/influencer/asyncThunk';
import { Text18Bold } from '../../components/utils/Text/Text.styles';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as TrendingIcon } from '../../assets/icons/PersonalityTags/trending.svg';

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { data, page, maxReached } = useSelector(selectFetchLandingInfluencersInfo);
  const getInfluencersLandingPending = useSelector(selectGetInfluencersLandingPending);
  const isLoggedIn = useSelector(selectLoggedIn);
  const observer = useRef();
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 550 });
  const { pathname } = useLocation();

  const onPersonalityTagClick = value => {
    navigate(`/${PERSONALITY.replace(':personalityName', value)}`);
  };
  const onAppearanceTagClick = value => {
    navigate(`/${APPEARANCE.replace(':appearanceName', value)}`);
  };

  const lastBookElementRef = useCallback(
    node => {
      if (getInfluencersLandingPending) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !maxReached) {
          dispatch(getInfluencersLanding({ page }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [getInfluencersLandingPending, maxReached, dispatch, page],
  );

  return (
    <PageContainer>
      <PublicHeader />
      {(getInfluencersLandingPending && page === 1) || isLoggedIn === null ? (
        <Content>
          <HomePageSkeleton />
        </Content>
      ) : (
        <Content>
          <PersonalityTagsContainer>
            <PersonalityTags onTagClick={onPersonalityTagClick} />
          </PersonalityTagsContainer>
          <AllModelsSection>
            <TitleContainer>
              <Text18Bold>Explore</Text18Bold>
              <TrendingIcon width={20} height={20} />
            </TitleContainer>
            <Grid>
              {data.slice(0, 8).map((influencer, index) => {
                return (
                  <InfluencerCard
                    key={index}
                    id={influencer.id}
                    name={influencer.name}
                    isVerified={influencer.isVerified}
                    username={influencer.username}
                    aboutMe={influencer.aboutMe}
                    age={influencer.age}
                    aspectRatio={isMobile ? 0.48 : 3 / 4}
                    image={influencer.image}
                    chatButton={!isMobile}
                    category={influencer.category}
                    callOptions={influencer.callOptions}
                    from={pathname}
                  />
                );
              })}
            </Grid>
            <TryPremiumCard />
            {data.length > 8 && (
              <Grid>
                {data.slice(8, 12).map((influencer, index) => {
                  return (
                    <InfluencerCard
                      key={index}
                      id={influencer.id}
                      name={influencer.name}
                      isVerified={influencer.isVerified}
                      username={influencer.username}
                      aboutMe={influencer.aboutMe}
                      age={influencer.age}
                      aspectRatio={isMobile ? 0.48 : 3 / 4}
                      image={influencer.image}
                      chatButton={!isMobile}
                      category={influencer.category}
                      callOptions={influencer.callOptions}
                      from={pathname}
                    />
                  );
                })}
              </Grid>
            )}
            <TagsContainer>
              <AppearanceTags onTagClick={onAppearanceTagClick} />
            </TagsContainer>
            {data.length > 12 && (
              <Grid>
                {data.slice(12, data.length).map((influencer, index) => {
                  return (
                    <InfluencerCard
                      key={index}
                      id={influencer.id}
                      name={influencer.name}
                      isVerified={influencer.isVerified}
                      username={influencer.username}
                      aboutMe={influencer.aboutMe}
                      age={influencer.age}
                      aspectRatio={isMobile ? 0.48 : 3 / 4}
                      image={influencer.image}
                      chatButton={!isMobile}
                      category={influencer.category}
                      innerRef={index === data.slice(12, data.length).length - 1 ? lastBookElementRef : null}
                      callOptions={influencer.callOptions}
                      from={pathname}
                    />
                  );
                })}
              </Grid>
            )}
          </AllModelsSection>
        </Content>
      )}
      <Footer />
    </PageContainer>
  );
};

export default HomePage;
