import React, { useEffect, useState } from 'react';
import {
  Container,
  Divider,
  Form,
  GoogleSignUpButton,
  NoteText,
  PrivacyProtectedContainer,
  PrivacyProtectedText,
  TextItalic,
  Title,
} from './SignUpStep.styles';
import Input from '../../utils/inputs/Input/Input';
import { Button } from '../../utils/Button/Button';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google.svg';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/shield.svg';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError } from '../../../utils/notify';
import { checkEmailExistence, googleSignUp, requestVerificationCode } from '../../../store/slices/auth/asyncThunk';
import { selectGoogleSignUpPending, setInputEmail } from '../../../store/slices/auth/slice';
import { AUTH_MODAL_STEPS } from '../AuthModalContent/AuthModalContent';
import { useGoogleLogin } from '@react-oauth/google';
import Spinner from '../../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../App/routes';
import { useCookies } from 'react-cookie';
import { COOKIE_NAME, REFERRED_BY_TYPE } from '../../../utils/constants';

const signUpFormSchema = yup
  .object({
    email: yup.string().trim().required('Email is required').email('Invalid email'),
  })
  .required();

const SignUpStep = ({ setAuthStep, onClose, recaptchaRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleSignUpPending = useSelector(selectGoogleSignUpPending);
  const [loading, setLoading] = useState(false);
  const [cookies, , removeCookie] = useCookies([COOKIE_NAME.REFERRED_BY_AFFILIATE, COOKIE_NAME.REFERRED_BY_INFLUENCER]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    delayError: 300,
    resolver: yupResolver(signUpFormSchema),
  });

  useEffect(() => {
    const captchaBackgroundClickHandler = () => {
      setLoading(false);
    };

    let captchaBackground = null;
    const domObserver = new MutationObserver(() => {
      // THIS COULD BE CHANGED BY GOOGLE
      const iframe = document.querySelector('iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]');

      if (iframe) {
        domObserver.disconnect();

        captchaBackground = iframe.parentNode?.parentNode?.firstChild;
        captchaBackground?.addEventListener('click', captchaBackgroundClickHandler);
      }
    });

    domObserver.observe(document.documentElement || document.body, { childList: true, subtree: true });

    return () => {
      domObserver.disconnect();
      captchaBackground && captchaBackground.removeEventListener('click', captchaBackgroundClickHandler);
    };
    // eslint-disable-next-line
  }, []);

  const onSubmit = async data => {
    const { email } = data;
    setLoading(true);
    dispatch(setInputEmail({ email }));
    dispatch(checkEmailExistence({ email }))
      .unwrap()
      .then(async ({ userExists }) => {
        if (userExists) {
          setLoading(false);
          setAuthStep(AUTH_MODAL_STEPS.PASSWORD);
        } else {
          let recaptchaValue = null;
          try {
            recaptchaValue = await recaptchaRef.current.executeAsync();
          } catch (error) {
            setLoading(false);
            notifyError('Recaptcha verification failed');
            return;
          }
          dispatch(requestVerificationCode({ email, token: recaptchaValue }))
            .unwrap()
            .then(() => {
              setLoading(false);
              setAuthStep(AUTH_MODAL_STEPS.VERIFICATION_CODE);
            })
            .catch(err => {
              setLoading(false);
              recaptchaRef.current.reset();
              notifyError(err.message);
            });
        }
      })
      .catch(err => {
        setLoading(false);
        dispatch(setInputEmail({ email: null }));
        notifyError(err.message);
      });
  };

  const onGoogleSingUpClick = useGoogleLogin({
    onSuccess: ({ code }) => {
      const referredByAffiliate = cookies[COOKIE_NAME.REFERRED_BY_AFFILIATE];
      const referredByInfluencer = cookies[COOKIE_NAME.REFERRED_BY_INFLUENCER];
      let referredBy = null;
      if (referredByInfluencer) {
        referredBy = {
          type: REFERRED_BY_TYPE.INFLUENCER,
          source: { influencerUsername: referredByInfluencer.influencerUsername },
        };
      } else if (referredByAffiliate) {
        referredBy = {
          type: REFERRED_BY_TYPE.AFFILIATE,
          source: { clickId: referredByAffiliate.clickId, affiliateId: referredByAffiliate.affiliateId },
        };
      }

      dispatch(googleSignUp({ code, ...(referredBy && { referredBy }) }))
        .unwrap()
        .then(() => {
          setLoading(false);
          removeCookie(COOKIE_NAME.REFERRED_BY_AFFILIATE);
          removeCookie(COOKIE_NAME.REFERRED_BY_INFLUENCER);
          onClose();
        })
        .catch(err => {
          setLoading(false);
          notifyError(err.message);
        });
    },
    flow: 'auth-code',
  });

  const onTermsClick = () => {
    navigate(`/${TERMS_AND_CONDITIONS}`);
    onClose();
  };
  const onPrivacyPolicyClick = () => {
    navigate(`/${PRIVACY_POLICY}`);
    onClose();
  };

  return (
    <Container>
      <Title>Sign-up to start chatting!</Title>
      <TextItalic>FREE Forever.</TextItalic>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Enter your email address"
          {...register('email')}
          type="email"
          error={errors.email?.message}
        />
        <Button title="Continue" isLoading={loading} />
      </Form>
      <Divider />
      <GoogleSignUpButton disabled={googleSignUpPending} onClick={() => onGoogleSingUpClick()}>
        {googleSignUpPending ? (
          <Spinner width={48} height={48} />
        ) : (
          <>
            <GoogleIcon />
            <span>Continue with Google</span>
          </>
        )}
      </GoogleSignUpButton>
      <NoteText>
        By using this service you agree to the <span onClick={onTermsClick}>Terms</span> and{' '}
        <span onClick={onPrivacyPolicyClick}>Privacy Policy</span>, and confirm that you are at least 18 years old.
      </NoteText>
      <PrivacyProtectedContainer>
        <ShieldIcon />
        <PrivacyProtectedText>Privacy Protected</PrivacyProtectedText>
      </PrivacyProtectedContainer>
    </Container>
  );
};

export default SignUpStep;
