import { createGlobalStyle } from 'styled-components';
import { theme } from '../styles/constants';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; // 1rem = 10px, 10/16 = 60.25%
    box-sizing: border-box;
    height: 100%;
    background: #000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  body {
    font-weight: 400;
    font-size: 1.7rem;
    font-family: Lexend, sans-serif;
    margin: 0;
    color: ${theme.colors.white};
    background: #05030b;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  a,
  p,
  svg,
  button,
  div {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  #root {
    flex-grow: 1;
    display: flex;
    background: #05030b;
  }

  p {
    user-select: none;
  }

  .success-toast-message {
    background-color: #26134e;
    color: #fff;
    border-radius: 1.2rem;
  }

  .success-toast-message-progress {
    background-color: #843efe;
  }
`;

export default GlobalStyles;
