import React from 'react';
import {
  AllModelsSection,
  Container,
  Grid,
  PersonalityTagsContainer,
  TagsContainer,
  TitleContainer,
  TrendingCard,
} from './HomePageSkeleton.styles';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
import { ReactComponent as TrendingIcon } from '../../../assets/icons/PersonalityTags/trending.svg';
import { Text18Bold } from '../../utils/Text/Text.styles';
import PersonalityTags from '../../PersonalityTags/PersonalityTags';
import AppearanceTags from '../../AppearanceTags/AppearanceTags';
import TryPremiumCard from '../../home/TryPremiumCard/TryPremiumCard';

const HomePageSkeleton = () => {
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 480 });
  // const [cardWidth, setCardWidth] = useState(0);

  // const setRef = node => {
  //   if (node) {
  //     const width = node.clientWidth;
  //     setCardWidth(width);
  //   }
  // };

  return (
    <Container>
      <PersonalityTagsContainer>
        <PersonalityTags />
      </PersonalityTagsContainer>
      {/* <FeaturedNewbiesSection>
        <AllModelsTitleContainer>
          <Text18Bold>Trending</Text18Bold>
          <TrendingIcon width={20} height={20} />
        </AllModelsTitleContainer>
        <FeaturedNewbiesContent>
          {Array.from({ length: 10 }).map((_, index) => (
            <FeaturedNewbiesCard $animationTime={8} key={index} $cardWidth={cardWidth}>
              <Number>
                <Text14Bold>{index + 1}</Text14Bold>
              </Number>
              <LogoIcon />
            </FeaturedNewbiesCard>
          ))}
        </FeaturedNewbiesContent>
      </FeaturedNewbiesSection> */}
      <AllModelsSection>
        <TitleContainer>
          <Text18Bold>Explore</Text18Bold>
          <TrendingIcon width={20} height={20} />
        </TitleContainer>
        <Grid>
          {Array.from({ length: 8 }).map((_, index) => (
            <TrendingCard $animationTime={6} key={index} $aspectRatio={isMobile ? 0.48 : 3 / 4}>
              <LogoIcon />
            </TrendingCard>
          ))}
        </Grid>
        <TryPremiumCard />
        <Grid>
          {Array.from({ length: 4 }).map((_, index) => (
            <TrendingCard $animationTime={6} key={index} $aspectRatio={isMobile ? 0.48 : 3 / 4}>
              <LogoIcon />
            </TrendingCard>
          ))}
        </Grid>
        <TagsContainer>
          <AppearanceTags />
        </TagsContainer>
        <Grid>
          {Array.from({ length: 8 }).map((_, index) => (
            <TrendingCard $animationTime={6} key={index} $aspectRatio={isMobile ? 0.48 : 3 / 4}>
              <LogoIcon />
            </TrendingCard>
          ))}
        </Grid>
      </AllModelsSection>
    </Container>
  );
};

export default HomePageSkeleton;
