import React from 'react';
import StarsImage from '../../assets/images/stars.png';
import { Button, ButtonContainer, Image, PremiumImage, Text } from './PremiumPhoto.styles';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { selectUser } from '../../store/slices/user/slice';
import { useSubscriptionModal } from '../modals/SubscriptionModal/SubscriptionModal';
import { useAuthModal } from '../modals/AuthModal/AuthModal';
import { useTelegramModal } from '../modals/TelegramModal/TelegramModal';
import { isSubscribed } from '../../utils/util';

const PremiumPhoto = () => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const [openModal] = useSubscriptionModal();
  const [openAuthModal] = useAuthModal();
  const [openTelegramModal] = useTelegramModal();

  const onPremiumPhotoClick = () => {
    if (!isLoggedIn) {
      openAuthModal(null, () => {});
      return true;
    }
    if (isSubscribed(user?.subscribedUntil)) {
      openTelegramModal();
      return true;
    } else {
      openModal();
    }
  };
  return (
    <PremiumImage onClick={onPremiumPhotoClick}>
      <Image src={StarsImage} />
      <ButtonContainer>
        <Button title="Remove Blur" />
        <Text>Exclusive Photo</Text>
      </ButtonContainer>
    </PremiumImage>
  );
};

export default PremiumPhoto;
