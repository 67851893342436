import styled from 'styled-components';
import { Skeleton } from '../Skeleton.styles';

export const SkeletonItemContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
`;
export const AvatarSkeleton = styled(Skeleton)`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
`;

export const ContentSkeleton = styled(Skeleton)`
  height: 6.4rem;
  border-radius: 50%;
  border-radius: 16px;
  flex-grow: 1;
`;
