import styled from 'styled-components';
import { Text18Bold } from '../../utils/Text/Text.styles';

export const FeaturedNewbiesSection = styled.div``;

export const TitleContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;

  div {
    width: 4rem;
    height: 4rem;
  }
`;

export const FeaturedNewbiesTitle = styled(Text18Bold)``;

export const FeaturedNewbiesContent = styled.div`
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
