import React, { useState } from 'react';
import {
  FooterButton,
  LogoWrapper,
  NavLinkIconWrapper,
  NavLinkText,
  NavigationContainer,
  NavigationFooter,
  NavigationItem,
  NavigationItems,
  NavigationWrapper,
  StyledPremiumButton,
} from './Navigation.styles';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as HeartsIcon } from '../../assets/icons/hearts.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as MessageHeartIcon } from '../../assets/icons/message-heart-circle.svg';
import { ReactComponent as MessagePlusIcon } from '../../assets/icons/message-plus-circle.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CHAT, EXPLORE, HOME, PROFILE } from '../App/routes';
import { useAuthModal } from '../modals/AuthModal/AuthModal';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { useSelector } from 'react-redux';
import LogoComponent from '../LogoComponent/LogoComponent';
import { Text14Light } from '../utils/Text/Text.styles';
import { useSubscriptionModal } from '../modals/SubscriptionModal/SubscriptionModal';
import { useTelegramModal } from '../modals/TelegramModal/TelegramModal';
import { isSubscribed } from '../../utils/util';
import { selectUser } from '../../store/slices/user/slice';
import AgeAlertModal from '../modals/AgeAlertModal/AgeAlertModal';

const Navigation = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const [openAuthModal] = useAuthModal();
  const [openModal] = useSubscriptionModal();
  const [openTelegramModal] = useTelegramModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const handleOnClick = e => {
    if (!loggedIn) {
      openAuthModal(null, () => {
        navigate(`/${PROFILE}`);
      });

      e.preventDefault();
    }
  };

  const onBecomePremiumClick = () => {
    if (!loggedIn) {
      openAuthModal(null, () => {});
      return true;
    }
    if (isSubscribed(user.subscribedUntil)) {
      openTelegramModal();
      return true;
    } else {
      openModal();
      return true;
    }
  };

  const onSupportClick = () => {
    setShowModal(true);
  };

  const hiddenPaths = ['/chat/', '/creator/', '/checkout'];
  const navigationHidden = hiddenPaths.some(path => location.pathname.includes(path));

  return (
    <NavigationWrapper $hidden={navigationHidden}>
      <NavigationContainer>
        <NavigationItems>
          <LogoWrapper>
            <LogoComponent fullLogo id="navigation" />
          </LogoWrapper>
          <NavigationItem>
            <NavLink to={HOME}>
              <NavLinkIconWrapper>
                <HomeIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Home</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={EXPLORE}>
              <NavLinkIconWrapper>
                <HeartsIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Spotlight</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={CHAT}>
              <NavLinkIconWrapper>
                <MessageIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Chats</NavLinkText>
            </NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to={PROFILE} onClick={handleOnClick}>
              <NavLinkIconWrapper>
                <UserIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Profile</NavLinkText>
            </NavLink>
          </NavigationItem>
          <StyledPremiumButton title="Become Premium" onClick={onBecomePremiumClick} />
        </NavigationItems>
      </NavigationContainer>
      <NavigationFooter>
        <FooterButton onClick={onSupportClick}>
          <MessageHeartIcon />
          <Text14Light>Creators</Text14Light>
        </FooterButton>
        <FooterButton onClick={onSupportClick}>
          <MessagePlusIcon />
          <Text14Light>Support</Text14Light>
        </FooterButton>
      </NavigationFooter>
      <AgeAlertModal show={showModal} setShow={setShowModal} />
    </NavigationWrapper>
  );
};

export default Navigation;
