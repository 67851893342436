import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/influencers';

class InfluencerService {
  getInfluencersLanding = async page => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/w/landing?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencers } = response.data.data;
      return { influencers, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers');
    }
  };

  getInfluencers = async ({ personalityTags, appearanceTags }) => {
    try {
      const body = {
        personalityTags,
        appearanceTags,
      };
      const response = await axiosApiClient.post(`${BASE_URL}/w/category`, body);
      throwErrorIfWrongStatus(response, 200);
      const { influencers } = response.data.data;
      return { influencers };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers');
    }
  };

  getInfluencerByUsername = async ({ influencerUsername }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/w/${influencerUsername}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return { influencer };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer');
    }
  };
}

export default new InfluencerService();
