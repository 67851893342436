import styled from 'styled-components';
import {
  Text12Light,
  Text14Light,
  Text14Regular,
  Text16Bold,
  Text16Regular,
  Text18ExtraBold,
} from '../../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ $hasHeader }) => (!$hasHeader ? 'calc(100% - 9.8rem)' : '100%')};
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 23.5rem;
  opacity: 0.8;
  background: radial-gradient(40.05% 100% at 50% 0%, rgb(52, 21, 105) 0%, rgb(0 0 0 / 0%) 100%);
  z-index: 0;
`;

export const Photo = styled.img`
  border-radius: 20px;
  border: 1px solid #b485ff;
  width: ${({ width }) => `${width / 10}rem`};
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.25);
`;

export const SubscriptionPlans = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  margin: 1rem 0 2rem 0;
  z-index: 1;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const HeaderText = styled(Text12Light)`
  span {
    color: #7e58e1;
    font-weight: 600;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  margin-inline: auto;
  width: 100%;
  padding-top: 1rem;
  z-index: 30;

  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const NoteContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;

  p {
    opacity: 1;
    color: #8066bd;
  }
`;

export const DescriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
`;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 20;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const YourCurrentPlan = styled.div`
  border-radius: 8px;
  border: 1px solid #a27fe0;
  background: #170e28;
  box-shadow: 0px 0px 8px 0px #6e539e inset;
  padding: 0.4rem 1.2rem;
  margin-inline: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
  align-items: center;
  margin-top: 1.2rem;
  position: relative;
  padding-bottom: 1rem;

  svg {
    cursor: pointer;
  }

  @media only screen and (min-width: 600px) {
    margin-top: 0rem;
  }
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  svg {
    g {
      path {
        stroke: #8f71ee;
      }
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 0.2rem;
`;

export const DescriptionTitle = styled(Text16Regular)`
  @media only screen and (max-width: 380px) {
    font-size: 1.3rem;
  }
`;

export const AdditionalTextContainer = styled.div`
  border-radius: 12px;
  background: #1a0e30;
  padding: 0.5rem 0.4rem;
  display: flex;
  align-items: center;

  p {
    color: #b391ff;
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.67;
    line-height: 0.7rem;
  }

  @media only screen and (max-width: 380px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

export const DescriptionCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1.6rem;
  border-radius: 16px;
  border: 1px solid #23133d;
  background: linear-gradient(272deg, #0f071c 0.2%, #0f071c 99.8%);
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: 0.444px solid #aa85ff;
  background: linear-gradient(180deg, #8854ff 0%, #51329c 100%);
  box-shadow: 0px 1.111px 4.444px 0px rgba(132, 62, 254, 0.1);
`;

export const SubscriptionDescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.2rem;
`;

export const DescriptionText = styled(Text14Light)`
  line-height: 1.9rem;
  letter-spacing: -0.42px;
  opacity: 0.7;
  color: #ddd4ff;

  @media only screen and (max-width: 380px) {
    line-height: 1.6rem;
    font-size: 1.1rem;
  }
`;

export const SubscriptionPlanCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 1.2rem;
  padding: 1rem 1.6rem;
  border-radius: 16px;
  border: 1px solid ${({ $selected }) => ($selected ? '#9D7CFF' : 'rgba(157, 124, 255, 0.20)')};
  background: ${({ $selected }) =>
    $selected ? 'radial-gradient(40.93% 120% at 0% 0%, #3F2773 0%, #0F071C 100%)' : '#0F071C'};
  cursor: pointer;
  box-shadow: ${({ $selected }) =>
    $selected &&
    `0px 2px 5px 0px #703ed029, 0px 12px 5px 0px rgba(112, 62, 208, 0.03), 0px 6px 4px 0px rgba(112, 62, 208, 0.10), 0px 3px 3px 0px rgba(112, 62, 208, 0.17), 0px 1px 2px 0px rgba(112, 62, 208, 0.20)`};
`;

export const SubscriptionPlanCardLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const SubscriptionPlanCardRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const PeriodText = styled(Text14Light)`
  color: #9d7cff;
  opacity: 0.9;
`;

export const PriceText = styled(Text16Bold)`
  letter-spacing: -0.4px;
`;

export const OldPriceText = styled(Text12Light)`
  color: #9d7cff;
  opacity: 0.7;
  font-weight: 200;
  line-height: 1.5rem;
  letter-spacing: -0.4px;
  text-decoration: line-through;
`;

export const PeriodContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const PeriodAmountText = styled(Text16Bold)`
  line-height: 2rem;
  letter-spacing: -0.4px;
`;

export const DiscountPercentage = styled.div`
  border-radius: 8px;
  background: #8556e9;
  padding: 0.4rem 0.5rem;

  p {
    letter-spacing: -0.4px;
    line-height: 0.7rem;
  }
`;

export const WhatIncludedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1.6rem;
  z-index: 1;
`;

export const WhatIncludedTitle = styled(Text18ExtraBold)`
  line-height: 2.4rem;
  letter-spacing: -0.4px;
`;

export const WhatIncludedContent = styled.div`
  display: flex;
  gap: 0.9rem;
`;

export const ImageContainer = styled.div`
  flex: 1;
  border-radius: 20px;
  box-shadow: 0px 142px 40px 0px color(display-p3 0.4941 0.298 0.8902 / 0),
    0px 91px 36px 0px color(display-p3 0.4941 0.298 0.8902 / 0.01),
    0px 51px 31px 0px color(display-p3 0.4941 0.298 0.8902 / 0.05),
    0px 23px 23px 0px color(display-p3 0.4941 0.298 0.8902 / 0.09),
    0px 6px 13px 0px color(display-p3 0.4941 0.298 0.8902 / 0.1);

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
  justify-content: center;
  z-index: 1;
`;

export const StarsContainer = styled.div`
  display: flex;
  gap: 0.1rem;
`;

export const RateText = styled(Text14Regular)`
  letter-spacing: -0.42px;
  color: #ffd563;
`;

export const RateDescriptionText = styled(Text14Light)`
  color: #ddd4ff;
  letter-spacing: -0.42px;
  opacity: 0.7;
  font-size: 1.2rem;
`;

export const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: center;
  margin-bottom: 3rem;
  z-index: 1;
`;

export const PrivacyText = styled(Text12Light)`
  color: #4cff7c;
  text-align: center;
`;
