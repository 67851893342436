import React, { useState } from 'react';
import {
  AdditionalTextContainer,
  ButtonContainer,
  CloseIconWrapper,
  Container,
  DescriptionCardContainer,
  DescriptionsContainer,
  DescriptionText,
  DescriptionTitle,
  DiscountPercentage,
  Header,
  HeaderText,
  HeaderTextContainer,
  IconWrapper,
  ImageContainer,
  NoteContainer,
  OldPriceText,
  PeriodAmountText,
  PeriodContainer,
  PeriodText,
  PriceText,
  PrivacyContainer,
  PrivacyText,
  RateContainer,
  RateDescriptionText,
  RateText,
  ScrollableContainer,
  Shadow,
  StarsContainer,
  SubscriptionDescriptionText,
  SubscriptionPlanCard,
  SubscriptionPlanCardLeftSide,
  SubscriptionPlanCardRightSide,
  SubscriptionPlans,
  TextContainer,
  WhatIncludedContainer,
  WhatIncludedContent,
  WhatIncludedTitle,
  YourCurrentPlan,
} from './SubscriptionContent.styles';
import { ReactComponent as VideoIcon } from '../../../assets/icons/video.svg';
import { ReactComponent as DiamondIcon } from '../../../assets/icons/diamond.svg';
import { ReactComponent as MessageChatIcon } from '../../../assets/icons/message-text-circle-02.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image-02.svg';
import { ReactComponent as ReceiptIcon } from '../../../assets/icons/receipt_check.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram-03.svg';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import { ReactComponent as MicrophoneIcon } from '../../../assets/icons/microphone-02.svg';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/shield.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { ReactComponent as Star1Icon } from '../../../assets/icons/star-01.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check01.svg';
import { Text10ExtraBold, Text10Regular, Text14Light, Text24ExtraBold } from '../../utils/Text/Text.styles';
import { Button } from '../../utils/Button/Button';
import { useNavigate } from 'react-router-dom';
import { CHECKOUT } from '../../App/routes';
import {
  SUBSCRIPTION_CREDITS,
  SUBSCRIPTION_LEVEL,
  SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PRICES_PER_MONTH,
} from '../../../utils/constants';
import { getPriceForSelectedPlan, isSubscribed } from '../../../utils/util';
import { selectUser } from '../../../store/slices/user/slice';
import { useSelector } from 'react-redux';

const SubscriptionContent = ({ onClose }) => {
  const navigate = useNavigate();
  const { activeSubscription, subscribedUntil } = useSelector(selectUser);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(SUBSCRIPTION_PERIOD.MONTHLY);

  const SUBSCRIPTION_DESCRIPTIONS = [
    {
      icon: <ImageIcon color="#fff" />,
      title: 'Exclusive NSFW pictures',
      text: 'Gain access to a private gallery of sizzling hot photos that will set your pulse racing.',
    },
    {
      icon: <DiamondIcon color="#fff" width={18} height={18} />,
      title: `${SUBSCRIPTION_CREDITS.PREMIUM.VALUE} Credits / month`,
      additionalTitleText: `+${SUBSCRIPTION_CREDITS.PREMIUM.BONUS} BONUS CREDITS`,
      text: `Get ${SUBSCRIPTION_CREDITS.PREMIUM.VALUE} + ${SUBSCRIPTION_CREDITS.PREMIUM.BONUS} bonus credits each month to spend on premium features.`,
    },
    {
      icon: <VideoIcon color="#fff" />,
      title: 'Live Video Calls',
      text: "Get real-time facetime with your crush and make it feel like you're right there together.",
    },
    {
      icon: <PhoneIcon color="#fff" />,
      title: 'Live Audio Calls',
      text: 'Hear their voice, get butterflies whispering sweet nothings just for you.',
    },
    {
      icon: <TelegramIcon color="#fff" />,
      title: 'VIP Telegram App Access',
      text: 'Join the inner circle and get hooked up with exclusive perks and priority support.',
    },

    {
      icon: <MicrophoneIcon width={18} height={18} color="#fff" />,
      title: 'Unlimited Voice Messages / day',
      text: 'Get unlimited sultry voice notes from her, sent straight to your ears.',
    },

    {
      icon: <MessageChatIcon color="#fff" />,
      title: 'Unlimited Text Messages / day',
      text: 'Chat all day, every day - no cap on the messages or conversations.',
    },
    {
      icon: <HeartIcon color="#fff" />,
      title: 'Send Pictures & Get Rated',
      text: 'Share your most intimate moments and see how they measure up to her high standards.',
    },
    {
      icon: <ReceiptIcon color="#fff" />,
      title: 'Cancel Anytime',
      text: 'Cancel anytime if you are not completely satisfied with our service.',
    },
  ];

  const SUBSCRIPTION_PLANS = [
    {
      discount: 50,
      title: '1 month',
      period: SUBSCRIPTION_PERIOD.MONTHLY,
      price: SUBSCRIPTION_PRICES_PER_MONTH.PREMIUM.MONTHLY,
    },
    {
      discount: 70,
      title: '3 months',
      period: SUBSCRIPTION_PERIOD['3_MONTHS'],
      price: SUBSCRIPTION_PRICES_PER_MONTH.PREMIUM['3_MONTHS'],
    },
    {
      discount: 80,
      title: '12 months',
      period: SUBSCRIPTION_PERIOD.ANNUALLY,
      price: SUBSCRIPTION_PRICES_PER_MONTH.PREMIUM.ANNUALLY,
    },
  ];

  const onSubscribeClick = () => {
    navigate(`../${CHECKOUT}`, { state: { subscriptionPeriod, subscriptionPlan: SUBSCRIPTION_PLAN.PREMIUM } });
    onClose();
  };

  const onPlanClick = period => {
    setSubscriptionPeriod(period);
  };

  return (
    <Container $hasHeader={!isSubscribed(subscribedUntil)}>
      {!isSubscribed(subscribedUntil) && <Shadow />}
      <ScrollableContainer>
        {!isSubscribed(subscribedUntil) && (
          <>
            <Header>
              <HeaderTextContainer>
                <Text24ExtraBold>Limited Time Offer</Text24ExtraBold>
                <HeaderText>
                  Up to <span>80%</span> off your subscription
                </HeaderText>
              </HeaderTextContainer>
              <CloseIconWrapper>
                <CloseIcon onClick={onClose} />
              </CloseIconWrapper>
            </Header>
          </>
        )}

        <SubscriptionPlans>
          {SUBSCRIPTION_PLANS.map(item => {
            return (
              <SubscriptionPlanCard
                key={item.period}
                $selected={subscriptionPeriod === item.period}
                onClick={() => onPlanClick(item.period)}>
                <SubscriptionPlanCardLeftSide>
                  <PeriodContainer>
                    <PeriodAmountText>{item.title}</PeriodAmountText>
                    <DiscountPercentage>
                      <Text10ExtraBold>{`${item.discount}% OFF`}</Text10ExtraBold>
                    </DiscountPercentage>
                  </PeriodContainer>
                  <OldPriceText>was $29.99 / month</OldPriceText>
                </SubscriptionPlanCardLeftSide>
                <SubscriptionPlanCardRightSide>
                  <PriceText>${item.price}</PriceText>
                  <PeriodText>/month</PeriodText>
                </SubscriptionPlanCardRightSide>
              </SubscriptionPlanCard>
            );
          })}
        </SubscriptionPlans>
        <WhatIncludedContainer>
          <WhatIncludedTitle>What’s Included</WhatIncludedTitle>
          <WhatIncludedContent>
            <ImageContainer>
              <img
                src="https://www.onlychats.com/cdn-cgi/imagedelivery/JbcvhHWGK90wHykvJ8zUXw/6ccc4402-1da5-442e-35b8-4ca25e52b700/public"
                alt="video calls"
              />
            </ImageContainer>
            <ImageContainer>
              <img
                src="https://www.onlychats.com/cdn-cgi/imagedelivery/JbcvhHWGK90wHykvJ8zUXw/0ae935e2-a7d5-4d3c-a9a3-d4d847109b00/public"
                alt="audio calls"
              />
            </ImageContainer>
          </WhatIncludedContent>
        </WhatIncludedContainer>
        <DescriptionsContainer>
          {SUBSCRIPTION_DESCRIPTIONS.map((item, index) => (
            <DescriptionCardContainer key={index}>
              <IconWrapper>{item.icon}</IconWrapper>
              <SubscriptionDescriptionText>
                <TextContainer>
                  <DescriptionTitle>{item.title}</DescriptionTitle>
                  {item.additionalTitleText && (
                    <AdditionalTextContainer>
                      <Text10Regular>{item.additionalTitleText}</Text10Regular>
                    </AdditionalTextContainer>
                  )}
                </TextContainer>
                <DescriptionText>{item.text}</DescriptionText>
              </SubscriptionDescriptionText>
            </DescriptionCardContainer>
          ))}
        </DescriptionsContainer>
        <RateContainer>
          <StarsContainer>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <Star1Icon />
          </StarsContainer>
          <RateText>4.8</RateText>
          <RateDescriptionText>Rated by 47.2K+ Members</RateDescriptionText>
        </RateContainer>
        <PrivacyContainer>
          <ShieldIcon />
          <PrivacyText>100% Privacy.</PrivacyText>
          <PrivacyText>Your data is protected with industry-leading encryption.</PrivacyText>
        </PrivacyContainer>
      </ScrollableContainer>
      <ButtonContainer>
        {!isSubscribed(subscribedUntil) ? (
          <>
            <Button
              title={`Subscribe for $${getPriceForSelectedPlan(subscriptionPeriod, SUBSCRIPTION_PLAN.PREMIUM)}`}
              onClick={onSubscribeClick}
            />
            <NoteContainer>
              <CheckIcon />
              <RateDescriptionText>No adult transaction in your bank statement.</RateDescriptionText>
            </NoteContainer>
          </>
        ) : SUBSCRIPTION_LEVEL[subscriptionPeriod] > SUBSCRIPTION_LEVEL[activeSubscription.period] ? (
          <>
            <Button
              title={`Upgrade for $${getPriceForSelectedPlan(subscriptionPeriod, SUBSCRIPTION_PLAN.PREMIUM)}`}
              onClick={onSubscribeClick}
            />
            <NoteContainer>
              <CheckIcon />
              <RateDescriptionText>No adult transaction in your bank statement.</RateDescriptionText>
            </NoteContainer>
          </>
        ) : (
          activeSubscription.period === subscriptionPeriod && (
            <YourCurrentPlan>
              <Text14Light>Your current plan</Text14Light>
            </YourCurrentPlan>
          )
        )}
      </ButtonContainer>
    </Container>
  );
};

export default SubscriptionContent;
