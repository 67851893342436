import React from 'react';
import {
  AvatarImage,
  Background,
  CardInformation,
  CardText,
  Container,
  ContentContainer,
  DiamondContainer,
  LeftContent,
  MobileContainer,
  PremiumButtonContainer,
  RightContent,
  Text,
  TextContainer,
  Title,
} from './TryPremiumCard.styles';
import { ReactComponent as PromoIcon } from '../../../assets/icons/promo1.svg';
import { ReactComponent as DiamondIcon } from '../../../assets/icons/diamond.svg';
import { ReactComponent as PremiumIcon } from '../../../assets/icons/premium.svg';
import { ReactComponent as BackgroundIcon } from '../../../assets/icons/stars-background.svg';
import PremiumButton from '../../PremiumButton/PremiumButton';
import LogoComponent from '../../LogoComponent/LogoComponent';
import { useMediaQuery } from 'react-responsive';
import WomenImage from '../../../assets/images/anthia.png';
import { Text16Bold } from '../../utils/Text/Text.styles';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../../store/slices/auth/slice';
import { selectUser } from '../../../store/slices/user/slice';
import { useSubscriptionModal } from '../../modals/SubscriptionModal/SubscriptionModal';
import { useAuthModal } from '../../modals/AuthModal/AuthModal';
import { useTelegramModal } from '../../modals/TelegramModal/TelegramModal';
import { isSubscribed } from '../../../utils/util';

const TryPremiumCard = () => {
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 550 });
  const isLoggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const [openModal] = useSubscriptionModal();
  const [openAuthModal] = useAuthModal();
  const [openTelegramModal] = useTelegramModal();

  const onButtonClick = () => {
    if (!isLoggedIn) {
      openAuthModal(null, () => {});
      return true;
    }
    if (isSubscribed(user.subscribedUntil)) {
      openTelegramModal();
      return true;
    } else {
      openModal();
      return true;
    }
  };

  return isMobile ? (
    <MobileContainer>
      <Title>TRY PREMIUM</Title>
      <DiamondContainer>
        <DiamondIcon />
      </DiamondContainer>
      <ContentContainer onClick={onButtonClick}>
        <AvatarImage src={WomenImage} />
        <TextContainer>
          <Text16Bold>Live Calls & Pictures</Text16Bold>
          <Text>Ready for the best adventure of your life?</Text>
        </TextContainer>
      </ContentContainer>
    </MobileContainer>
  ) : (
    <Container>
      <Background>
        <BackgroundIcon />
      </Background>
      <LeftContent>
        <PromoIcon />
      </LeftContent>
      <RightContent>
        <CardInformation>
          <PremiumButtonContainer>
            <PremiumIcon />
          </PremiumButtonContainer>
          <LogoComponent fullLogo />
          <CardText>
            Subscribe to OnlyChats Premium to access exclusive content, including photos, phone and video calls, and
            much more!
          </CardText>
          <PremiumButton />
        </CardInformation>
      </RightContent>
    </Container>
  );
};

export default TryPremiumCard;
