import styled from 'styled-components';
import {
  Text12Light,
  Text13Light,
  Text13Regular,
  Text14Light,
  Text14Regular,
} from '../../components/utils/Text/Text.styles';

export const PageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: 10rem;
  position: relative;

  @media only screen and (min-width: 768px) {
    max-width: 80rem;
    width: 100%;
    margin-inline: auto;
  }
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 19.2rem;
  background: radial-gradient(40.05% 100% at 50% 0%, #341569 0%, #07030e 100%);
  background: radial-gradient(
    40.05% 100% at 50% 0%,
    color(display-p3 0.1882 0.0902 0.3961) 0%,
    color(display-p3 0.0235 0.0118 0.051) 100%
  );
`;

export const Header = styled.div`
  height: 5.8rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.6rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
    height: 5rem;
    z-index: 1;
  }
`;

export const BackIcon = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 3.8rem;
    height: 3.8rem;
  }
`;

export const BasicInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.8rem;
  padding-right: 1.6rem;
  gap: 1.6rem;
  z-index: 1;

  @media only screen and (max-width: 1050px) {
    margin-inline: 1.6rem;
  }

  @media only screen and (max-width: 768px) {
    gap: 0.8rem;

    margin-inline: 1.5rem;
    margin-top: 0.4rem;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  margin: 0.4rem 0;
`;

export const OnlineContainer = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;

export const Circle = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background-color: #00da00;
`;

export const OnlineText = styled(Text13Regular)`
  letter-spacing: -0.408px;
  color: #00da00;
`;

export const AvatarImageWrapper = styled.div`
  width: 14.2rem;
  height: 14.2rem;
  border-radius: 50%;

  @media only screen and (max-width: 768px) {
    width: 7.2rem;
    height: 7.2rem;
  }
`;

export const AvatarImage = styled.img`
  width: 14.2rem;
  height: 14.2rem;
  border-radius: 50%;

  @media only screen and (max-width: 768px) {
    width: 7.2rem;
    height: 7.2rem;
  }
`;

export const SecretModes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    margin-top: 2.2rem;
  }

  @media only screen and (max-width: 1050px) {
    margin-inline: 1.6rem;
  }
`;

export const SectionTitle = styled(Text12Light)`
  color: #cfc3e9;
`;

export const SecretModeItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
`;

export const SecretModeButton = styled.button`
  border-radius: 10px;
  border: ${({ $selected }) => ($selected ? '1px solid #7438DF;' : '1px solid #2c1655')};
  background: ${({ $selected }) =>
    $selected
      ? 'linear-gradient(0deg, #2B1754 0%, #2B1754 100%), #5E3D99;'
      : 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36'};
  padding: 0.5rem 1.4rem 0 1.2rem;
  font-size: 1.4rem;
  font-weight: 300;
  outline: none;
  color: #fff;
  cursor: pointer;
  letter-spacing: -0.408px;
  height: 3rem;
  display: flex;
  justify-content: center;
  line-height: 1.8rem;
`;

export const AboutMeTextMobile = styled(Text13Light)`
  line-height: 1.7rem;
  z-index: 1;
  margin: 1.2rem 1.6rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const ActionButtonWrapper = styled.div`
  height: 4.8rem;
  padding: 0.1rem;
  background: linear-gradient(180deg, #4d2595 0%, #aa75ff 100%);
  border-radius: 16px;
  max-width: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;

  @media only screen and (max-width: 500px) {
    max-width: unset;
  }
`;

export const ActionButton = styled.button`
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;
  width: 100%;
  outline: none;
  border: none;
`;

export const ChatButton = styled(ActionButton)`
  background: linear-gradient(180deg, #ab75ff 0%, #4d2595 100%);
  padding: 1.2rem 1rem 1.2rem 0.6rem;
  height: 100%;
`;

export const CallButton = styled(ActionButton)`
  border: 1px solid #4d2595;
  background: linear-gradient(180deg, #341865 0%, #180f2a 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.23);
  flex: 1;
  max-width: 12rem;
  padding: 1.2rem 1rem 1.2rem 0.4rem;
  height: 4.8rem;

  @media only screen and (max-width: 500px) {
    max-width: unset;
  }
`;

export const CreditsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.6rem;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  gap: 0.5rem;
`;

export const BalanceText = styled(Text14Regular)`
  letter-spacing: -0.42px;
`;

export const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  div {
    width: 20rem;
    height: 20rem;
  }
`;

export const AboutMeText = styled(Text14Light)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 1.2rem;
  line-height: 2.6rem;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.8rem;
  margin-inline: 1.6rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
  margin-top: 2rem;

  @media only screen and (max-width: 1050px) {
    margin-inline: 1.6rem;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.1rem;
    margin-inline: 0;
  }
`;

export const CoverImage = styled.img`
  width: 100%;
  aspect-ratio: 0.75;
  object-fit: cover;
  border-radius: 16px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;
