import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/chats';

class ChatService {
  sendChatMessage = async ({ userInput, influencerUsername, initialMessageSecretModeIds, secretModeId }) => {
    try {
      const body = { userInput, influencerUsername, secretModeId, initialMessageSecretModeIds };
      const response = await axiosApiClient.post(`${BASE_URL}/w/message`, body);
      throwErrorIfWrongStatus(response, 200);
      const { messages, userInfluencer } = response.data.data;
      return { messages, userInfluencer };
    } catch (err) {
      throw handleError(err, 'Error occurred while sending message');
    }
  };

  getUserInfluencerChat = async ({ influencerId, page }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/w/influencer/${influencerId}/chat?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { userInfluencer } = response.data.data;
      return { userInfluencer, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching user influencer chat');
    }
  };
}

export default new ChatService();
