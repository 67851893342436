import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 144rem;
  display: flex;
  margin-inline: auto;
  flex-grow: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 18.8rem);
  margin-left: 18.8rem;
  border-right: 1px solid #1d1229;

  @media only screen and (max-width: 1440px) {
    border-right: none;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: unset;
  }
`;
