import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import {
  ButtonContainer,
  CheckboxContainer,
  CheckboxWrapper,
  Content,
  Header,
  IconWrapper,
  ModalStyle,
  NoteText,
  OverlayStyle,
  PaymentInfo,
  PriceContainer,
  SubscriptionPlanContainer,
  VATPrice,
  WeAcceptContainer,
  WeAcceptText,
  CompanyText,
} from './PaymentInfoModal.styles';
import { Text14Regular, Text15Regular, Text16Regular, Text20Bold } from '../../utils/Text/Text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CheckEmptyIcon } from '../../../assets/icons/check-empty.svg';
import { ReactComponent as VisaIcon } from '../../../assets/icons/visa.svg';
import { ReactComponent as MastercardIcon } from '../../../assets/icons/mastercard.svg';
import { ReactComponent as AmericanExpressIcon } from '../../../assets/icons/american-express.svg';
import { roundNumber } from '../../../utils/util';

const PAYMENT_CONTAINER_ID = 'smgl-payment-form';

const PaymentInfoModal = ({
  show,
  setShow,
  subscriptionPlan,
  subscriptionPeriod,
  price,
  priceWithVat,
  vat,
  publicToken,
  amount,
}) => {
  const [recurrentPayment, setRecurrentPayment] = useState(true);
  const [shouldPaymentRender, setShouldPaymentRender] = useState(false);

  const setRef = node => {
    if (node) {
      if (!shouldPaymentRender) {
        setShouldPaymentRender(true);
      }
    }
  };

  useEffect(() => {
    if (shouldPaymentRender && publicToken) {
      const paymentForm = new window.SmglPaymentForm(publicToken, {
        texts: {
          paymentForm: {
            buttonPayLabel: amount
              ? `Top up for $${priceWithVat}`
              : `Subscribe for $${priceWithVat} / ${subscriptionPeriod.replace('_', ' ').toLowerCase()}`,
          },
        },
      });
      paymentForm.render();
    }
    // eslint-disable-next-line
  }, [shouldPaymentRender, publicToken]);

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => {
        setShow(false);
      }}
      onAfterClose={() => {
        setShouldPaymentRender(false);
      }}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Header>
            <Text20Bold>Payment Information</Text20Bold>
            <IconWrapper onClick={() => setShow(false)}>
              <CloseIcon />
            </IconWrapper>
          </Header>
          <Content>
            <WeAcceptContainer>
              <WeAcceptText>We Accept</WeAcceptText>
              <VisaIcon />
              <MastercardIcon />
              <AmericanExpressIcon />
            </WeAcceptContainer>
            <SubscriptionPlanContainer>
              <Text15Regular>{amount ? 'Top up' : subscriptionPlan}</Text15Regular>
              <PriceContainer>
                <Text16Regular>
                  {amount ? `$${amount}` : `$${price} / ${subscriptionPeriod.replace('_', ' ').toLowerCase()}`}
                </Text16Regular>
                {!!vat && <VATPrice>{`+ VAT $${roundNumber(priceWithVat - (amount ? amount : price))} `}</VATPrice>}
              </PriceContainer>
            </SubscriptionPlanContainer>
            <PaymentInfo ref={setRef} id={PAYMENT_CONTAINER_ID}></PaymentInfo>
            {!amount && (
              <CheckboxContainer>
                <CheckboxWrapper
                  onClick={() => {
                    setRecurrentPayment(!recurrentPayment);
                  }}>
                  {recurrentPayment ? <CheckIcon /> : <CheckEmptyIcon />}
                </CheckboxWrapper>
                <Text14Regular>
                  I agree to subscribe to the Premium monthly subscription, which can be canceled at any time.
                </Text14Regular>
              </CheckboxContainer>
            )}
            {!recurrentPayment && <ButtonContainer />}
            <NoteText>Your statement will show your payment as SmartGlocal.</NoteText>
            <CompanyText>OCAI LTD, Themistokli Dervi 6, Nicosia, Cyprus</CompanyText>
          </Content>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default PaymentInfoModal;
