import styled from 'styled-components';
import { Text15Regular, Text24Bold } from '../utils/Text/Text.styles';

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.8rem;
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;

  svg {
    @media only screen and (max-width: 355px) {
      margin-top: 0.4rem;
      max-width: 9.8rem;
    }
  }
`;

export const TelegramText = styled(Text24Bold)`
  color: #2bacef;

  @media only screen and (max-width: 355px) {
    font-size: 2rem;
  }
`;

export const TitleText = styled(Text24Bold)`
  @media only screen and (max-width: 355px) {
    font-size: 2rem;
  }
`;

export const PlatformContainer = styled.div`
  display: flex;
  margin: 4rem 0;
  justify-content: center;

  svg {
    g {
      cursor: pointer;
    }
  }
`;

export const Line = styled.div`
  opacity: 0.56;
  background: linear-gradient(90deg, #030108 0%, #a168ff 47.5%, #030108 100%);
  width: 100%;
  height: 1px;
`;

export const Text = styled(Text15Regular)`
  color: #c4a9ff;
  text-align: center;
  margin-top: 3.3rem;
  margin-bottom: 1.6rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: center;
`;

export const ActionButton = styled.div`
  border-radius: 16px;
  border: 1px solid #432181;
  background: linear-gradient(90deg, #1d0f37 0%, #231047 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 1.2rem 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;
  width: 17.8rem;
`;

export const CopyButton = styled(ActionButton)`
  width: unset;
`;

export const SvgContainer = styled.div`
  svg {
    max-width: 100%;
    height: 90%;
  }
`;
