import styled from 'styled-components';
import {
  Text12Bold,
  Text12Light,
  Text14Light,
  Text15Regular,
  Text16Regular,
  Text20Bold,
} from '../../utils/Text/Text.styles';
import { StyledButton } from '../SubscriptionModalContent/SubscriptionModalContent.styles';
import Input from '../../utils/inputs/Input/Input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 9.8rem);
  z-index: 1;
`;

export const CreditsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

export const CreditsText = styled(Text12Bold)`
  color: #8e59ea;
  opacity: 0.5;
`;

export const AmountCredits = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  justify-content: center;
`;

export const AmountButton = styled(StyledButton)`
  flex: 0 0 32%;
`;

export const Line = styled.div`
  opacity: 0.56;
  background: linear-gradient(90deg, #030108 0%, #a168ff 47.5%, #030108 100%);
  height: 1px;
  width: 100%;
`;

export const AddAmountButton = styled(Text14Light)`
  color: #c4a4fa;
  margin: 1.2rem 0;
  margin-inline: auto;
`;

export const StyledInput = styled(Input)`
  height: 4.3rem;
  margin-bottom: 2.1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 0.8rem;
  gap: 0.8rem;
  flex-grow: 1;
`;

export const ProcessingPaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const ProcessingPaymentText = styled(Text20Bold)`
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 4.4rem;
`;

export const RedirectingText = styled(Text15Regular)`
  color: #c4a9ff;
`;

export const NoteText = styled(Text12Light)`
  color: #c3a4fa;
  opacity: 0.6;
  margin-top: 1rem;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const ErrorText = styled(Text16Regular)`
  text-align: center;
`;

export const PaymentInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Text = styled(Text12Light)`
  color: #c4a4fa;
  margin-bottom: 0.2rem;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const DescriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  margin-top: 2.1rem;
  padding-bottom: 1.6rem;
`;

export const IconWrapper = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  border-radius: 50%;
  border: 0.444px solid var(--Purple-400, #aa85ff);
  background: linear-gradient(180deg, #8854ff 0%, #51329c 100%);
  box-shadow: 0px 1.111px 4.444px 0px rgba(132, 62, 254, 0.1);
`;

export const NoteContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const PaymentNoteText = styled(Text12Light)`
  color: #8066bd;
  letter-spacing: -0.42px;
`;
