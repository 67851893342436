import { axiosApiFreeClient } from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

class AuthService {
  login = async body => {
    try {
      const response = await axiosApiFreeClient.post('/w/login', body, {
        withCredentials: true,
      });
      throwErrorIfWrongStatus(response, 200);
      const { accessToken, user } = response.data.data;
      return { accessToken, user };
    } catch (err) {
      throw handleError(err, 'Error occurred while logging in');
    }
  };

  loginWithOneTimeToken = async token => {
    try {
      const response = await axiosApiFreeClient.post(
        '/w/login-with-token',
        { token },
        {
          withCredentials: true,
        },
      );
      throwErrorIfWrongStatus(response, 200);
      const { accessToken, user } = response.data.data;
      return { accessToken, user };
    } catch (err) {
      throw handleError(err, 'Error occurred while logging in with one time token');
    }
  };

  checkEmailExistence = async ({ email }) => {
    try {
      const body = {
        email,
      };
      const response = await axiosApiFreeClient.post(`/w/check-email`, body);
      throwErrorIfWrongStatus(response, 200);
      const { userExists } = response.data.data;
      return { userExists };
    } catch (err) {
      throw handleError(err, 'Error occurred while checking email existence');
    }
  };

  verifyEmail = async ({ code, email, referredBy }) => {
    try {
      const body = {
        code,
        email,
        ...(referredBy && { referredBy }),
      };
      const response = await axiosApiFreeClient.post('/w/verify-email', body, {
        withCredentials: true,
      });
      throwErrorIfWrongStatus(response, 200);
      const { accessToken, user, isNewUser } = response.data.data;
      return { accessToken, user, isNewUser };
    } catch (err) {
      throw handleError(err, 'Error occurred while verifying email');
    }
  };

  requestVerificationCode = async ({ email, token }) => {
    try {
      const body = {
        email,
        token,
      };
      const response = await axiosApiFreeClient.post('/w/request-verification-code', body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while requesting verification code');
    }
  };

  googleSignUp = async ({ code, referredBy }) => {
    try {
      const body = { code, ...(referredBy && { referredBy }) };
      const response = await axiosApiFreeClient.post('/w/google/sign-up', body, {
        withCredentials: true,
      });
      throwErrorIfWrongStatus(response, 200);
      const { user, accessToken, googleName, isNewUser } = response.data.data;
      return { user, accessToken, googleName, isNewUser };
    } catch (err) {
      throw handleError(err, 'Error occurred while signing in with google');
    }
  };

  logout = async () => {
    try {
      const response = await axiosApiFreeClient.post(
        '/w/logout',
        {},
        {
          withCredentials: true,
        },
      );
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while logging out');
    }
  };
}

export default new AuthService();
