import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/slice';
import userSlice from './slices/user/slice';
import paymentSlice from './slices/payment/slice';
import influencerSlice from './slices/influencer/slice';
import chatSlice from './slices/chat/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    paymentInfo: paymentSlice,
    influencerInfo: influencerSlice,
    chatInfo: chatSlice,
  },
  devTools: false,
});
