import React from 'react';
import { ReactComponent as LogoIcon } from '../../../assets/icons/skeleton.svg';
import { StyledSkeleton } from './PersonalitiesPageSkeleton.styles';
import { useMediaQuery } from 'react-responsive';

const PersonalitiesPageSkeleton = () => {
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 480 });

  return Array.from({ length: 12 }).map((_, index) => {
    return (
      <StyledSkeleton key={index} $animationTime={6} $aspectRatio={isMobile ? 0.48 : 3 / 4}>
        <LogoIcon />
      </StyledSkeleton>
    );
  });
};

export default PersonalitiesPageSkeleton;
