import React from "react";
import { StepItem, StepsContainer } from "./StepIndicator.styles";

const StepIndicator = ({ numOfSteps, activeStep }) => {
  return (
    <StepsContainer>
      {Array(numOfSteps)
        .fill(0)
        .map((_, index) => {
          return (
            <StepItem
              key={index}
              $active={activeStep === index}
              $highlighted={index <= activeStep}
            />
          );
        })}
    </StepsContainer>
  );
};

export default StepIndicator;
