import React from 'react';
import ReactModal from 'react-modal';
import { Content, Header, ModalStyle, OverlayStyle, Text } from './MessageLimitExceededModal.styles';
import { Text22Bold } from '../../utils/Text/Text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Button } from '../../utils/Button/Button';
import { DAILY_LIMIT_EXCEEDED_ERROR_MESSAGE } from '../../../utils/constants';

const MessageLimitExceededModal = ({ setShow, show, onSubscribeClick }) => {
  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => setShow(false)}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Content>
            <Header>
              <Text22Bold>Daily Limit Reached</Text22Bold>
              <CloseIcon onClick={() => setShow(false)} />
            </Header>
            <Text>{DAILY_LIMIT_EXCEEDED_ERROR_MESSAGE}</Text>
            <Button title="Subscribe" onClick={onSubscribeClick} />
          </Content>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default MessageLimitExceededModal;
