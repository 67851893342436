import React, { useEffect, useMemo, useState } from 'react';
import {
  AddAmountButton,
  AmountButton,
  AmountCredits,
  ButtonContainer,
  Container,
  CreditsContainer,
  CreditsText,
  DescriptionContainer,
  DescriptionsContainer,
  ErrorContainer,
  ErrorText,
  Header,
  IconWrapper,
  Line,
  NoteContainer,
  NoteText,
  PaymentNoteText,
  ProcessingPaymentContainer,
  ProcessingPaymentText,
  RedirectingText,
  StyledInput,
  Text,
} from './TopUpContent.styles';
import { Text14Regular, Text15Light, Text20Bold, Text44ExtraBold } from '../../utils/Text/Text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/x-circle.svg';
import { Button } from '../../utils/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentSession, purchaseCredits } from '../../../store/slices/payment/asyncThunk';
import { CREDITS_FOR_ONE_USD, PAYMENT_SESSION_STATUS } from '../../../utils/constants';
import { notifyError, notifyInfo } from '../../../utils/notify';
import {
  selectProcessingPayment,
  selectPurchaseCreditsPending,
  setProcessingPayment,
} from '../../../store/slices/payment/slice';
import Spinner from '../../Spinner/Spinner';
import DotsLoader from '../../../assets/images/dots_loader.gif';
import { roundNumber } from '../../../utils/util';
import { selectUser, setUserFields } from '../../../store/slices/user/slice';
import { VATS } from '../../../utils/vats';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image-02.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/video.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check01.svg';

const CREDITS_PRICES = [
  { amount: 100, price: 10 },
  { amount: 200, price: 20 },
  { amount: 500, price: 50 },
  { amount: 1000, price: 100 },
  { amount: 2500, price: 250 },
  { amount: 5000, price: 500 },
];

const DESCRIPTIONS = [
  { icon: <ImageIcon />, text: 'Exclusive NSFW pictures' },
  { icon: <VideoIcon />, text: 'Live Video Calls' },
  { icon: <PhoneIcon />, text: 'Live Audio Calls' },
  { icon: <HeartIcon />, text: 'Send Pictures & Get Rated' },
];

const TopUpContent = ({ onClose }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [creditPrice, setCreditPrice] = useState(CREDITS_PRICES[0]);
  const [customAmount, setCustomAmount] = useState('');
  const [inputError, setInputError] = useState('');
  const [customCredits, setCustomCredits] = useState(null);
  const purchaseCreditsPending = useSelector(selectPurchaseCreditsPending);
  const processingPayment = useSelector(selectProcessingPayment);
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [error, setError] = useState(null);
  const [getPaymentSessionAttempts, setGetPaymentSessionAttempts] = useState(1);
  const [intervalLength, setIntervalLength] = useState(1000);

  const defaultPaymentMethod = user.paymentMethods.find(method => method.isDefault);

  const additionalVat = useMemo(() => {
    if (user && defaultPaymentMethod) {
      return VATS[defaultPaymentMethod.billingAddress.country.alpha3Code] || 0;
    } else {
      return 0;
    }
  }, [user, defaultPaymentMethod]);

  useEffect(() => {
    if (customAmount !== '' && !/^[1-9]\d*$/.test(customAmount)) {
      setInputError('Please enter a valid amount');
    } else {
      if (customAmount !== '' && (customAmount < 10 || customAmount > 1000)) {
        setInputError('Amount should be between 10$ and 1000$');
        setCustomCredits(null);
      } else {
        setInputError('');
        setCustomCredits(roundNumber(customAmount * CREDITS_FOR_ONE_USD, 0));
      }
    }
  }, [customAmount]);

  const handleTopUp = () => {
    dispatch(
      purchaseCredits({
        amount: customAmount ? customAmount : creditPrice.price,
        paymentMethodId: defaultPaymentMethod.id,
      }),
    )
      .unwrap()
      .then(({ paymentSessionId }) => {
        setSessionId(paymentSessionId);
        dispatch(setProcessingPayment(true));
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  useEffect(() => {
    if (redirectUrl && timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    } else if (timeRemaining === 0 && redirectUrl) {
      setTimeout(() => {
        window.open(redirectUrl, '_self', 'noreferrer');
      });
    }
  }, [timeRemaining, redirectUrl]);

  useEffect(() => {
    let intervalId;
    if (sessionId && user?.id) {
      intervalId = setInterval(() => {
        dispatch(getPaymentSession({ sessionId, attempts: getPaymentSessionAttempts }))
          .unwrap()
          .then(({ userData, paymentSession }) => {
            if (paymentSession.status === PAYMENT_SESSION_STATUS.FAILED) {
              clearInterval(intervalId);
              setError(paymentSession.error);
            } else if (paymentSession.status === PAYMENT_SESSION_STATUS.SUCCEEDED) {
              clearInterval(intervalId);
              const { credits, subscribedUntil, activeSubscription, paymentMethods } = userData;
              dispatch(setUserFields({ credits, subscribedUntil, activeSubscription, paymentMethods }));
              dispatch(setProcessingPayment(false));
              notifyInfo('Payment successfully processed');
              onClose();
            } else if (paymentSession.status === PAYMENT_SESSION_STATUS.ACTION_REQUIRED) {
              clearInterval(intervalId);
              setRedirectUrl(paymentSession.redirect3dsUrl);
              setTimeRemaining(3);
            } else {
              setGetPaymentSessionAttempts(getPaymentSessionAttempts + 1);
              if (getPaymentSessionAttempts > 14) {
                setIntervalLength(3000);
              }
            }
          })
          .catch(err => {
            clearInterval(intervalId);
            notifyError(err.message);
          });
      }, intervalLength);
    }

    return () => intervalId && clearInterval(intervalId);
    // eslint-disable-next-line
  }, [sessionId, user?.id, dispatch, intervalLength, getPaymentSessionAttempts]);

  const onPriceClick = credit => {
    setCreditPrice(credit);
    setCustomAmount('');
  };

  return (
    <Container>
      {processingPayment ? (
        <ProcessingPaymentContainer>
          {error ? (
            <ErrorContainer>
              <Header>
                <CloseIcon color="#FF0000" />
                <Text20Bold>Payment Failed</Text20Bold>
              </Header>
              <ErrorText>{error}</ErrorText>
              <Button
                onClick={() => {
                  dispatch(setProcessingPayment(false));
                  setError(null);
                }}
                title="Try Again"
              />
            </ErrorContainer>
          ) : (
            <>
              <Spinner src={DotsLoader} width={150} height={150} />
              <ProcessingPaymentText>
                Processing payment.
                <br />
                Please wait.
              </ProcessingPaymentText>
              {redirectUrl && <RedirectingText>Redirecting in {timeRemaining} seconds.</RedirectingText>}
            </>
          )}
        </ProcessingPaymentContainer>
      ) : (
        <>
          <CreditsContainer>
            <Text44ExtraBold>💎 {customCredits ? customCredits : creditPrice.amount}</Text44ExtraBold>
            <CreditsText>CREDITS</CreditsText>
          </CreditsContainer>
          <AmountCredits>
            {CREDITS_PRICES.map((credit, index) => {
              return (
                <AmountButton key={index} onClick={() => onPriceClick(credit)} $selected={credit === creditPrice}>
                  ${credit.price}
                </AmountButton>
              );
            })}
          </AmountCredits>
          <AddAmountButton>or add custom amount</AddAmountButton>
          <StyledInput
            placeholder="Enter amount"
            onChange={e => setCustomAmount(e.target.value)}
            value={customAmount}
            error={inputError}
            leftIcon={<Text15Light>$</Text15Light>}
          />
          <Line />
          <DescriptionsContainer>
            <Text>Credits can be used for</Text>
            {DESCRIPTIONS.map((description, index) => {
              return (
                <DescriptionContainer key={index}>
                  <IconWrapper>{description.icon}</IconWrapper>
                  <Text14Regular>{description.text}</Text14Regular>
                </DescriptionContainer>
              );
            })}
          </DescriptionsContainer>
          {!!additionalVat && <NoteText>*VAT not included in price displayed.</NoteText>}
          <ButtonContainer>
            <Button
              disabled={inputError}
              isLoading={purchaseCreditsPending}
              title={`Top up for $${customAmount ? customAmount : creditPrice.price} ${
                additionalVat
                  ? `+ $${roundNumber(
                      ((customAmount ? customAmount : creditPrice.price) * additionalVat) / 100,
                      2,
                    )} VAT(${additionalVat}%)`
                  : ''
              }`}
              onClick={handleTopUp}
            />
            <NoteContainer>
              <CheckIcon />
              <PaymentNoteText>No adult transaction in your bank statement.</PaymentNoteText>
            </NoteContainer>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default TopUpContent;
