import styled from 'styled-components';
import { Text10Regular, Text14Light } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding-bottom: 1.6rem;
`;

export const Text = styled(Text10Regular)`
  color: #bb8efb;
`;

export const ReplyText = styled(Text14Light)`
  line-height: 2.2rem;
`;

export const ReplyContent = styled.div`
  border-radius: 10px;
  background: #190c30;
  padding: 0.4rem 1.4rem;
  width: fit-content;
  cursor: pointer;
`;
