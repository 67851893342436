import React, { useState } from 'react';
import {
  ActionButton,
  ButtonsContainer,
  CloseIconWrapper,
  Container,
  CopyButton,
  Line,
  PlatformContainer,
  SvgContainer,
  TelegramText,
  Text,
  TextContainer,
  TitleText,
} from './TelegramModalContent.styles';
import { Text16Regular } from '../utils/Text/Text.styles';
import { ReactComponent as AndroidIcon } from '../../assets/icons/android.svg';
import { ReactComponent as AppleIcon } from '../../assets/icons/apple.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as OnlyChatsIcon } from '../../assets/icons/only-chats.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as TelegramSwitchIcon } from '../../assets/icons/telegram-switch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getTelegramInvitationUrl } from '../../store/slices/user/asyncThunk';
import { notifyError, notifyInfo } from '../../utils/notify';
import { selectGetTelegramInvitationLinkPending } from '../../store/slices/user/slice';
import Spinner from '../Spinner/Spinner';
import { useMediaQuery } from 'react-responsive';

const BUTTON_ACTIONS = {
  CONNECT_PROFILE: 'CONNECT_PROFILE',
  COPY_LINK: 'COPY_LINK',
};

const TelegramModalContent = ({ onClose }) => {
  const dispatch = useDispatch();
  const getTelegramInvitationLinkPending = useSelector(selectGetTelegramInvitationLinkPending);
  const [buttonAction, setButtonAction] = useState(null);

  const smallMobile = useMediaQuery({ minWidth: 0, maxWidth: 355 });

  const copyLinkClick = () => {
    setButtonAction(BUTTON_ACTIONS.COPY_LINK);
    dispatch(getTelegramInvitationUrl())
      .unwrap()
      .then(({ invitationUrl }) => {
        setTimeout(() => {
          navigator.clipboard.writeText(invitationUrl);
        }, 0);
        notifyInfo('Link copied to clipboard!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const connectProfileClick = () => {
    setButtonAction(BUTTON_ACTIONS.CONNECT_PROFILE);
    dispatch(getTelegramInvitationUrl())
      .unwrap()
      .then(({ invitationUrl }) => {
        setTimeout(() => {
          window.open(invitationUrl, '_blank', 'noreferrer');
        });
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <Container>
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
      <SvgContainer>
        <TelegramSwitchIcon />
      </SvgContainer>
      <TextContainer>
        <TitleText>Launch</TitleText>
        <OnlyChatsIcon height={smallMobile ? 20 : 35} />
        <TitleText>Premium</TitleText>
      </TextContainer>
      <TextContainer>
        <TitleText>by signing up on </TitleText>
        <TelegramText>Telegram</TelegramText>
      </TextContainer>
      <PlatformContainer>
        <AndroidIcon
          elementId="Subscription purchased-plan-24-permonth-googleplay"
          onClick={() => {
            setTimeout(() => {
              window.open(
                'https://play.google.com/store/apps/details?id=org.telegram.messenger&pcampaignid=web_share',
                '_blank',
                'noreferrer',
              );
            });
          }}
        />
        <AppleIcon
          elementId="Subscription purchased-plan-24-permonth-apple"
          onClick={() => {
            setTimeout(() => {
              window.open('https://apps.apple.com/rs/app/telegram-messenger/id686449807', '_blank', 'noreferrer');
            });
          }}
        />
      </PlatformContainer>
      <Line />
      <Text>Then click the link below to start</Text>
      <ButtonsContainer>
        <ActionButton elementId="Subscription purchased-plan-24-permonth-connecttelegram" onClick={connectProfileClick}>
          {buttonAction === BUTTON_ACTIONS.CONNECT_PROFILE && getTelegramInvitationLinkPending ? (
            <Spinner width={20} height={20} />
          ) : (
            <>
              <LinkIcon />
              <Text16Regular>Connect Profile</Text16Regular>
            </>
          )}
        </ActionButton>
        <CopyButton elementId="Subscription purchased-plan-24-permonth-copyurl" onClick={copyLinkClick}>
          {buttonAction === BUTTON_ACTIONS.COPY_LINK && getTelegramInvitationLinkPending ? (
            <Spinner width={20} height={20} />
          ) : (
            <CopyIcon />
          )}
        </CopyButton>
      </ButtonsContainer>
    </Container>
  );
};

export default TelegramModalContent;
