import React from 'react';
import { SwitchButton, SwitchCircle, SwitchContainer, SwitchText } from './SwitchMessageType.styles';

const SwitchMessageType = ({ rightValue, selectedValue, onChange, circleColor }) => {
  return (
    <SwitchContainer>
      <SwitchText>{selectedValue.toLowerCase()}</SwitchText>
      <SwitchButton onClick={onChange}>
        <SwitchCircle $circleColor={circleColor} $rightValueSelected={rightValue === selectedValue} />
      </SwitchButton>
    </SwitchContainer>
  );
};

export default SwitchMessageType;
