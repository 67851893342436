import React, { useEffect, useState } from 'react';
import {
  AboutMeText,
  AboutMeTextMobile,
  ActionsContainer,
  AvatarImage,
  AvatarImageWrapper,
  BackIcon,
  BalanceText,
  BasicInfoContainer,
  Buttons,
  CallButton,
  ChatButton,
  CoverImage,
  CreditsContainer,
  Grid,
  Header,
  OnlineContainer,
  LottieContainer,
  NameContainer,
  PageContainer,
  RightSide,
  SecretModeButton,
  SecretModeItems,
  SecretModes,
  SectionTitle,
  Shadow,
  Circle,
  OnlineText,
  MobileButtons,
  ActionButtonWrapper,
} from './InfluencerInfoPage.styles';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/chevron_left.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message_circle.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus_circle.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Text16Regular, Text20Bold } from '../../components/utils/Text/Text.styles';
import { useDispatch, useSelector } from 'react-redux';
import { getInfluencerByUsername } from '../../store/slices/influencer/asyncThunk';
import { selectGetInfluencerByUsernamePending, selectSelectedInfluencer } from '../../store/slices/influencer/slice';
import { getVisitedChats, isSubscribed } from '../../utils/util';
import { selectUser } from '../../store/slices/user/slice';
import { CHAT, CHAT_PREVIEW, HOME } from '../../components/App/routes';
import { useSubscriptionModal } from '../../components/modals/SubscriptionModal/SubscriptionModal';
import { useAuthModal } from '../../components/modals/AuthModal/AuthModal';
import { useTelegramModal } from '../../components/modals/TelegramModal/TelegramModal';
import { SUBSCRIPTION_MODAL_MODE } from '../../components/payment/SubscriptionModalContent/SubscriptionModalContent';
import Lottie from 'lottie-react';
import logoAnimation from '../../assets/gifs/logo.json';
import { InfluencerInfoPageHelmet, SwitchMessageType } from '../../components';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { changeUserResponseFormat } from '../../store/slices/user/asyncThunk';
import { notifyError } from '../../utils/notify';
import { COOKIE_NAME } from '../../utils/constants';
import { useCookies } from 'react-cookie';

export const CHAT_MODE = {
  VOICE: 'VOICE',
  TEXT: 'TEXT',
};

const InfluencerInfoPage = () => {
  const influencer = useSelector(selectSelectedInfluencer);
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectLoggedIn);
  const getInfluencerByUsernamePending = useSelector(selectGetInfluencerByUsernamePending);
  const { influencerUsername } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSecretMode, setSelectedSecretMode] = useState(null);
  const [chatMode, setChatMode] = useState(CHAT_MODE.VOICE);
  const [openAuthModal] = useAuthModal();
  const [openTelegramModal] = useTelegramModal();
  const [openSubscriptionModal] = useSubscriptionModal();
  const { state } = useLocation();
  const fromPage = state?.from;
  const [, setCookie] = useCookies([COOKIE_NAME.REFERRED_BY_INFLUENCER]);

  useEffect(() => {
    if (fromPage === undefined) {
      setCookie(COOKIE_NAME.REFERRED_BY_INFLUENCER, { influencerUsername }, { expires: 0 });
    }
  }, [fromPage, influencerUsername, setCookie]);

  const preventAction = () => {
    if (!user) {
      openAuthModal(null, () => {});
      return true;
    }

    if (isSubscribed(user.subscribedUntil)) {
      openTelegramModal();
    } else {
      openSubscriptionModal();
    }
  };

  useEffect(() => {
    if (influencerUsername) {
      dispatch(getInfluencerByUsername({ influencerUsername }));
    }
  }, [influencerUsername, dispatch]);

  useEffect(() => {
    if (user) {
      setChatMode(user.responseFormat);
    }
  }, [user]);

  useEffect(() => {
    if (influencer && isLoggedIn !== null) {
      const visitedChats = getVisitedChats(user);
      const influencerChat = visitedChats[influencer.username];

      if (influencerChat) {
        const activeSecretModeId = influencerChat.activeSecretModeId;
        setSelectedSecretMode(influencer.secretModes.find(sm => sm.id === activeSecretModeId));
      } else {
        setSelectedSecretMode(influencer.secretModes.find(sm => sm.isDefault));
      }
    }
    // eslint-disable-next-line
  }, [influencer, isLoggedIn]);

  const onAddBalanceClick = () => {
    if (!user) {
      openAuthModal(null, () => {});
      return true;
    }

    if (isSubscribed(user.subscribedUntil)) {
      openSubscriptionModal(SUBSCRIPTION_MODAL_MODE.TOP_UP);
    } else {
      openSubscriptionModal();
    }
  };

  const onBack = () => {
    if (fromPage && fromPage !== HOME) {
      navigate(fromPage);
    } else {
      navigate(`${HOME}`);
    }
  };

  const switchChange = () => {
    if (!user) {
      openAuthModal(null, () => {});
      return true;
    }
    const newChatMode = chatMode === CHAT_MODE.TEXT ? CHAT_MODE.VOICE : CHAT_MODE.TEXT;
    setChatMode(newChatMode);
    dispatch(changeUserResponseFormat({ responseFormat: newChatMode }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        notifyError(err.message);
        setChatMode(chatMode);
      });
  };

  if (getInfluencerByUsernamePending || !influencer) {
    return (
      <LottieContainer>
        <Lottie animationData={logoAnimation} loop={true} />
      </LottieContainer>
    );
  }

  return (
    <PageContainer>
      <InfluencerInfoPageHelmet influencer={influencer} />
      <Shadow />
      <Header>
        <BackIcon onClick={onBack}>
          <LeftArrowIcon />
        </BackIcon>
        <ActionsContainer>
          <SwitchMessageType
            leftValue={CHAT_MODE.VOICE}
            rightValue={CHAT_MODE.TEXT}
            selectedValue={chatMode}
            onChange={switchChange}
          />
          <CreditsContainer onClick={onAddBalanceClick}>
            <BalanceText>{user ? user.credits : 0} 💎</BalanceText>
            <PlusIcon />
          </CreditsContainer>
        </ActionsContainer>
      </Header>
      <BasicInfoContainer>
        <AvatarImageWrapper>
          <AvatarImage src={influencer.profilePhoto} />
        </AvatarImageWrapper>
        <RightSide>
          <NameContainer>
            <Text20Bold>
              {influencer.name}, {influencer.age}
            </Text20Bold>
            <VerifiedIcon />
          </NameContainer>
          <OnlineContainer>
            <Circle />
            <OnlineText>Online</OnlineText>
          </OnlineContainer>
          <AboutMeText>{influencer.aboutMe}</AboutMeText>
          <Buttons>
            <ActionButtonWrapper>
              <ChatButton
                onClick={() => {
                  navigate(`/${CHAT}/${CHAT_PREVIEW.replace(':influencerUsername', influencerUsername)}`, {
                    state: { secretMode: selectedSecretMode },
                  });
                }}>
                <MessageIcon />
                <Text16Regular>Chat Now</Text16Regular>
              </ChatButton>
            </ActionButtonWrapper>

            <CallButton onClick={preventAction}>
              <PhoneIcon color="#fff" />
              <Text16Regular>Call</Text16Regular>
            </CallButton>
          </Buttons>
        </RightSide>
      </BasicInfoContainer>
      <AboutMeTextMobile>{influencer.aboutMe}</AboutMeTextMobile>
      <SecretModes>
        <SectionTitle>Secret Modes</SectionTitle>
        <SecretModeItems>
          {influencer.secretModes.map(sm => {
            return (
              <SecretModeButton
                key={sm.id}
                onClick={() => setSelectedSecretMode(sm)}
                $selected={sm === selectedSecretMode}>
                {`${sm.symbol} ${sm.name}`}
              </SecretModeButton>
            );
          })}
        </SecretModeItems>
      </SecretModes>
      <MobileButtons>
        <ActionButtonWrapper>
          <ChatButton
            onClick={() => {
              navigate(`/${CHAT}/${CHAT_PREVIEW.replace(':influencerUsername', influencerUsername)}`, {
                state: { secretMode: selectedSecretMode },
              });
            }}>
            <MessageIcon />
            <Text16Regular>Chat Now</Text16Regular>
          </ChatButton>
        </ActionButtonWrapper>
        <CallButton onClick={preventAction}>
          <PhoneIcon color="#fff" />
          <Text16Regular>Call</Text16Regular>
        </CallButton>
      </MobileButtons>
      <Grid>
        {influencer.albumMedia.photos.cover.map((photo, index) => {
          return <CoverImage key={index} src={photo} />;
        })}
      </Grid>
    </PageContainer>
  );
};

export default InfluencerInfoPage;
