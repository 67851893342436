import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { HOME } from '../../components/App/routes';

const PrivateLayout = () => {
  const isLoggedIn = useSelector(selectLoggedIn);

  const location = useLocation();

  if (isLoggedIn !== null && !isLoggedIn) {
    return <Navigate to={`${HOME}`} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateLayout;
