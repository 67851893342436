import styled from 'styled-components';
import { Text11Light, Text12Light, Text18Light, Text22Bold } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  padding-top: 4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  margin-top: 5.4rem;
`;

export const Divider = styled.div`
  height: 1px;
  opacity: 0.56;
  background: linear-gradient(90deg, #030108 0%, #a168ff 47.5%, #030108 100%);
  margin: 5.6rem 0;
  width: 100%;
`;

export const GoogleSignUpButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #7438df;
  background: linear-gradient(0deg, #2b1754 0%, #2b1754 100%), #5e3d99;
  backdrop-filter: blur(2px);
  width: 100%;
  gap: 0.8rem;
  margin-bottom: 5.6rem;

  span {
    font-size: 1.4rem;
    color: #fff;
    font-weight: 300;
  }
`;

export const NoteText = styled(Text11Light)`
  line-height: 2rem;
  text-align: center;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const PrivacyProtectedText = styled(Text12Light)`
  color: #87ff8c;
`;

export const PrivacyProtectedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: center;
  gap: 0.4rem;
`;

export const TextItalic = styled(Text22Bold)`
  font-style: italic;
  font-weight: 500;
`;

export const Title = styled(Text18Light)`
  color: #c4a0ff;
  letter-spacing: -0.54px;
  margin-bottom: 0.8rem;
`;
