import axiosApiClient from '..';
import { PAYMENT_TYPE } from '../../utils/constants';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/payment';

class PaymentService {
  createPaymentSession = async ({
    subscriptionPeriod,
    subscriptionPlan,
    paymentType,
    address,
    city,
    zip,
    country,
    state,
    amount,
  }) => {
    try {
      let body = {
        paymentType,
        address,
        city,
        zip,
        country,
        ...(state && { state }),
      };

      if (paymentType === PAYMENT_TYPE.CREDIT_PURCHASE) {
        body.amount = amount;
      } else {
        body.subscriptionPeriod = subscriptionPeriod;
        body.subscriptionPlan = subscriptionPlan;
      }

      const response = await axiosApiClient.post(`${BASE_URL}/w/create-session`, body);
      throwErrorIfWrongStatus(response, 200);
      const { publicToken } = response.data.data;
      return { publicToken };
    } catch (err) {
      throw handleError(err, 'Error occurred while creating payment session');
    }
  };

  purchaseCredits = async ({ amount, paymentMethodId }) => {
    try {
      const body = { amount, paymentMethodId };
      const response = await axiosApiClient.post(`${BASE_URL}/w/credits`, body);
      throwErrorIfWrongStatus(response, 200);
      const { paymentSessionId } = response.data.data;
      return { paymentSessionId };
    } catch (err) {
      throw handleError(err, 'Error occurred while purchasing credits');
    }
  };

  cancelSubscription = async () => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/w/subscription/cancel`, {});
      throwErrorIfWrongStatus(response, 200);
      const { canceledAt } = response.data.data;
      return { canceledAt };
    } catch (err) {
      throw handleError(err, 'Error occurred while canceling subscription');
    }
  };

  getPaymentSession = async ({ sessionId, attempts }) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/w/session?id=${sessionId}&attempts=${attempts}`);
      throwErrorIfWrongStatus(response, 200);
      const { paymentSession, userData } = response.data.data;
      return { paymentSession, userData };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching payment session');
    }
  };
}

export default new PaymentService();
