import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  margin-top: 4.4rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  margin-bottom: 3.7rem;
  @media only screen and (max-width: 600px) {
    margin-bottom: 7.6rem;
  }
`;

export const GenderSelection = styled.div`
  display: flex;
  gap: 1.6rem;
  padding-inline: 3.2rem;
  justify-content: space-between;

  @media only screen and (max-width: 385px) {
    padding-inline: unset;
  }
`;

export const GenderButton = styled.button`
  border-radius: 8px;
  border: 1px solid ${({ $selected }) => ($selected ? '#7438DF' : 'rgba(116, 56, 223, 0.16)')};
  background: ${$selected =>
    $selected
      ? 'linear-gradient(0deg, #2B1754 0%, #2B1754 100%), #5E3D99'
      : 'linear-gradient(0deg, #1d0f37 0%, #1d0f37 100%), #5e3d99'};
  backdrop-filter: blur(2px);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 0.4rem 2rem;
  cursor: pointer;
`;
