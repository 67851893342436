export const HOME = '/';
export const EXPLORE = 'spotlight';
export const CHAT = 'chats';
export const PROFILE = 'profile';
export const CHECKOUT = 'checkout';
export const TAG = 'tag/:tagName';
export const PERSONALITY = 'personality/:personalityName';
export const APPEARANCE = 'hashtag/:appearanceName';
export const CHAT_PREVIEW = ':influencerUsername';
export const SUPPORT = 'support';
export const PRIVACY_POLICY = 'privacy-policy';
export const TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const FAQS = 'faqs';
export const INFLUENCER_INFO = 'creator/:influencerUsername';
