import React, { useState } from 'react';
import { AudioContainer, AudioContent, AudioDurationContainer, ButtonContainer } from './AudioPlayer.styles';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause-circle.svg';
import WavesurferPlayer from '@wavesurfer/react';

const AudioPlayer = ({ url }) => {
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDurationToEnd, setAudioDurationToEnd] = useState(0);

  const onReady = ws => {
    setWavesurfer(ws);
    setAudioDurationToEnd(formatTime(ws.getDuration()));
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  const formatTime = time => {
    return [Math.floor((time % 3600) / 60), ('00' + Math.floor(time % 60)).slice(-2)].join(':');
  };

  return (
    <AudioContainer>
      <ButtonContainer onClick={onPlayPause}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</ButtonContainer>
      <AudioContent>
        <WavesurferPlayer
          height={30}
          url={url}
          waveColor="#453763"
          progressColor="#855BE0"
          cursorWidth={0}
          barWidth={4}
          barHeight={3}
          barRadius={100}
          barGap={4}
          onReady={onReady}
          onAudioprocess={() =>
            setAudioDurationToEnd(formatTime(wavesurfer.getDuration() - wavesurfer.getCurrentTime()))
          }
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </AudioContent>
      {!!audioDurationToEnd && <AudioDurationContainer>{audioDurationToEnd}</AudioDurationContainer>}
    </AudioContainer>
  );
};

export default AudioPlayer;
