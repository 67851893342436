import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Content, PageContainer } from './InfluencersTagPage.styles';
import { Header, InfluencerCard, Spinner } from '../../components';
import { convertTagValueToLabel } from '../../utils/util';
import { TAGS } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getInfluencers } from '../../store/slices/influencer/asyncThunk';
import { selectGetInfluencersPending, selectInfluencers } from '../../store/slices/influencer/slice';

const InfluencersTagPage = () => {
  const { tagName } = useParams();
  const { state } = useLocation();
  const type = state?.type;
  const title = state?.title;

  const dispatch = useDispatch();
  const influencers = useSelector(selectInfluencers);
  const getInfluencersPending = useSelector(selectGetInfluencersPending);

  useEffect(() => {
    if (tagName) {
      if (type === TAGS.APPEARANCE) {
        dispatch(getInfluencers({ appearanceTags: [tagName], personalityTags: [] }));
      } else if (type === TAGS.PERSONALITY) {
        if (tagName.includes('&')) {
          const tags = tagName.split('&');
          dispatch(getInfluencers({ appearanceTags: [], personalityTags: tags }));
        } else {
          dispatch(getInfluencers({ appearanceTags: [], personalityTags: [tagName] }));
        }
      } else {
        const tags = tagName.split('&');
        dispatch(getInfluencers({ appearanceTags: [tags[1]], personalityTags: [tags[0]] }));
      }
    }
  }, [tagName, type, dispatch]);

  if (getInfluencersPending) {
    return <Spinner width={100} height={100} />;
  }

  return (
    <PageContainer>
      <Header title={convertTagValueToLabel(title || tagName)} />
      <Content>
        {influencers.map(influencer => {
          return (
            <InfluencerCard
              key={influencer.id}
              id={influencer.id}
              width={240}
              name={influencer.name}
              isVerified={influencer.isVerified}
              image={influencer.image}
              username={influencer.username}
            />
          );
        })}
      </Content>
    </PageContainer>
  );
};

export default InfluencersTagPage;
