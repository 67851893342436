import React from 'react';
import {
  Container,
  Content,
  H2Text,
  Links,
  LinksContainer,
  LinkText,
  LogoContainer,
  LogoText,
  ReservedText,
  SocialIcon,
  SocialLinks,
  SocialsContainer,
  Text,
} from './Footer.styles';
import LogoComponent from '../LogoComponent/LogoComponent';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as TiktokIcon } from '../../assets/icons/tiktok.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icons/telegram.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { useNavigate } from 'react-router-dom';
import { CHAT, EXPLORE, HOME, PRIVACY_POLICY, PROFILE, TERMS_AND_CONDITIONS } from '../App/routes';
import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { useAuthModal } from '../modals/AuthModal/AuthModal';

const SOCIAL_PLATFORMS = {
  TWITTER: 'twitter',
  TIKTOK: 'tiktok',
  TELEGRAM: 'telegram',
  INSTAGRAM: 'instagram',
};

const Footer = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const [openAuthModal] = useAuthModal();

  const navigate = useNavigate();

  const handleOnClick = () => {
    if (!loggedIn) {
      openAuthModal(null, () => {
        navigate(`/${PROFILE}`);
      });
      return;
    } else {
      navigate(`/${PROFILE}`);
    }
  };

  const onSocialClick = socialPlatform => {
    switch (socialPlatform) {
      case SOCIAL_PLATFORMS.TWITTER:
        break;
      case SOCIAL_PLATFORMS.TIKTOK:
        break;
      case SOCIAL_PLATFORMS.TELEGRAM:
        window.open('https://t.me/onlynews', '_blank');
        break;
      case SOCIAL_PLATFORMS.INSTAGRAM:
        window.open('https://www.instagram.com/onlychats', '_blank');
        break;
      default:
        break;
    }
  };
  return (
    <Container>
      <LogoContainer>
        <LogoComponent fullLogo id="footer" />
        <LogoText>AI Girlfriend & Anime Chat</LogoText>
        <H2Text>Experience the most realistic AI Girlfriend & Anime Chat platform</H2Text>
      </LogoContainer>
      <Content>
        <LinksContainer>
          <Text>Resources</Text>
          <Links>
            <LinkText onClick={() => navigate(`${HOME}`)}>Explore</LinkText>
            <LinkText onClick={() => navigate(`/${EXPLORE}`)}>Spotlight</LinkText>
            <LinkText onClick={() => navigate(`/${CHAT}`)}>Chats</LinkText>
            <LinkText onClick={handleOnClick}>Profile</LinkText>
          </Links>
        </LinksContainer>
        <LinksContainer>
          <Text>Company</Text>
          <Links>
            <LinkText onClick={() => navigate(`/${TERMS_AND_CONDITIONS}`)}>Terms & Conditions</LinkText>
            <LinkText onClick={() => navigate(`/${PRIVACY_POLICY}`)}>Privacy Policy</LinkText>
            <LinkText onClick={() => window.open('https://about.onlychats.com', '_blank')}>About</LinkText>
          </Links>
        </LinksContainer>
      </Content>
      <SocialsContainer>
        <Text>Socials</Text>
        <SocialLinks>
          <SocialIcon onClick={() => onSocialClick(SOCIAL_PLATFORMS.TELEGRAM)}>
            <TelegramIcon />
          </SocialIcon>
          <SocialIcon onClick={() => onSocialClick(SOCIAL_PLATFORMS.INSTAGRAM)}>
            <InstagramIcon />
          </SocialIcon>
          <SocialIcon onClick={() => onSocialClick(SOCIAL_PLATFORMS.TIKTOK)}>
            <TiktokIcon />
          </SocialIcon>
          <SocialIcon onClick={() => onSocialClick(SOCIAL_PLATFORMS.TWITTER)}>
            <TwitterIcon />
          </SocialIcon>
        </SocialLinks>
      </SocialsContainer>
      <ReservedText>2024 OnlyChats. All rights reserved.</ReservedText>
    </Container>
  );
};

export default Footer;
