import styled from 'styled-components';
import { Text15Light } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  padding-top: 8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  margin-top: 4.4rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  margin-top: auto;
  margin-bottom: 3.7rem;
  @media only screen and (max-width: 600px) {
    margin-bottom: ${({ $loggedIn }) => ($loggedIn ? '7.6rem' : '3.9rem')};
  }
`;

export const ForgotPasswordText = styled(Text15Light)`
  color: #c4a0ff;
  text-decoration: underline;
  cursor: pointer;
  margin-inline: auto;
`;
