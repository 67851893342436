import styled from 'styled-components';
import { Text12Light, Text13Light } from '../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  gap: 2.4rem;
  padding: 3.2rem 2.4rem;
  padding-bottom: 6.8rem;
  position: relative;
  background: linear-gradient(180deg, #010102 0%, #100a1a 100%);
  background: linear-gradient(
    180deg,
    color(display-p3 0.0039 0.0039 0.0078 / 0) 0%,
    color(display-p3 0.0593 0.0419 0.0976) 100%
  );
  justify-content: space-between;
  margin-top: auto;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-inline: 1.6rem;
    padding-bottom: 10rem;
    padding-top: 4rem;
    margin: 0 -1.6rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const LogoText = styled.h1`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.408px;
`;

export const H2Text = styled.h2`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2rem;
  letter-spacing: -0.408px;
  max-width: 22rem;
  opacity: 0.6;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Text = styled(Text12Light)`
  line-height: 2rem;
  letter-spacing: -0.408px;
  opacity: 0.4;
`;

export const LinkText = styled(Text13Light)`
  letter-spacing: -0.42px;
  color: #c29cff;
  cursor: pointer;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 2.4rem;
`;

export const SocialIcon = styled.div`
  cursor: pointer;
`;

export const ReservedText = styled(Text12Light)`
  line-height: 2rem;
  letter-spacing: -0.408px;
  opacity: 0.2;

  @media only screen and (min-width: 768px) {
    position: absolute;
    left: 2.4rem;
    bottom: 2.4rem;
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 32rem;
  width: 100%;
  justify-content: space-between;
`;
