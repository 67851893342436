export const BASE_URL = 'https://api.onlychats.com';
// export const BASE_URL = 'http://localhost:5001';

export const LOGOUT = 'LOGOUT';

export const SUBSCRIPTION_PERIOD = {
  ANNUALLY: 'ANNUALLY',
  '3_MONTHS': '3_MONTHS',
  MONTHLY: 'MONTHLY',
};

export const SUBSCRIPTION_PLAN = {
  STARTER: 'STARTER',
  PREMIUM: 'PREMIUM',
  VIP: 'VIP',
};

export const SUBSCRIPTION_LEVEL = {
  MONTHLY: 1,
  '3_MONTHS': 2,
  ANNUALLY: 3,
};

// MONTHLY
export const SUBSCRIPTION_CREDITS = {
  STARTER: {
    VALUE: 150,
    BONUS: 0,
    TOTAL: 150,
  },
  PREMIUM: {
    VALUE: 150,
    BONUS: 20,
    TOTAL: 170,
  },
  VIP: {
    VALUE: 500,
    BONUS: 50,
    TOTAL: 550,
  },
};

export const SUBSCRIPTION_PRICES_PER_MONTH = {
  STARTER: {
    ANNUALLY: 11.99,
    MONTHLY: 14.99,
  },
  PREMIUM: {
    ANNUALLY: 5.99,
    '3_MONTHS': 9.99,
    MONTHLY: 14.99,
  },
  VIP: {
    ANNUALLY: 39.99,
    MONTHLY: 49.99,
  },
};

export const VALUE_OF_ONE_CREDIT_IN_USD = 0.1;
export const CREDITS_FOR_ONE_USD = 1 / VALUE_OF_ONE_CREDIT_IN_USD;

export const PAYMENT_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  CREDIT_PURCHASE: 'CREDIT_PURCHASE',
};

export const GENDERS = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Other', value: 'OTHER' },
];

export const TAGS = {
  PERSONALITY: 'personality',
  APPEARANCE: 'appearance',
};

export const LOCAL_STORAGE_ITEMS = {
  VISITED_CHATS: 'visited_chats',
};

export const COOKIE_NAME = {
  AGE_ALERT_WARNING: 'age_alert_warning',
  REFERRED_BY_AFFILIATE: 'referred_by_affiliate',
  REFERRED_BY_INFLUENCER: 'referred_by_influencer',
};

export const USER_ROLE = {
  INFLUENCER: 'INFLUENCER',
  USER: 'USER',
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  VOICE: 'VOICE',
  PHOTO: 'PHOTO',
  PREMIUM_PHOTO: 'PREMIUM_PHOTO',
};

export const GOOGLE_RECAPTCHA_SITE_KEY = '6Le8PwsqAAAAAGFanjpTSn0vrBLyZh0QmhYm3GX6';

export const PAYMENT_SESSION_STATUS = {
  CREATED: 'CREATED',
  ACTION_REQUIRED: 'ACTION_REQUIRED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
};

export const INFLUENCER_CATEGORY = {
  PERSON: 'PERSON',
  ANIME: 'ANIME',
};

export const NUMBER_OF_FREE_MESSAGES_PER_DAY = 200;

export const DAILY_LIMIT_EXCEEDED_ERROR_MESSAGE =
  'Due to a large number of users that tried to abuse our limitless system recently, we turned on a limit of 200 messages per day.  In order to still provide the best experience to our loyal members, topping up any amount to your wallet will reset you daily limit.';

export const REFERRED_BY_TYPE = {
  AFFILIATE: 'AFFILIATE',
  INFLUENCER: 'INFLUENCER',
};
