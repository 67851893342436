import React from 'react';
import {
  Card,
  ChatButton,
  Image,
  Shadow,
  InfoContainer,
  NameContainer,
  AboutMeText,
  NameText,
  StyledVerifiedIcon,
  AnimeWrapper,
  IconsContainer,
} from './InfluencerCard.styles';
import { ReactComponent as MessageDotIcon } from '../../assets/icons/message-dots-circle.svg';
import { ReactComponent as VideoIcon } from '../../assets/icons/video_1.svg';
import { ReactComponent as CallIcon } from '../../assets/icons/call_1.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message_2.svg';

import { ReactComponent as AnimeIcon } from '../../assets/icons/anime.svg';
import { CHAT, CHAT_PREVIEW, INFLUENCER_INFO } from '../App/routes';
import { useNavigate } from 'react-router-dom';
import { INFLUENCER_CATEGORY } from '../../utils/constants';

const InfluencerCard = ({
  name,
  isVerified,
  image,
  username,
  aspectRatio,
  chatButton,
  age,
  aboutMe,
  innerRef,
  marginRight,
  category,
  from,
  callOptions,
}) => {
  const navigate = useNavigate();

  const onChatClick = e => {
    e.stopPropagation();
    navigate(`/${CHAT}/${CHAT_PREVIEW.replace(':influencerUsername', username)}`);
  };

  return (
    <Card
      to={{ pathname: `/${INFLUENCER_INFO.replace(':influencerUsername', username)}` }}
      state={{ from }}
      $aspectRatio={aspectRatio}
      ref={innerRef}
      $marginRight={marginRight}>
      {category === INFLUENCER_CATEGORY.ANIME && (
        <AnimeWrapper>
          <AnimeIcon />
        </AnimeWrapper>
      )}
      <IconsContainer>
        <MessageIcon />
        <CallIcon />
        {callOptions?.isVideoCallEnabled && <VideoIcon />}
      </IconsContainer>
      <InfoContainer $chatButtonHidden={!chatButton}>
        <NameContainer>
          <NameText>
            {name}, {age}
          </NameText>
          {isVerified && <StyledVerifiedIcon />}
        </NameContainer>
        <AboutMeText>{aboutMe}</AboutMeText>
      </InfoContainer>
      {chatButton && (
        <ChatButton onClick={onChatClick}>
          <MessageDotIcon />
        </ChatButton>
      )}
      <Image src={image} />
      <Shadow />
    </Card>
  );
};

export default InfluencerCard;
