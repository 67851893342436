import styled from 'styled-components';
import { Text16Regular } from '../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
`;

export const RightShadow = styled.div`
  background: linear-gradient(
    270deg,
    color(display-p3 0.0078 0.0039 0.0118) 0%,
    color(display-p3 0.0078 0.0039 0.0118 / 0) 100%
  );
  right: 0;
  bottom: -0.2rem;
  position: absolute;
  width: 2.1rem;
  height: 4.2rem;

  @media only screen and (max-width: 768px) {
    height: 8.8rem;
  }
`;

export const LeftShadow = styled.div`
  background: linear-gradient(
    270deg,
    color(display-p3 0.0078 0.0039 0.0118 / 0) 0%,
    color(display-p3 0.0078 0.0039 0.0118) 100%
  );
  left: 0;
  bottom: -0.2rem;
  position: absolute;
  width: 2.1rem;
  height: 4.2rem;

  @media only screen and (max-width: 768px) {
    height: 8.8rem;
  }
`;

export const Title = styled(Text16Regular)`
  padding-left: 1.6rem;
`;

export const Content = styled.div`
  display: flex;
  gap: 0.8rem;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileContent = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
  display: none;
  gap: 0.8rem;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  border-radius: 10px;
  border: ${({ $selected }) => ($selected ? '1px solid #B485FF' : '1px solid #2c1655')};
  background: ${({ $selected }) =>
    $selected
      ? 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #3e2072'
      : 'linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36'};
  padding: 0.8rem 3rem;
  cursor: pointer;
  margin-left: ${({ $first }) => $first && '1.6rem'};
  margin-right: ${({ $last }) => $last && '1.6rem'};

  p {
    white-space: nowrap;
  }
`;

export const HashtagText = styled(Text16Regular)`
  color: #d6c2ff;
`;

export const EmptyDiv = styled.div`
  min-width: 0.1px;
`;
