import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CheckoutPageContainer,
  EmptyDiv,
  Header,
  IconWrapper,
  Form,
  InputsRow,
  StyledButton,
} from './CheckoutPage.styles';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/chevron_left.svg';
import { Text16ExtraBold } from '../../components/utils/Text/Text.styles';
import { getPriceForSelectedPlan, getVatByCountry, roundNumber } from '../../utils/util';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentSession } from '../../store/slices/payment/asyncThunk';
import { notifyError } from '../../utils/notify';
import { selectCreatePaymentSessionPending } from '../../store/slices/payment/slice';
import { PAYMENT_TYPE } from '../../utils/constants';
import { Input, PaymentInfoModal, Select } from '../../components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HOME } from '../../components/App/routes';
import { COUNTRIES, USA_STATES } from '../../utils/countries';

const USA_COUNTRY = 'United States of America';

const enterBillingAddressFormSchema = yup
  .object({
    address: yup.string().trim().required('Address is required'),
    city: yup.string().trim().required('City is required'),
    zip: yup.string().trim().required('Zip is required'),
    country: yup.mixed().required('Country is required'),
    state: yup
      .object()
      .nullable()
      .when('country', {
        is: value => {
          return value?.label === USA_COUNTRY;
        },
        then: schema => schema.required('State is required'),
      }),
  })
  .required();

const CheckoutPage = () => {
  const createPaymentSessionPending = useSelector(selectCreatePaymentSessionPending);

  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const subscriptionPeriod = state?.subscriptionPeriod;
  const subscriptionPlan = state?.subscriptionPlan;
  const amount = state?.amount;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      address: '',
      city: '',
      zip: '',
      country: null,
      state: null,
    },
    delayError: 300,
    resolver: yupResolver(enterBillingAddressFormSchema),
  });

  const watchCountry = watch('country');

  useEffect(() => {
    if ((!subscriptionPeriod || !subscriptionPlan) && !amount) {
      navigate(`${HOME}`);
    }
  }, [subscriptionPeriod, subscriptionPlan, navigate, amount]);

  const onSubmitBillingAddress = data => {
    const { address, city, zip, country, state } = data;
    setSelectedCountry(country.value.alpha3);

    dispatch(
      createPaymentSession({
        subscriptionPeriod,
        subscriptionPlan,
        paymentType: amount ? PAYMENT_TYPE.CREDIT_PURCHASE : PAYMENT_TYPE.SUBSCRIPTION,
        amount,
        address,
        city,
        zip,
        country: country.value,
        state: country.label === USA_COUNTRY ? state?.value : null,
      }),
    )
      .unwrap()
      .then(({ publicToken }) => {
        setShowModal(true);
        setToken(publicToken);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const [price, vat, priceWithVat] = useMemo(() => {
    if (subscriptionPeriod && subscriptionPlan) {
      const price = getPriceForSelectedPlan(subscriptionPeriod, subscriptionPlan);

      if (selectedCountry) {
        const vat = getVatByCountry(selectedCountry);
        const priceWithVat = roundNumber(price + (vat / 100) * price, 2);
        return [price, vat, priceWithVat];
      } else {
        return [price, 0, price];
      }
    } else {
      if (selectedCountry) {
        const vat = getVatByCountry(selectedCountry);
        const priceWithVat = roundNumber(+amount + (vat / 100) * amount, 2);
        return [+amount, vat, priceWithVat];
      } else {
        return [+amount, 0, +amount];
      }
    }
  }, [selectedCountry, subscriptionPeriod, subscriptionPlan, amount]);

  const countryOptions = useMemo(() => {
    return COUNTRIES.map(c => ({ value: c, label: c.name }));
  }, []);

  const stateOptions = useMemo(() => {
    return USA_STATES.map(state => ({ value: state, label: state }));
  }, []);

  useEffect(() => {
    setValue(
      'country',
      countryOptions.find(c => c.label === USA_COUNTRY),
    );
  }, [setValue, countryOptions]);

  return (
    <CheckoutPageContainer>
      <Header>
        <IconWrapper onClick={() => navigate(-1)}>
          <LeftArrowIcon />
        </IconWrapper>
        <Text16ExtraBold>Checkout</Text16ExtraBold>
        <EmptyDiv />
      </Header>
      <Form onSubmit={handleSubmit(onSubmitBillingAddress)}>
        <Input label="Address" placeholder="Enter address" {...register('address')} error={errors.address?.message} />
        <Input label="City" placeholder="Enter city" {...register('city')} error={errors.city?.message} />
        <InputsRow>
          <Input label="ZIP Code" placeholder="ZIP" type="tel" {...register('zip')} error={errors.zip?.message} />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                options={countryOptions}
                label="Country"
                error={errors.country?.message}
              />
            )}
          />
        </InputsRow>
        {watchCountry?.label === USA_COUNTRY && (
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                selectedOption={field.value}
                handleChange={value => field.onChange(value)}
                options={stateOptions}
                label="State"
                error={errors.state?.message}
              />
            )}
          />
        )}
        <StyledButton title="Continue" isLoading={createPaymentSessionPending} />
      </Form>
      <PaymentInfoModal
        show={showModal}
        setShow={setShowModal}
        price={price}
        vat={vat}
        priceWithVat={priceWithVat}
        subscriptionPeriod={subscriptionPeriod}
        subscriptionPlan={subscriptionPlan}
        amount={amount}
        publicToken={token}
      />
    </CheckoutPageContainer>
  );
};

export default CheckoutPage;
