import React from 'react';
import { Container, ReplyContent, ReplyText, Text } from './SuggestedReply.styles';

const SuggestedReply = ({ replyMessage, onClick }) => {
  return (
    <Container>
      <Text>SUGGESTED REPLY</Text>
      <ReplyContent onClick={onClick}>
        <ReplyText>{replyMessage}</ReplyText>
      </ReplyContent>
    </Container>
  );
};

export default SuggestedReply;
