import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../../api/services/UserService';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  return await UserService.fetchUserInfo();
});

export const updateUserInfo = createAsyncThunk('user/updateUserInfo', async requestData => {
  return await UserService.updateUserInfo(requestData);
});

export const getTelegramInvitationUrl = createAsyncThunk('user/getTelegramInvitationUrl', async () => {
  return await UserService.getTelegramInvitationUrl();
});

export const changeUserResponseFormat = createAsyncThunk('user/changeUserResponseFormat', async requestData => {
  return await UserService.changeUserResponseFormat(requestData);
});
