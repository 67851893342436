import styled from 'styled-components';

export const Container = styled.div`
  width: 4.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  border: 1px solid ${({ $isVoiceActive }) => ($isVoiceActive ? '#532B9A' : 'transparent')};
  background: ${({ $isVoiceActive }) =>
    $isVoiceActive ? 'linear-gradient(180deg, #3B1E70 0%, #552B9D 100%)' : '#271348'};
  cursor: pointer;
  padding-inline: 1px;
  display: flex;
  align-items: center;
`;

export const SwitchCircle = styled.span`
  height: 2rem;
  width: 2rem;
  background: ${({ $isVoiceActive }) =>
    $isVoiceActive ? 'linear-gradient(217deg, #AB75FF 1.49%, #8C5BE3 95.68%)' : '#8151D3'};
  border-radius: 50%;
  transform: ${({ $isVoiceActive }) => $isVoiceActive && 'translateX(100%)'};
  transition: transform 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;
