import styled from 'styled-components';
import { Text14Light, Text20Bold } from '../utils/Text/Text.styles';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import { Link } from 'react-router-dom';

export const ChatButton = styled.button`
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 1.2rem;
  right: 1.2rem;
  z-index: 10;
  border: 1px solid transparent;
`;

export const Card = styled(Link)`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  cursor: pointer;
  border: 1.5px solid transparent;

  &:hover {
    border-color: #b687ff;
    transition: 0.3s;

    ${ChatButton} {
      transition: 0.3s;
      border-color: #fff;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Shadow = styled.div`
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(180deg, color(display-p3 0 0 0 / 0) 0%, color(display-p3 0 0 0 / 0.7) 100%);
  height: 8rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: absolute;
  bottom: 1.2rem;
  left: 1.2rem;
  right: ${({ $chatButtonHidden }) => ($chatButtonHidden ? '1.2rem' : '6rem')};
  z-index: 1;
`;

export const AboutMeText = styled(Text14Light)`
  line-height: 1.9rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const NameText = styled(Text20Bold)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const StyledVerifiedIcon = styled(VerifiedIcon)`
  min-height: 1.6rem;
  min-width: 1.7rem;
`;

export const AnimeWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

export const IconsContainer = styled.div`
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
