import { useEffect, useRef, useState } from 'react';
import { OtpInputContainer, StyledOtpInput } from './OtpInput.styles';

const OtpInput = ({ codeLength, otp, setOtp, disabled, autoFocus, onChange }) => {
  const optTextInput = useRef([]);
  const [focused, setFocused] = useState(Array(codeLength).fill(false));

  useEffect(() => {
    if (autoFocus) {
      optTextInput.current[0].focus();
      setFocused(newArr => newArr.map((_, i) => i === 0));
    }
  }, [autoFocus]);

  const onKeyDown = (index, e) => {
    if ((e.keyCode === 8 || e.key === 'Backspace' || e.key === 'Delete' || e.keyCode === 46) && index !== 0) {
      e.preventDefault();
      optTextInput.current[index - 1].focus();

      let newArr = [...otp];
      newArr[index] = '';
      setOtp(newArr);
    } else if ((e.keyCode === 37 || e.key === 'ArrowLeft') && index !== 0) {
      e.preventDefault();
      optTextInput.current[index - 1].focus();
    } else if ((e.keyCode === 39 || e.key === 'ArrowRight') && index !== codeLength - 1) {
      e.preventDefault();
      optTextInput.current[index + 1].focus();
    } else if (e.keyCode === 32 || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
      e.preventDefault();
    }
  };

  const onPaste = e => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    const newOtp = [...otp];
    let activeInput = focused.findIndex(f => f);
    let nextActiveInput = activeInput;

    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, codeLength - activeInput)
      .split('');

    for (let pos = 0; pos < codeLength; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        newOtp[pos] = pastedData.shift();
        nextActiveInput++;
      }
    }

    if (nextActiveInput === codeLength) {
      nextActiveInput--;
    }

    setFocused(newArr => newArr.map((_, i) => i === nextActiveInput));

    optTextInput.current[nextActiveInput].focus();

    setOtp(newOtp);
  };

  const renderInputs = () => {
    const inputs = Array(codeLength).fill(0);
    return inputs.map((_, index) => (
      <StyledOtpInput
        key={index}
        ref={ref => (optTextInput.current[index] = ref)}
        onChange={e => {
          if (isNaN(e.target.value)) {
            return;
          }
          onChange && onChange();
          focusNext(index, e);
        }}
        type="tel"
        placeholder="-"
        onKeyDown={e => onKeyDown(index, e)}
        maxLength={1}
        autoComplete="new-password"
        onPaste={onPaste}
        disabled={disabled}
        value={otp[index] ? otp[index] : ''}
        onFocus={() => {
          setFocused(newArr => newArr.map((_, i) => i === index));
        }}
        onBlur={() => {
          setFocused(newArr => newArr.map((_, i) => i !== index));
        }}
      />
    ));
  };

  const focusNext = (index, e) => {
    if (!e.target.value.trim() && index !== 0) {
      optTextInput.current[index - 1].focus();
    }
    if (index < optTextInput.current.length - 1 && e.target.value.trim()) {
      optTextInput.current[index + 1].focus();
    }

    let newArr = [...otp];
    newArr[index] = e.target.value;
    setOtp(newArr);
  };

  return <OtpInputContainer>{renderInputs()}</OtpInputContainer>;
};

export default OtpInput;
