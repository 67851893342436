import { createSlice } from '@reduxjs/toolkit';
import {
  getExploreInfluencersBuilder,
  getInfluencerByUsernameBuilder,
  getInfluencersBuilder,
  getInfluencersLandingBuilder,
} from './builder';

const INIT_STATE = {
  influencersLanding: null,
  fetchLandingInfluencersInfo: {
    data: [],
    page: 1,
    maxReached: false,
  },
  influencers: [],
  exploreInfluencers: [],
  selectedInfluencer: null,
  // ATTEMPTS
  getInfluencersLandingPending: false,
  getInfluencersPending: false,
  getExploreInfluencersPending: false,
  getInfluencerByUsernamePending: false,
};

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    getInfluencersLandingBuilder(builder);
    getInfluencersBuilder(builder);
    getExploreInfluencersBuilder(builder);
    getInfluencerByUsernameBuilder(builder);
  },
});

export const selectInfluencersLanding = state => state.influencerInfo.influencersLanding;
export const selectInfluencers = state => state.influencerInfo.influencers;
export const selectExploreInfluencers = state => state.influencerInfo.exploreInfluencers;
export const selectSelectedInfluencer = state => state.influencerInfo.selectedInfluencer;
export const selectFetchLandingInfluencersInfo = state => state.influencerInfo.fetchLandingInfluencersInfo;

// ATTEMPTS
export const selectGetInfluencersLandingPending = state => state.influencerInfo.getInfluencersLandingPending;
export const selectGetInfluencersPending = state => state.influencerInfo.getInfluencersPending;
export const selectGetInfluencerByUsernamePending = state => state.influencerInfo.getInfluencerByUsernamePending;
export const selectGetExploreInfluencersPending = state => state.influencerInfo.getExploreInfluencersPending;

export default influencerSlice.reducer;
