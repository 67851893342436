import React from 'react';
import { Container, IconWrapper } from './Header.styles';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron_left.svg';
import { useNavigate } from 'react-router-dom';
import { Text24ExtraBold } from '../utils/Text/Text.styles';

const Header = ({ title }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <IconWrapper onClick={() => navigate(-1)}>
        <ArrowIcon />
      </IconWrapper>
      <Text24ExtraBold>{title}</Text24ExtraBold>
    </Container>
  );
};

export default Header;
