import React from 'react';
import ReactSelect, { components } from 'react-select';
import { Container, Label, SingleValueText, selectStyles } from './Select.styles';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

const { Option, SingleValue, NoOptionsMessage } = components;

const Select = ({
  label,
  selectedOption,
  options,
  handleChange,
  isSearchable,
  placeholder,
  error,
  multiSelection,
  disabled,
  className,
  isClearable,
}) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <ReactSelect
        styles={selectStyles}
        className={className}
        options={options}
        value={selectedOption}
        onChange={handleChange}
        closeMenuOnSelect={!multiSelection}
        isMulti={multiSelection}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        placeholderTextColor="#fff"
        components={{
          Option: props => IconOption(props),
          SingleValue: props => IconSingleValue(props),
          NoOptionsMessage: props => NoOptionsMessageComponent(props),
        }}
      />
      <ErrorContainer errorText={error} />
    </Container>
  );
};

const IconOption = props => {
  return (
    <Option {...props}>
      {props.data.icon}
      <SingleValueText>{props.data.label}</SingleValueText>
    </Option>
  );
};

const IconSingleValue = props => {
  return (
    <SingleValue {...props}>
      {props.data.icon}
      <SingleValueText>{props.data.label}</SingleValueText>
    </SingleValue>
  );
};

const NoOptionsMessageComponent = props => {
  return (
    <NoOptionsMessage {...props}>
      <SingleValueText>{props.children}</SingleValueText>
    </NoOptionsMessage>
  );
};

export default Select;
