import styled from 'styled-components';
import { MESSAGE_TYPE, USER_ROLE } from '../../utils/constants';
import { Text12Light, Text14Light } from '../utils/Text/Text.styles';

export const Message = styled.div`
  padding: ${({ $type }) => $type !== MESSAGE_TYPE.PHOTO && $type !== MESSAGE_TYPE.PREMIUM_PHOTO && '1rem'};
  padding-top: ${({ $type }) => $type !== MESSAGE_TYPE.PHOTO && $type !== MESSAGE_TYPE.PREMIUM_PHOTO && '0.6rem'};
  padding-bottom: ${({ $type }) => $type !== MESSAGE_TYPE.PHOTO && $type !== MESSAGE_TYPE.PREMIUM_PHOTO && '2.2rem'};
  border-radius: ${({ $role }) => ($role === USER_ROLE.INFLUENCER ? '16px 16px 16px 4px' : '16px 16px 4px 16px')};
  background: ${({ $role }) =>
    $role === USER_ROLE.INFLUENCER
      ? '#181222'
      : 'linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #7545C9'};
  max-width: 90%;
  margin-bottom: 1.6rem;
  position: relative;
  margin-left: ${({ $role }) => $role === USER_ROLE.USER && 'auto'};
  width: ${({ $type }) =>
    $type === MESSAGE_TYPE.PHOTO || $type === MESSAGE_TYPE.PREMIUM_PHOTO ? '20rem' : 'fit-content'};
  height: ${({ $type }) => ($type === MESSAGE_TYPE.PHOTO || $type === MESSAGE_TYPE.PREMIUM_PHOTO) && '26rem'};
  overflow: hidden;
  min-width: 7rem;

  @media only screen and (min-width: 768px) {
    max-width: 60%;
  }
`;

export const MessageText = styled(Text14Light)`
  line-height: 2.2rem;
`;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 0.7;
  object-fit: cover;
`;

export const ImageBlur = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 16px 16px 16px 4px;
  background: rgba(26, 8, 58, 0.64);
`;

export const SentAtText = styled(Text12Light)`
  opacity: 0.4;
  position: absolute;
  right: 1rem;
  bottom: 0.6rem;
`;
