import React, { createContext, useContext, useState } from 'react';
import Modal from '../utils/Modal/Modal';
import SubscriptionModalContent from '../../payment/SubscriptionModalContent/SubscriptionModalContent';

const SubscriptionModalContext = createContext({
  openModal: modalMode => {},
});

export const SubscriptionModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalModel, setModalMode] = useState(null);

  const openModal = modalModel => {
    setIsOpen(true);
    setModalMode(modalModel);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalMode(null);
  };

  return (
    <SubscriptionModalContext.Provider value={{ openModal }}>
      {children}
      <SubscriptionModal isOpen={isOpen} onClose={closeModal} modalModel={modalModel} />
    </SubscriptionModalContext.Provider>
  );
};

const SubscriptionModal = ({ isOpen, onClose, modalModel }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SubscriptionModalContent onClose={onClose} modalModel={modalModel} />
    </Modal>
  );
};

export const useSubscriptionModal = () => {
  const { openModal } = useContext(SubscriptionModalContext);
  return [openModal];
};

export default SubscriptionModal;
