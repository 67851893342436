export const VATS = {
  AUT: 20,
  BEL: 21,
  BGR: 20,
  HRV: 25,
  CYP: 19,
  CZE: 21,
  DNK: 25,
  EST: 22,
  FIN: 24,
  FRA: 20,
  DEU: 19,
  GRC: 24,
  HUN: 27,
  IRL: 23,
  ISL: 24,
  ITA: 22,
  LVA: 21,
  LUX: 17,
  LTU: 21,
  MLT: 18,
  NLD: 21,
  NOR: 25,
  POL: 23,
  PRT: 23,
  ROU: 19,
  ESP: 25,
  SVK: 20,
  SVN: 22,
  SWE: 25,
};
