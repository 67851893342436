import styled from 'styled-components';
import { Text12Light, Text14Light, Text16Light } from '../utils/Text/Text.styles';
import { Button } from '../utils/Button/Button';

export const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  position: relative;
`;

export const ImageContainer = styled.div`
  max-width: 52rem;
  width: 52rem;
  border-radius: 16px;
  aspect-ratio: 0.6;
  overflow: hidden;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }
`;

export const Shadow = styled.div`
  background: linear-gradient(180deg, #000 0.11%, rgba(0, 0, 0, 0.9) 71.08%);
  background: linear-gradient(180deg, color(display-p3 0 0 0 / 0) 0.11%, color(display-p3 0 0 0 / 0.9) 71.08%);
  height: 50%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const InfluencerInfo = styled.div`
  width: 29rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem 1.6rem;

  @media only screen and (max-width: 768px) {
    position: absolute;
    bottom: 2.2rem;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 0;
  }
`;

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const NameInfo = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  svg {
    min-width: 1.8rem;
    min-height: 1.8rem;
  }
`;

export const LocationInfo = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const PersonalitiesInfo = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  align-items: center;
`;

export const AboutMeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Avatar = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Text = styled(Text14Light)`
  color: #cfc3e9;
`;

export const PersonalityTag = styled.div`
  border-radius: 8px;
  background: #1e1435;
  padding: 0.2rem 0.8rem;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2.2rem;

  @media only screen and (max-width: 768px) {
    background: rgba(0, 0, 0, 0.6);
  }
`;

export const ButtonContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const CircleButton = styled.div`
  width: 4.8rem;
  min-width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  border: 1px solid #843efe;
  background: linear-gradient(180deg, #9861f8 0%, #4d2495 100%);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: auto;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const ChatButton = styled(Button)`
  padding: 1.2rem 1.2rem 1.2rem 0;
  gap: 0.4rem;
`;

export const CountryFlag = styled(Text12Light)`
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.2rem 0.8rem;
  line-height: 2.2rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const AboutMeMobile = styled(Text14Light)`
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const AboutMeText = styled(Text16Light)`
  line-height: 2.3rem;
`;

export const AnimeWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;
