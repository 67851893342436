import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20rem;
`;

export const Header = styled.div`
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1.6rem;
  margin-bottom: 2rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const NotebookHeader = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  padding-inline: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  @media only screen and (min-width: 768px) {
    padding: 2.4rem 1.6rem 1.6rem 2.4rem;
  }
`;

export const LogoContainer = styled.div`
  flex: 1;
  cursor: pointer;
  height: 4rem;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const PremiumButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
