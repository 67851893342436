import styled from "styled-components";

export const StepsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const StepItem = styled.div`
  background: ${({ $highlighted }) =>
    $highlighted
      ? "linear-gradient(99deg, #B490F4 1.02%, #843EFE 122.92%)"
      : "#a06ef9"};
  opacity: ${({ $highlighted }) => !$highlighted && 0.23};
  height: 4px;
  width: ${({ $active }) => ($active ? "44px" : "4px")};
  border-radius: 100px;
`;
