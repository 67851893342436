import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Content,
  ContentContainer,
  Header,
  LogoContainer,
  NotebookHeader,
  PremiumButtonWrapper,
} from './PersonalitiesPage.styles';
import {
  InfluencerCard,
  LogoComponent,
  PersonalitiesPageSkeleton,
  PersonalityTags,
  PremiumButton,
  PublicHeader,
} from '../../components';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron_left.svg';
import { HOME, PERSONALITY } from '../../components/App/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getInfluencers } from '../../store/slices/influencer/asyncThunk';
import { selectGetInfluencersPending, selectInfluencers } from '../../store/slices/influencer/slice';
import { useMediaQuery } from 'react-responsive';
import { TagsContainer } from '../HomePage/HomePage.styles';

const PersonalitiesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { personalityName } = useParams();
  const { pathname } = useLocation();

  const getInfluencersPending = useSelector(selectGetInfluencersPending);
  const influencers = useSelector(selectInfluencers);

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 550 });

  const onPersonalityTagClick = value => {
    navigate(`/${PERSONALITY.replace(':personalityName', value)}`);
  };

  useEffect(() => {
    if (personalityName) {
      dispatch(getInfluencers({ appearanceTags: [], personalityTags: [personalityName] }));
    }
  }, [personalityName, dispatch]);

  const onBack = () => {
    navigate(`${HOME}`);
  };

  return (
    <Container>
      <NotebookHeader>
        <PublicHeader backText="Categories" onBack={onBack} />
      </NotebookHeader>
      <Header>
        <LogoContainer onClick={onBack}>
          <ChevronLeft />
        </LogoContainer>
        <LogoComponent id="personalityPage" />
        <PremiumButtonWrapper>
          <PremiumButton />
        </PremiumButtonWrapper>
      </Header>
      <ContentContainer>
        <TagsContainer>
          <PersonalityTags onTagClick={onPersonalityTagClick} selectedTag={personalityName} />
        </TagsContainer>
        {getInfluencersPending ? (
          <Content>
            <PersonalitiesPageSkeleton />
          </Content>
        ) : (
          <Content>
            {influencers.map(influencer => {
              return (
                <InfluencerCard
                  key={influencer.id}
                  id={influencer.id}
                  name={influencer.name}
                  isVerified={influencer.isVerified}
                  image={influencer.image}
                  username={influencer.username}
                  age={influencer.age}
                  aboutMe={influencer.aboutMe}
                  chatButton={!isMobile}
                  aspectRatio={isMobile ? 0.48 : 3 / 4}
                  category={influencer.category}
                  from={pathname}
                  callOptions={influencer.callOptions}
                />
              );
            })}
          </Content>
        )}
      </ContentContainer>
    </Container>
  );
};

export default PersonalitiesPage;
