import React, { Fragment } from 'react';
import {
  Container,
  HashtagText,
  TagContainer,
  Content,
  MobileContent,
  Row,
  Title,
  EmptyDiv,
  RightShadow,
  LeftShadow,
} from './AppearanceTags.styles';
import { Text14Light } from '../utils/Text/Text.styles';
import { splitArrayInHalf } from '../../utils/util';

export const APPEARANCE_TAGS = [
  { label: 'Asian', value: 'asian' },
  { label: 'Caucasian', value: 'caucasian' },
  { label: 'Latina', value: 'latina' },
  { label: 'Middle Eastern', value: 'middle-eastern' },
  { label: 'Brown', value: 'brown' },
  { label: 'Black/Afro', value: 'black-afro' },
  { label: 'Blonde', value: 'blonde' },
  { label: 'Redhead', value: 'redhead' },
  { label: 'Curvy', value: 'curvy' },
  { label: 'Skinny', value: 'skinny' },
  { label: 'Athletic', value: 'athletic' },
  { label: 'Voluptuous', value: 'voluptuous' },
  { label: 'Tattoos', value: 'tattoos' },
  { label: 'Dark Features', value: 'dark-features' },
  { label: 'British', value: 'british' },
  { label: 'Gamer', value: 'gamer' },
];

const AppearanceTags = ({ onTagClick, hideTitle, selectedTag }) => {
  const [firstHalf, secondHalf] = splitArrayInHalf(APPEARANCE_TAGS);

  const onClick = value => {
    if (onTagClick) {
      onTagClick(value);
    }
  };

  return (
    <Container>
      <RightShadow />
      <LeftShadow />
      {!hideTitle && <Title>Popular Hashtags</Title>}
      <Content>
        {APPEARANCE_TAGS.map((tag, index) => {
          return (
            <TagContainer
              $last={index === APPEARANCE_TAGS.length - 1}
              $first={index === 0}
              key={tag.value}
              onClick={() => onClick(tag.value)}
              $selected={selectedTag === tag.value}>
              <HashtagText>#</HashtagText>
              <Text14Light>{tag.label}</Text14Light>
            </TagContainer>
          );
        })}
      </Content>
      <MobileContent>
        <Row>
          {firstHalf.map((tag, index) => {
            return (
              <Fragment key={tag.value}>
                <TagContainer
                  $last={index === firstHalf.length - 1}
                  $first={index === 0}
                  onClick={() => onClick(tag.value)}
                  $selected={selectedTag === tag.value}>
                  <HashtagText>#</HashtagText>
                  <Text14Light>{tag.label}</Text14Light>
                </TagContainer>
                {index === firstHalf.length - 1 && <EmptyDiv />}
              </Fragment>
            );
          })}
        </Row>
        <Row>
          {secondHalf.map((tag, index) => {
            return (
              <TagContainer
                $last={index === secondHalf.length - 1}
                $first={index === 0}
                key={tag.value}
                onClick={() => onClick(tag.value)}
                $selected={selectedTag === tag.value}>
                <HashtagText>#</HashtagText>
                <Text14Light>{tag.label}</Text14Light>
              </TagContainer>
            );
          })}
        </Row>
      </MobileContent>
    </Container>
  );
};

export default AppearanceTags;
