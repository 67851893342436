import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/users';

class UserService {
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/w/me`, { withCredentials: true });
      throwErrorIfWrongStatus(response, 200);
      const { user, accessToken } = response.data.data;
      return { user, accessToken };
    } catch (err) {
      throw handleError(err, "Error occurred while fetching user's info.");
    }
  };

  updateUserInfo = async body => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/w/user-info`, body);
      throwErrorIfWrongStatus(response, 200);
      const { user } = response.data.data;
      return { user };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating user info.');
    }
  };

  getTelegramInvitationUrl = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/w/session-id`);
      throwErrorIfWrongStatus(response, 200);
      const { invitationUrl } = response.data.data;
      return { invitationUrl };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating user info.');
    }
  };

  changeUserResponseFormat = async ({ responseFormat }) => {
    try {
      const body = { responseFormat };
      const response = await axiosApiClient.post(`${BASE_URL}/w/response-format`, body);
      throwErrorIfWrongStatus(response, 200);
      return { responseFormat };
    } catch (err) {
      throw handleError(err, 'Error occurred while changing response format.');
    }
  };
}

export default new UserService();
