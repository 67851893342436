import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactModal from 'react-modal';
import { CloseIconWrapper, Content, Header, ModalStyle, OverlayStyle } from './BaseModal.styles';
import { Text20Bold } from '../../../utils/Text/Text.styles';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

const BaseModal = forwardRef(({ title, children, onClose, shouldCloseOnOverlayClick = true, onAfterClose }, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
    },
    toggle() {
      setShow(prev => !prev);
    },
  }));

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={onClose}
      onAfterClose={onAfterClose}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Header>
            <Text20Bold>{title}</Text20Bold>
            <CloseIconWrapper onClick={onClose}>
              <CloseIcon />
            </CloseIconWrapper>
          </Header>
          <Content>{children}</Content>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    />
  );
});

export default BaseModal;
