import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BottomWrapper,
  ChatContainer,
  Container,
  InputWrapper,
  LottieContainer,
  LottieRecordingVoice,
  MenuIconWrapper,
  RecordVoiceContainer,
  ScrollLoaderContainer,
  SendButton,
  StartChatTime,
  StyledInput,
  TextRecordingVoice,
} from './ChatPreviewPage.styles';
import { ChatHeader, MessageComponent, MessageLimitExceededModal, Spinner, SuggestedReply } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVisitedChats,
  groupMessagesByDay,
  isSubscribed,
  messageLimitExceeded,
  removeUsersInfluencerVisitedChat,
  saveVisitedChatsToStorage,
} from '../../utils/util';
import { selectUser } from '../../store/slices/user/slice';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import {
  selectGetUserInfluencerChatPending,
  selectSendChatMessagePending,
  setVisitedChatsChanged,
} from '../../store/slices/chat/slice';
import { getUserInfluencerChat, sendChatMessage } from '../../store/slices/chat/asyncThunk';
import { notifyError } from '../../utils/notify';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DAILY_LIMIT_EXCEEDED_ERROR_MESSAGE, MESSAGE_TYPE, USER_ROLE } from '../../utils/constants';
import { useAuthModal } from '../../components/modals/AuthModal/AuthModal';
import { useSubscriptionModal } from '../../components/modals/SubscriptionModal/SubscriptionModal';
import { useTelegramModal } from '../../components/modals/TelegramModal/TelegramModal';
import { getInfluencerByUsername } from '../../store/slices/influencer/asyncThunk';
import { selectGetInfluencerByUsernamePending } from '../../store/slices/influencer/slice';
import logoAnimation from '../../assets/gifs/logo.json';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment.svg';
import Lottie from 'lottie-react';
import animationData from '../../assets/gifs/waveform.json';
import typingAnimationData from '../../assets/gifs/typing.json';
import { format, parseISO } from 'date-fns';
import { CHAT_MODE } from '../InfluencerInfoPage/InfluencerInfoPage';
import { changeUserResponseFormat } from '../../store/slices/user/asyncThunk';

const sendMessageFormSchema = yup
  .object({
    message: yup
      .string()
      .trim()
      .required('Message is required')
      .test('len', 'Message too long', val => val.toString().length <= 200),
  })
  .required();

const ChatPreviewPage = () => {
  const { influencerUsername } = useParams();
  const { state } = useLocation();
  const [openModal] = useSubscriptionModal();
  const [openAuthModal] = useAuthModal();
  const [openTelegramModal] = useTelegramModal();

  const user = useSelector(selectUser);
  const sentMessages = user?.messageLimiter.count;
  const sendMessagePending = useSelector(selectSendChatMessagePending);
  const getInfluencerByUsernamePending = useSelector(selectGetInfluencerByUsernamePending);
  const getUserInfluencerChatPending = useSelector(selectGetUserInfluencerChatPending);
  const isLoggedIn = useSelector(selectLoggedIn);

  const dispatch = useDispatch();

  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [maxReached, setMaxReached] = useState(true);
  const [showMessageLimitExceededModal, setShowMessageLimitExceededModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [chatMode, setChatMode] = useState(CHAT_MODE.TEXT);
  const secretMode = state?.secretMode;

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      message: '',
    },
    delayError: 300,
    resolver: yupResolver(sendMessageFormSchema),
  });

  useEffect(() => {
    if (
      !sendMessagePending &&
      selectedInfluencer &&
      !getInfluencerByUsernamePending &&
      !getUserInfluencerChatPending &&
      isLoggedIn !== null
    ) {
      const shouldOpenModal = sentMessages === 5 || (sentMessages > 5 && sentMessages % 10 === 0);
      if (shouldOpenModal) {
        openModal();
      }
    }
    // eslint-disable-next-line
  }, [
    sentMessages,
    selectedInfluencer,
    isLoggedIn,
    getInfluencerByUsernamePending,
    getUserInfluencerChatPending,
    sendMessagePending,
  ]);

  const onSubmit = data => {
    const { message } = data;
    if (preventAction()) {
      return;
    }

    const userMessage = {
      content: message,
      sentAt: new Date().toISOString(),
      role: USER_ROLE.USER,
      type: MESSAGE_TYPE.TEXT,
      id: new Date().getTime().toString(),
    };

    setSelectedInfluencer({
      ...selectedInfluencer,
      chat: [...selectedInfluencer.chat, userMessage],
    });

    reset();

    const visitedChats = getVisitedChats(user);
    const storedInfluencerInfo = visitedChats[influencerUsername];

    const initialMessageSecretModeIds = [];

    if (storedInfluencerInfo) {
      for (let i = 1; i < storedInfluencerInfo.chat.length; i += 2) {
        if (storedInfluencerInfo.chat[i].secretModeId) {
          initialMessageSecretModeIds.push(storedInfluencerInfo.chat[i].secretModeId);
        }
      }
    }

    dispatch(
      sendChatMessage({
        influencerUsername,
        userInput: message,
        secretModeId: selectedInfluencer.activeSecretModeId,
        initialMessageSecretModeIds,
      }),
    )
      .unwrap()
      .then(({ messages }) => {
        removeUsersInfluencerVisitedChat(user, influencerUsername);
        const id = new Date().getTime().toString();
        const newMessages = messages.map((message, index) => ({
          ...message,
          id: `${id}-${index}`,
        }));
        setSelectedInfluencer({
          ...selectedInfluencer,
          chat: [...selectedInfluencer.chat, userMessage, ...newMessages],
        });
      })
      .catch(err => {
        if (err.message === DAILY_LIMIT_EXCEEDED_ERROR_MESSAGE) {
          setShowMessageLimitExceededModal(true);
          return;
        }
        notifyError('Something went wrong. Please try again in few minutes.');
      });
  };

  const onSuggestedReplyClick = () => {
    if (preventAction()) {
      return;
    }
    const { suggestedReply, ...restInfluencer } = selectedInfluencer;

    const userMessage = {
      content: suggestedReply,
      sentAt: new Date().toISOString(),
      role: USER_ROLE.USER,
      type: MESSAGE_TYPE.TEXT,
      id: new Date().getTime().toString(),
    };
    setSelectedInfluencer({
      ...restInfluencer,
      chat: [...selectedInfluencer.chat, userMessage],
    });

    const visitedChats = getVisitedChats(user);
    const storedInfluencerInfo = visitedChats[influencerUsername];

    const initialMessageSecretModeIds = [];

    if (storedInfluencerInfo) {
      for (let i = 1; i < storedInfluencerInfo.chat.length; i += 2) {
        if (storedInfluencerInfo.chat[i].secretModeId) {
          initialMessageSecretModeIds.push(storedInfluencerInfo.chat[i].secretModeId);
        }
      }
    }

    dispatch(
      sendChatMessage({
        influencerUsername,
        userInput: suggestedReply,
        secretModeId: selectedInfluencer.activeSecretModeId,
        initialMessageSecretModeIds,
      }),
    )
      .unwrap()
      .then(({ messages }) => {
        removeUsersInfluencerVisitedChat(user, influencerUsername);
        const id = new Date().getTime().toString();

        const newMessages = messages.map((message, index) => ({
          ...message,
          id: `${id}-${index}`,
        }));
        setSelectedInfluencer({
          ...restInfluencer,
          chat: [...selectedInfluencer.chat, userMessage, ...newMessages],
        });
      })
      .catch(err => {
        if (err.message === DAILY_LIMIT_EXCEEDED_ERROR_MESSAGE) {
          setShowMessageLimitExceededModal(true);
          return;
        }
        notifyError('Something went wrong. Please try again in few minutes.');
      });
  };

  const onEnterPress = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (!sendMessagePending) {
        handleSubmit(onSubmit)();
      }
    }
  };

  useEffect(() => {
    if (user) {
      setChatMode(user.responseFormat);
    }
  }, [user]);

  const switchChange = () => {
    if (!user) {
      openAuthModal(null, () => {});
      return true;
    }
    const newChatMode = chatMode === CHAT_MODE.TEXT ? CHAT_MODE.VOICE : CHAT_MODE.TEXT;
    setChatMode(newChatMode);

    dispatch(changeUserResponseFormat({ responseFormat: newChatMode }))
      .unwrap()
      .then(() => {})
      .catch(err => {
        notifyError(err.message);
        setChatMode(chatMode);
      });
  };

  useEffect(() => {
    if (influencerUsername && isLoggedIn !== null) {
      const visitedChats = getVisitedChats(user);
      const influencerChat = visitedChats[influencerUsername];

      let userInfluencer = null;

      if (user) {
        userInfluencer = user.userInfluencers.find(ui => ui.influencerUsername === influencerUsername);
      }

      if (userInfluencer) {
        dispatch(getUserInfluencerChat({ influencerId: userInfluencer.id, page: 1 }))
          .unwrap()
          .then(({ userInfluencer, page }) => {
            setMaxReached(userInfluencer.chat.maxReached);
            setCurrentPage(page + 1);
            if (influencerChat) {
              if (secretMode && influencerChat.activeSecretModeId !== secretMode.id) {
                const savedInfluencer = saveVisitedChatsToStorage(influencerChat, user, secretMode);
                dispatch(setVisitedChatsChanged(true));
                const newChat = [...userInfluencer.chat.data, ...savedInfluencer.chat];
                setSelectedInfluencer({
                  ...userInfluencer,
                  activeSecretModeId: savedInfluencer.activeSecretModeId,
                  chat: newChat,
                });
              } else {
                const newChat = [...userInfluencer.chat.data, ...influencerChat.chat];
                setSelectedInfluencer({
                  ...userInfluencer,
                  activeSecretModeId: influencerChat.activeSecretModeId,
                  chat: newChat,
                });
              }
            } else {
              if (secretMode && userInfluencer.activeSecretModeId !== secretMode.id) {
                const savedInfluencer = saveVisitedChatsToStorage(userInfluencer, user, secretMode);
                const newChat = [...userInfluencer.chat.data, ...savedInfluencer.chat];
                dispatch(setVisitedChatsChanged(true));
                setSelectedInfluencer({
                  ...userInfluencer,
                  activeSecretModeId: savedInfluencer.activeSecretModeId,
                  chat: newChat,
                });
              } else {
                setSelectedInfluencer({ ...userInfluencer, chat: userInfluencer.chat.data });
              }
            }
          })
          .catch(err => {
            notifyError(err.message);
          });
        return;
      }

      if (influencerChat) {
        // Secret mode changed
        if (secretMode && influencerChat.activeSecretModeId !== secretMode.id) {
          const savedInfluencer = saveVisitedChatsToStorage(influencerChat, user, secretMode);
          dispatch(setVisitedChatsChanged(true));
          setSelectedInfluencer(savedInfluencer);
        } else {
          setSelectedInfluencer(influencerChat);
        }
      } else {
        dispatch(getInfluencerByUsername({ influencerUsername }))
          .unwrap()
          .then(payload => {
            const savedInfluencer = saveVisitedChatsToStorage(
              { ...payload.influencer, influencerUsername: payload.influencer.username },
              user,
              secretMode,
            );
            dispatch(setVisitedChatsChanged(true));
            setSelectedInfluencer(savedInfluencer);
          })
          .catch(err => {
            notifyError(err.message);
          });
      }
    }
    // eslint-disable-next-line
  }, [influencerUsername, dispatch, secretMode, user?.id, isLoggedIn]);

  const messages = useMemo(() => {
    if (selectedInfluencer) {
      const { groupedMessages, hasUserMessages } = groupMessagesByDay(selectedInfluencer.chat);
      return { content: groupedMessages, hasUserMessages, shouldHandleScroll: selectedInfluencer.shouldHandleScroll };
    }
  }, [selectedInfluencer]);

  const preventAction = phoneAction => {
    if (!user) {
      openAuthModal(null, () => {});
      return true;
    }
    if (isSubscribed(user.subscribedUntil)) {
      openTelegramModal();
      return true;
    }
    if (phoneAction) {
      openModal();
      return true;
    }
    if (messageLimitExceeded(user.messageLimiter)) {
      setShowMessageLimitExceededModal(true);
      return true;
    }

    return false;
  };

  const onAttachmentClick = () => {
    preventAction(true);
  };

  const loadMoreData = useCallback(() => {
    if (!maxReached && user) {
      const userInfluencer = user.userInfluencers.find(ui => ui.influencerUsername === influencerUsername);
      if (userInfluencer) {
        dispatch(getUserInfluencerChat({ influencerId: userInfluencer.id, page: currentPage }))
          .unwrap()
          .then(({ userInfluencer, page }) => {
            setMaxReached(userInfluencer.chat.maxReached);
            setCurrentPage(page + 1);
            const newChat = [...userInfluencer.chat.data, ...selectedInfluencer.chat];
            setSelectedInfluencer({
              ...userInfluencer,
              chat: newChat,
              shouldHandleScroll: true,
            });
          })
          .catch(err => {
            notifyError(err.message);
          });
      }
    }
  }, [user, maxReached, currentPage, dispatch, influencerUsername, selectedInfluencer]);

  const [bottomWrapperHeight, setBottomWrapperHeight] = useState(0);

  const bottomWrapperRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setBottomWrapperHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(node);
  }, []);

  if (
    !selectedInfluencer ||
    getInfluencerByUsernamePending ||
    (getUserInfluencerChatPending && currentPage === 1) ||
    isLoggedIn === null
  ) {
    return (
      <LottieContainer>
        <Lottie animationData={logoAnimation} loop={true} />
      </LottieContainer>
    );
  }

  return (
    <Container>
      <ChatHeader
        influencerUsername={influencerUsername}
        name={selectedInfluencer.name}
        image={selectedInfluencer.profilePhoto}
        isVerified={selectedInfluencer.isVerified}
        onPhoneClick={() => preventAction(true)}
        setSelectedInfluencer={setSelectedInfluencer}
        chatMode={chatMode}
        setChatMode={switchChange}
      />
      <ScrollableDiv
        bottomWrapperHeight={bottomWrapperHeight}
        loadMoreData={loadMoreData}
        loading={getUserInfluencerChatPending}
        setSelectedInfluencer={setSelectedInfluencer}
        messages={messages}>
        {Object.keys(messages.content).map(date => (
          <Fragment key={date}>
            {!getUserInfluencerChatPending && <StartChatTime>{format(parseISO(date), 'MMMM dd, yyyy')}</StartChatTime>}
            {messages.content[date].map(message => (
              <MessageComponent key={message.id} message={message} />
            ))}
          </Fragment>
        ))}
      </ScrollableDiv>
      <BottomWrapper ref={bottomWrapperRef}>
        {!sendMessagePending && selectedInfluencer.suggestedReply && !messages.hasUserMessages && (
          <SuggestedReply onClick={onSuggestedReplyClick} replyMessage={selectedInfluencer.suggestedReply} />
        )}
        {sendMessagePending && (
          <RecordVoiceContainer>
            <LottieRecordingVoice>
              <Lottie animationData={chatMode === CHAT_MODE.TEXT ? typingAnimationData : animationData} />
            </LottieRecordingVoice>
            <TextRecordingVoice>{chatMode === CHAT_MODE.TEXT ? 'typing' : 'recording voice'}</TextRecordingVoice>
          </RecordVoiceContainer>
        )}
        <form>
          <InputWrapper>
            <MenuIconWrapper onClick={onAttachmentClick}>
              <AttachmentIcon />
            </MenuIconWrapper>
            <StyledInput
              onKeyDown={onEnterPress}
              {...register('message')}
              placeholder="Type a message..."
              autoComplete="new-password"
            />
            {sendMessagePending ? (
              <Spinner width={40} height={40} />
            ) : (
              <SendButton onClick={() => handleSubmit(onSubmit)()}>
                <SendIcon />
              </SendButton>
            )}
          </InputWrapper>
        </form>
      </BottomWrapper>
      <MessageLimitExceededModal
        show={showMessageLimitExceededModal}
        setShow={setShowMessageLimitExceededModal}
        onSubscribeClick={() => {
          openModal();
          setShowMessageLimitExceededModal(false);
        }}
      />
    </Container>
  );
};

const ScrollableDiv = ({ children, loading, loadMoreData, messages, bottomWrapperHeight, setSelectedInfluencer }) => {
  const listRef = useRef(null);
  const scrollPosition = useRef(0);

  useEffect(() => {
    // Scroll to the bottom of the div whenever the content changes
    listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [bottomWrapperHeight]);

  useEffect(() => {
    if (listRef.current && messages.shouldHandleScroll) {
      listRef.current.scrollTop = listRef.current.scrollHeight - scrollPosition.current;
      setSelectedInfluencer(prev => ({ ...prev, shouldHandleScroll: false }));
    }
    // eslint-disable-next-line
  }, [messages.shouldHandleScroll]);

  const handleScroll = () => {
    if (listRef.current.scrollTop === 0 && !loading) {
      // Save the scroll position before loading new data
      scrollPosition.current = listRef.current.scrollHeight;
      loadMoreData(() => {
        listRef.current.scrollTop = listRef.current.scrollHeight - scrollPosition.current;
      });
    }
  };

  return (
    <ChatContainer $bottomWrapperHeight={bottomWrapperHeight} ref={listRef} onScroll={handleScroll}>
      {loading && (
        <ScrollLoaderContainer>
          <Lottie animationData={logoAnimation} />
        </ScrollLoaderContainer>
      )}
      {children}
    </ChatContainer>
  );
};

export default ChatPreviewPage;
