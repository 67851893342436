import styled from 'styled-components';
import { Transition, theme } from '../../../../styles/constants';

export const StyledOtpInput = styled.input`
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 300;
  color: ${theme.colors.white};
  background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36;
  height: 5.4rem;
  width: 5.4rem;
  border: 1px solid #2c1655;
  border-radius: 16px;
  -webkit-transition: ${Transition.hover.off};
  -moz-transition: ${Transition.hover.off};
  -ms-transition: ${Transition.hover.off};
  -o-transition: ${Transition.hover.off};
  transition: ${Transition.hover.off};
  box-shadow: none;
  outline: none;
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

  ::placeholder {
    color: #b3a2cf;
  }

  @media only screen and (max-width: 380px) {
    width: 5rem;
  }

  @media only screen and (max-width: 350px) {
    width: 4.8rem;
  }
`;

export const OtpInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
