import React, { useEffect } from 'react';
import BaseModal from '../utils/BaseModal/BaseModal';
import { Button } from '../../utils/Button/Button';
import { ButtonWrapper, CancelText, Form, GenderButton, GenderSelection } from './EditUserInfoModal.styles';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GENDERS } from '../../../utils/constants';
import Input from '../../utils/inputs/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { selectUpdateUserInfoPending, selectUser } from '../../../store/slices/user/slice';
import { updateUserInfo } from '../../../store/slices/user/asyncThunk';
import { notifyError } from '../../../utils/notify';
import { setForgotPassword } from '../../../store/slices/auth/slice';

export const EDIT_USER_INFO_MODAL_TYPE = {
  NICKNAME: 'nickname',
  GENDER: 'gender',
  PASSWORD: 'password',
};

const passwordFormSchema = yup
  .object({
    password: yup.string().trim().required('Password is required').min(6, 'Must be at least 6 characters'),
  })
  .required();

const updateUserNameFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').min(3, 'Must be at least 3 characters'),
  })
  .required();

const EditUserInfoModal = ({ modalRef, type }) => {
  const user = useSelector(selectUser);
  const updateUserInfoPending = useSelector(selectUpdateUserInfoPending);

  const dispatch = useDispatch();

  const renderValidationSchema = () => {
    switch (type) {
      case EDIT_USER_INFO_MODAL_TYPE.NICKNAME:
        return updateUserNameFormSchema;
      case EDIT_USER_INFO_MODAL_TYPE.PASSWORD:
        return passwordFormSchema;
      case EDIT_USER_INFO_MODAL_TYPE.GENDER:
        return yup.object({});
      default:
        break;
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      password: '',
      name: '',
      gender: '',
    },
    delayError: 300,
    resolver: yupResolver(renderValidationSchema()),
  });

  useEffect(() => {
    setValue('name', user.name);
    setValue('gender', user.gender);
  }, [user, setValue]);

  const renderTitle = () => {
    switch (type) {
      case EDIT_USER_INFO_MODAL_TYPE.NICKNAME:
        return 'Edit Nickname';
      case EDIT_USER_INFO_MODAL_TYPE.GENDER:
        return 'Edit Gender';
      case EDIT_USER_INFO_MODAL_TYPE.PASSWORD:
        return 'Change Password';

      default:
        break;
    }
  };

  const renderContent = () => {
    switch (type) {
      case EDIT_USER_INFO_MODAL_TYPE.NICKNAME:
        return <Input placeholder="Enter name" {...register('name')} error={errors.name?.message} />;
      case EDIT_USER_INFO_MODAL_TYPE.GENDER:
        return (
          <GenderSelection>
            {GENDERS.map(gender => {
              return (
                <Controller
                  name="gender"
                  key={gender.value}
                  control={control}
                  render={({ field }) => (
                    <GenderButton
                      type="button"
                      $selected={field.value === gender.value}
                      onClick={() => field.onChange(gender.value)}>
                      {gender.label}
                    </GenderButton>
                  )}
                />
              );
            })}
          </GenderSelection>
        );
      case EDIT_USER_INFO_MODAL_TYPE.PASSWORD:
        return (
          <Input
            placeholder="Enter password"
            {...register('password')}
            error={errors.password?.message}
            type="password"
          />
        );

      default:
        break;
    }
  };

  const onSubmit = data => {
    const { password, name, gender } = data;

    let requestData = {};

    switch (type) {
      case EDIT_USER_INFO_MODAL_TYPE.NICKNAME:
        requestData.name = name;
        break;
      case EDIT_USER_INFO_MODAL_TYPE.GENDER:
        requestData.gender = gender;
        break;
      case EDIT_USER_INFO_MODAL_TYPE.PASSWORD:
        requestData.password = password;
        break;

      default:
        break;
    }

    dispatch(updateUserInfo(requestData))
      .unwrap()
      .then(() => {
        dispatch(setForgotPassword(false));
        modalRef.current.hide();
      })
      .catch(() => {
        notifyError('Something went wrong. Please try again in few minutes.');
      });
  };

  return (
    <BaseModal
      title={renderTitle()}
      ref={modalRef}
      onClose={() => {
        modalRef.current.hide();
        dispatch(setForgotPassword(false));
      }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {renderContent()}
        <ButtonWrapper>
          <Button title="Save" isLoading={updateUserInfoPending} />
          <CancelText
            onClick={() => {
              modalRef.current.hide();
              dispatch(setForgotPassword(false));
            }}>
            Cancel
          </CancelText>
        </ButtonWrapper>
      </Form>
    </BaseModal>
  );
};

export default EditUserInfoModal;
