import { cancelSubscription, createPaymentSession, getPaymentSession, purchaseCredits } from './asyncThunk';

export const createPaymentSessionBuilder = builder => {
  builder.addCase(createPaymentSession.pending, state => {
    state.createPaymentSessionPending = true;
  });
  builder.addCase(createPaymentSession.fulfilled, state => {
    state.createPaymentSessionPending = false;
  });
  builder.addCase(createPaymentSession.rejected, state => {
    state.createPaymentSessionPending = false;
  });
};

export const purchaseCreditsBuilder = builder => {
  builder.addCase(purchaseCredits.pending, state => {
    state.purchaseCreditsPending = true;
  });
  builder.addCase(purchaseCredits.fulfilled, state => {
    state.purchaseCreditsPending = false;
  });
  builder.addCase(purchaseCredits.rejected, state => {
    state.purchaseCreditsPending = false;
  });
};

export const cancelSubscriptionBuilder = builder => {
  builder.addCase(cancelSubscription.pending, state => {
    state.cancelSubscriptionPending = true;
  });
  builder.addCase(cancelSubscription.fulfilled, state => {
    state.cancelSubscriptionPending = false;
  });
  builder.addCase(cancelSubscription.rejected, state => {
    state.cancelSubscriptionPending = false;
  });
};

export const getPaymentSessionBuilder = builder => {
  builder.addCase(getPaymentSession.pending, state => {
    state.getPaymentSEssionPending = true;
  });
  builder.addCase(getPaymentSession.fulfilled, state => {
    state.getPaymentSEssionPending = false;
  });
  builder.addCase(getPaymentSession.rejected, state => {
    state.getPaymentSEssionPending = false;
  });
};
