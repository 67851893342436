import styled from 'styled-components';
import { Text15Light } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  padding-top: 8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OtpContainer = styled.div`
  margin-top: 4.4rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TimeRemainText = styled(Text15Light)`
  color: #c4a0ff;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  margin-top: auto;
  margin-bottom: 3.7rem;
  @media only screen and (max-width: 600px) {
    margin-bottom: 7.6rem;
  }
`;

export const ResendCodeContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;

export const ResendText = styled(Text15Light)`
  color: #c4a0ff;
  text-decoration: underline;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
