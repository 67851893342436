import React from 'react';
import ReactModal from 'react-modal';
import {
  ButtonsContainer,
  CancelButton,
  Content,
  Header,
  MainButton,
  ModalStyle,
  OverlayStyle,
  Text,
} from './CancelSubscriptionModal.styles';
import { Text22Bold } from '../../utils/Text/Text.styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { notifyError } from '../../../utils/notify';
import { useDispatch, useSelector } from 'react-redux';
import { cancelSubscription } from '../../../store/slices/payment/asyncThunk';
import { selectCancelSubscriptionPending } from '../../../store/slices/payment/slice';

const CancelSubscriptionModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const cancelSubscriptionPending = useSelector(selectCancelSubscriptionPending);

  const onCancelClick = () => {
    dispatch(cancelSubscription())
      .unwrap()
      .then(() => {
        setShow(false);
      })
      .catch(err => {
        notifyError(err.message);
      });
  };
  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => setShow(false)}
      className="_"
      overlayClassName="_"
      contentElement={props => (
        <ModalStyle {...props}>
          <Content>
            <Header>
              <Text22Bold>Cancel Subscription</Text22Bold>
              <CloseIcon onClick={() => setShow(false)} />
            </Header>
            <Text>
              Are you sure you want to cancel your subscription? You will lose access to all Premium features.
            </Text>
            <ButtonsContainer>
              <CancelButton title="Nevermind" onClick={() => setShow(false)} />
              <MainButton title="Yes, Cancel" isLoading={cancelSubscriptionPending} onClick={onCancelClick} />
            </ButtonsContainer>
          </Content>
        </ModalStyle>
      )}
      overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    />
  );
};

export default CancelSubscriptionModal;
