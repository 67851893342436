import styled from 'styled-components';
import { Text10Regular, Text13Light, Text15Light } from '../../utils/Text/Text.styles';

export const Container = styled.div`
  border-radius: 32px;
  border: 1px solid #8653e0;
  display: flex;
  flex-direction: row;
  height: 28rem;
  position: relative;
  background: linear-gradient(93deg, #331b61 -0.05%, rgba(0, 0, 1, 0.01) 54.14%, #23153d 99.4%);
  background: linear-gradient(
    93deg,
    color(display-p3 0.1882 0.1098 0.3647) -0.05%,
    color(display-p3 0.0017 0.001 0.0034 / 0.01) 54.14%,
    color(display-p3 0.1298 0.0856 0.2285) 99.4%
  );
`;

export const LeftContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  svg {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
`;

export const CardInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  position: relative;
`;

export const CardText = styled(Text15Light)`
  color: #aa85ff;
  max-width: 45rem;
  line-height: 2.4rem;
`;

export const PremiumButtonContainer = styled.div`
  position: absolute;
  left: 12.2rem;
  top: -2.6rem;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
`;

export const Title = styled(Text10Regular)`
  letter-spacing: 1.6px;
`;

export const ContentContainer = styled.div`
  cursor: pointer;
  border-radius: 100px;
  border: 1.4px solid rgba(180, 144, 244, 0.4);
  border: 1.4px solid color(display-p3 0.6824 0.5686 0.9333 / 0.4);
  background: linear-gradient(91deg, #53308e 1.29%, #24163c 12.07%, #150c29 39.29%, #150c29 98.75%);
  background: linear-gradient(
    91deg,
    color(display-p3 0.307 0.1956 0.5375) 1.29%,
    color(display-p3 0.1325 0.0877 0.2261) 12.07%,
    color(display-p3 0.0784 0.0471 0.1529) 39.29%,
    color(display-p3 0.0784 0.0471 0.1529) 98.75%
  );
  backdrop-filter: blur(5px);
  padding: 1.2rem;
  display: flex;
  gap: 1.2rem;
  align-items: center;
`;

export const AvatarImage = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;
export const Text = styled(Text13Light)`
  color: #bb8efb;
  opacity: 0.9;
`;

export const DiamondContainer = styled.div`
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bb8efb;
  transform: rotate(18.32deg);
  position: absolute;
  z-index: 2;
  right: -0.2rem;
  top: 0.8rem;
  svg {
    width: 1.9rem;
    height: 1.9rem;
  }
`;

export const Background = styled.div`
  position: absolute;
  inset: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;
