import styled from 'styled-components';
import { Text13Light, Text14Light } from '../../components/utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media only screen and (min-width: 768px) {
    padding: 0;
    height: 100%;
  }
`;

export const ChatContainer = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  padding-inline: 1.6rem;
  margin-top: 7.2rem;
  overflow: auto;
  max-height: calc(100dvh - 7.2rem - 3.2rem - ${({ $bottomWrapperHeight }) => `${$bottomWrapperHeight}px`});

  @media only screen and (max-width: 768px) {
    margin-top: 6.4rem;
    max-height: calc(100dvh - 6.4rem - 2rem - ${({ $bottomWrapperHeight }) => `${$bottomWrapperHeight}px`});
  }
`;

export const InputWrapper = styled.div`
  border-radius: 16px;
  height: 6.4rem;
  width: 100%;
  background: #170d2e;
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  @media only screen and (max-width: 768px) {
    padding: 0.2rem 0.8rem 0.2rem 1.2rem;
    height: 5.2rem;
  }
`;

export const BottomWrapper = styled.div`
  position: fixed;
  bottom: 0;
  max-width: 83.2rem;
  width: 100%;
  padding: 1.6rem;
  background: #05030b;
  z-index: 10;
  border-right: 1px solid #1d1229;

  @media only screen and (max-width: 1440px) {
    right: 0;
    width: unset;
    left: 60.8rem;
    border-right: none;
  }

  @media only screen and (max-width: 768px) {
    padding-left: unset;
    padding-right: unset;
    padding-top: 0.4rem;
    left: 1.6rem;
    right: 1.6rem;
  }
`;

export const StyledInput = styled.input`
  flex-grow: 1;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 300;
  color: #fff;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
  &::placeholder {
    color: #fff;
  }
`;

export const SendButton = styled.div`
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuIconWrapper = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: 20rem;
    height: 20rem;
  }
`;

export const StartChatTime = styled(Text13Light)`
  color: #bb8efb;
  opacity: 0.4;
  margin: 1.2rem 0;
  text-align: center;
`;

export const RecordVoiceContainer = styled.div`
  border-radius: 4px 16px 16px 16px;
  background: #181222;
  background: color(display-p3 0.0902 0.0706 0.1294);
  padding: 1rem;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.4rem;
`;

export const LottieRecordingVoice = styled.div`
  height: 2rem;
  width: 2rem;
`;

export const TextRecordingVoice = styled(Text14Light)`
  color: #c4a0ff;
`;

export const ScrollLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  div {
    height: 4rem;
    width: 4rem;
  }
`;
